import React, { Fragment, useEffect, useState } from "react"
import "../../css/dashboard.css"
import "../../css/marketCard.css"
import thumb from "../../images/cardImage.svg"
import PersonIcon from '@mui/icons-material/Person';
import heartIcon from "../../images/heart.svg"
import heartbeatIcon from "../../images/heartbeat.svg"
import punchIcon from "../../images/punch.svg"
import shieldIcon from "../../images/shield.svg"
import { Link } from "react-router-dom";
import { REAL_STATE_URL } from "../../utils/config";
import NoItemListed from "./noItemListed";
import { useSelector } from "react-redux";
import DashboardNoItemsFound from './noItemListed'
import { getUSDPrice } from '../../utils/helper'
import blockIcon from "../../images/block.svg";
import {HeartIcon} from "../../utils/svgConst";

const ListedAvatar = (props) => {

    const [data, setData] = useState([])


    const selector = useSelector(state => state);

    useEffect(() => {
        setData(props?.data)
    }, [props])

    return (




        data && data.length > 0 ? data.map((key, index) => {
            return (
                <Fragment key={index}>
                    <Link to={"/detail/" + key?.nftContract.toLowerCase() + "/" + key?.nftId}>
                        <div className="listed-block-item custom-dashboard-card main-dark-card">
                            <img src={key?.image ? key?.image : blockIcon} alt="thumb" className="thumb-icon" />
                            <div className="profileID dark-orange-bg orange-outline">
                                <PersonIcon className="clr-orange" />
                                <p className="clr-orange font12Txt ml-2 mb-0">#{key?.nftId}</p>
                            </div>
                            {key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {
                                return (
                                    <Fragment key={index}>
                                        {key?.attribute === "baseHP" && key?.type === 3 &&
                                        <div className="img--block">
                                                <img src={heartIcon} alt="heart" />
                                                <p className="mb-0 font14Txt mt-1 clr-white">{key?.value}</p>
                                        </div>
                                        }
                                    </Fragment>
                                )
                            })
                            }
                            {key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {
                                return (
                                    <Fragment key={index}>
                                        {key?.attribute === "healthReganRate" && key?.type === 3 &&
                                        <div className="img--block">
                                            <img src={heartbeatIcon} alt="heart" />
                                            <p className="mb-0 font14Txt mt-1 clr-white">{key?.value}</p>
                                        </div>
                                        }
                                    </Fragment>
                                )
                            })
                            }
                            {key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {
                                return (
                                    <Fragment key={index}>
                                        {key?.attribute === "bonusToDMG" && key?.type === 3 &&
                                        <div className="img--block">
                                            <img src={punchIcon} alt="heart" />
                                            <p className="mb-0 font14Txt mt-1 clr-white">{key?.value}</p>
                                        </div>
                                        }
                                    </Fragment>
                                )
                            })
                            }

                            {key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {
                                return (
                                    <Fragment key={index}>
                                        {key?.attribute === "bonusToDefence" && key?.type === 3 &&
                                        <div className="img--block">
                                            <img src={shieldIcon} alt="heart" />
                                            <p className="mb-0 font14Txt mt-1 clr-white">{key?.value}</p>
                                        </div>
                                        }
                                    </Fragment>
                                )
                            })
                            }
                            {/*<div className="img--block">*/}
                            {/*    <img src={heartIcon} alt="heart" />*/}
                            {/*    <p className="mb-0 font14Txt mt-1 clr-white">45</p>*/}
                            {/*</div>*/}
                            {/*<div className="img--block">*/}
                            {/*    <img src={heartbeatIcon} alt="heart" />*/}
                            {/*    <p className="mb-0 font14Txt mt-1 clr-white">45</p>*/}
                            {/*</div>*/}
                            {/*<div className="img--block">*/}
                            {/*    <img src={punchIcon} alt="heart" />*/}
                            {/*    <p className="mb-0 font14Txt mt-1 clr-white">45</p>*/}
                            {/*</div>*/}
                            {/*<div className="img--block">*/}
                            {/*    <img src={shieldIcon} alt="heart" />*/}
                            {/*    <p className="mb-0 font14Txt mt-1 clr-white">45</p>*/}
                            {/*</div>*/}
                            <div className="img--block">
                                <p className="font21Txt mb-0 clr-white">Ξ {key?.price}</p>
                                <p className="font18Txt clr-gray mb-0">$ {getUSDPrice(key?.price, selector.ethPrice.price)}</p>
                            </div>
                        </div>
                    </Link>
                </Fragment>
            )
        }) :
            <DashboardNoItemsFound type="listed" />
    )
}

export default ListedAvatar
