import React, { Fragment, useState } from "react"


import "../css/landingPage.css"
import "../images/btnOutline.svg"
import logo from "../images/logo.svg"
import { BBSIcon, CrossIcon, DiscordIcon, DownloadGameButtonIcon, GetCodeBtn, TwitterIcon, WhitepaperButtonIcon } from "../utils/svgConst"
import CreateUsernameModal from "../component/Modals/CreateUsernameModal";
import UserEmailExampleModal from "../component/Modals/UserEmailExampleModal"
import USER_HELPER from "../helper/userHelper"


const LandingPage = () => {

    const { getTemplateInfo } = USER_HELPER()
    const templateInfo = getTemplateInfo()


    return (
        <Fragment>
            <div className="homeBG">
                <img src={templateInfo?.home?.homepageMainImage} alt="bg" className="logo-bg" />
                <div className="home-btn-block mb-50">
                    <div className="btnBG ">
                        <button className="btn downloadGame">
                            <DownloadGameButtonIcon />
                        </button>
                    </div>
                    <div className="btnBG ">
                        <button className="btn whitepaper">
                            <WhitepaperButtonIcon />
                        </button>
                    </div>

                </div>
                <div className="bottomNav-Btn-Block">
                    <a className="btn" href="https://discord.com/invite/jvq7SCjUhz" target="_blank">
                        <DiscordIcon />
                    </a>
                    <a className="btn" href="https://twitter.com/ClubMetaNFT" target="_blank">
                        <TwitterIcon />
                    </a>
                    <button className="btn clr-red font20BoldTxt">
                        <BBSIcon />
                    </button>
                </div>

                <CreateUsernameModal />
                <UserEmailExampleModal />

            </div>
        </Fragment>
    )
}

export default LandingPage
