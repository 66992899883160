import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import React from "react";

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#111111',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#111111',
        color: '#00ffff'
    },
}));

export default CustomTooltip
