import React, { Fragment } from "react"
import "../css/dashboard.css"
import SoldBlock from "../component/Dashboard/SoldBlock"
import DashboardDataDayWise from "../component/Dashboard/DashboardDataDayWise";
import ListedSecondarySaleDashboard from "../component/Dashboard/ListedBlock";
import TabHeader from "../component/TabHeader"



const Dashboard = () => {
    return (
        <Fragment>
            <TabHeader />
            <div className="container pb-5">

                <DashboardDataDayWise />

                <div className="dashboard-detail-grid">
                    <div className="listed-block">
                        <p className="font21Txt clr-red orbFont">Recently Listed</p>
                        <ListedSecondarySaleDashboard />
                    </div>
                    <div className="sold-block">
                        <p className="font21Txt clr-red orbFont">Recently Sold</p>
                        <SoldBlock />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Dashboard
