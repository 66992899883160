import React, { Fragment, useState } from 'react'
import { Formik, Form, Field } from "formik"
import blueBuilding from "../../../images/buildingBlue.svg"
import blueA2Z from "../../../images/a2zBlue.svg"
import SidebarDropdown from '../../Dropdown/SidebarDropdown'

import {useDispatch} from "react-redux";
import {RealEstateApartmentNumber, RealEstateFloorNumber} from "../../../redux/actions/actions";


const RealEstateTraits = () => {

    const dispatch=useDispatch()
    const handleSubmit = (values) => {
        //consollog("value submit", values)
    }

    const [nameColor, setNameColor] = useState(false)
    const handleNameColorPick = () => {
        setNameColor(!nameColor)
    }

    const [selected, setSelected] = useState("")


    const [floorValue,setFloorValue] = useState('')
    const handleFloor=(e)=>{
        console.log('floor',[Number(e.target.value)])
        setFloorValue(e.target.value)
        let valueNu = Number(e.target.value)
        // let nu =''
        // if(valueNu===0){
        //     valueNu=nu
        // }
        if(valueNu>0){
            dispatch(RealEstateFloorNumber([valueNu]))
        }
        else {
            dispatch(RealEstateFloorNumber([]))
        }
    }
    const [apartmentValue,setApartmentValue] = useState('')

    const handleApartment=(e)=>{
        setApartmentValue(e.target.value)
        let valueNu = Number(e.target.value)
        // let nu =''
        // if(valueNu===0){
        //     valueNu=nu
        // }

        if(valueNu>0){
            dispatch(RealEstateApartmentNumber([valueNu]))
        }
        else {
            dispatch(RealEstateApartmentNumber([]))
        }
    }


    return (
        <Fragment>

            <SidebarDropdown selected={selected} setSelected={setSelected} type="real-estate"
            />

            {selected &&
                <>
                    <div className='mt-30 pl-25 pr-25'>
                        <Formik
                            initialValues={{ minETH: "", maxETH: "" }}
                            // onChange={(values) => handleFloor(values)}
                        >
                            <Form >
                                <img src={blueBuilding} alt="building" className="mr-3 mb-2" />
                                <p className="font14Txt clr-white text-uppercase d-ib mb-2">Floor</p>
                                <Field
                                    name="number1"
                                    type="number"
                                    placeholder="ENTER A Number  5-100"
                                    className="fullWidth-input br-0 mb-40"
                                    value={floorValue}
                                    onChange={(e)=>{
                                        handleFloor(e)
                                    }}
                                />
                                <img src={blueA2Z} alt="building" className="mr-3 mb-2" />
                                <p className="font14Txt clr-white text-uppercase d-ib mb-2">APARTMENT NUMBER</p>
                                <Field
                                    name="letter1"
                                    type="text"
                                    placeholder="ENTER A LETTER A-Z"
                                    className="fullWidth-input br-0 "
                                    value={apartmentValue}
                                    onChange={(e)=>{
                                        handleApartment(e)
                                    }}
                                />

                            </Form>
                        </Formik>
                    </div>
                </>
            }
        </Fragment>
    )
}

export default RealEstateTraits
