import React, { Fragment, useEffect, useState } from "react"
import "../../css/dashboard.css"
import "../../css/marketCard.css"
import saberIcon from "../../images/saber.svg"
import damageIcon from "../../images/damage.svg"
import weaponIcon from "../../images/weapon.svg"
import headIcon from "../../images/head.svg"
import flashIcon from "../../images/flash.svg"
import triangleIcon from "../../images/triangle.svg"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DashboardNoItemsFound from './noItemListed'
import { getUSDPrice } from '../../utils/helper'

const ListedItems = (props) => {

    const [data, setData] = useState([])

    const selector = useSelector(state => state);

    useEffect(() => {
        setData(props?.data)
    }, [props])

    return (
        data && data.length > 0 ? data.map((key, index) => {
            //consollog('kkk', key)
            return (
                <Fragment key={index}>
                    <Link to={"/detail/" + key?.nftContract.toLowerCase() + "/" + key?.nftId}>
                        <div className="listed-block-item custom-dashboard-card main-dark-card">
                            <img src={saberIcon} alt="thumb" className="thumb-icon" />
                            <div>
                                <div className="br-0 dark-orange-bg orange-outline">
                                    <p className="orange-outline-txt">Legendary</p>
                                </div>
                                <p className="font12Txt clr-Primary mb-0 mt-2">#{key?.nftId}</p>
                            </div>
                            <div className="img--block">
                                <img src={damageIcon} alt="damageIcon" />
                                <p className="mb-0 font14Txt mt-1 clr-white">45</p>
                            </div>
                            <div className="img--block">
                                <img src={weaponIcon} alt="weaponIcon" />
                                <p className="mb-0 font14Txt mt-1 clr-white">45</p>
                            </div>
                            <div className="img--block">
                                <img src={headIcon} alt="heart" />
                                <p className="mb-0 font14Txt mt-1 clr-white">45</p>
                            </div>
                            <div className="img--block">
                                <img src={flashIcon} alt="heart" />
                                <p className="mb-0 font14Txt mt-1 clr-white">45</p>
                            </div>
                            <div className="img--block">
                                <img src={triangleIcon} alt="heart" />
                                <p className="mb-0 font14Txt mt-1 clr-white">45</p>
                            </div>
                            <div className="img--block">
                                <p className="font21Txt mb-0 clr-white">Ξ {key?.price}</p>
                                <p className="font18Txt clr-gray mb-0">$ {getUSDPrice(key?.price, selector.ethPrice.price)}</p>
                            </div>
                        </div>
                    </Link>
                </Fragment>
            )
        }) :
            <DashboardNoItemsFound type="listed" />
    )
}

export default ListedItems
