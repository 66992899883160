import {Route, Switch} from "react-router-dom";
import {Fragment} from "react"
import MarketPlace from "./MarketPlace";
import Dashboard from "./dashboard";
import Detail from "./detail";
import MyWallet from "./myWallet";
import NewMintScreen from "./mintScreen";
import LandingPage from "./landingPage";
import Header from "../component/Header/Header";

const PublicRoutes = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path="/profile/:tabName" component={MyWallet}/>
                <Route path="/market/:nftContract" component={MarketPlace}/>
                <Route path="/dashboard" component={Dashboard}/>
                <Route path="/detail/:nftContract/:nftId" component={Detail}/>
                <Route path="/profile/:tabName" component={MyWallet}/>
                <Route path="/mint/:nftContract" component={NewMintScreen}/>
                <Route path="/" exact component={LandingPage}/>
            </Switch>
        </Fragment>
    )
}

export default PublicRoutes
