import React, { Fragment, useState, useEffect } from 'react'
import "../../css/sidebar.css"
import arrow from "../../images/dropdownArrow.svg"
import HandleClickOutside from "../../utils/helper"
import { TickIcon } from '../../utils/svgConst'
import charterDeselect from "../../images/charterDeselect.svg"
import charterSelect from "../../images/charterSelect.svg"

import antiHeroSelect from "../../images/antiHeroSelect.svg"
import antiHeroDeselect from '../../images/antiheroDeselect.svg'

import studioOneSelect from "../../images/studioOneSelect.svg"
import studioOneDeselect from "../../images/studioOneDeselect.svg"
import studioTwoSelect from "../../images/studioTwoSelect.svg"
import studioTwoDeselect from "../../images/studioTwoDeselect.svg"
import mediumOneSelect from "../../images/mediumOneSelect.svg"
import mediumOneDeselect from "../../images/mediumOneDeselect.svg"
import mediumTwoSelect from "../../images/mediumTwoSelect.svg"
import mediumTwoDeselect from "../../images/mediumTwoDeselect.svg"
import penthouseSelect from "../../images/penthouseSelect.svg"
import penthouseDeselect from "../../images/penthouseDeselect.svg"
import { useDispatch } from "react-redux";
import { FirstFilter } from "../../redux/actions/actions";



const SidebarDropdown = ({ selected, setSelected, type }) => {
    const dispatch = useDispatch()


    const [isOpen, setIsOpen] = useState(false)
    const [stateActive, setStateActive] = useState()
    const [imgIc, setImgIc] = useState()
    const handleToggleBox = () => {
        setIsOpen(!isOpen)
    }

    const [data, setData] = useState([])

    useEffect(() => {

        if (type === 'avatar') {
            const data = [{
                name: 'Charter Wireframe',
                imageSelected: charterSelect,
                image: charterDeselect,

            },
            {
                name: 'Anti Hero',
                imageSelected: antiHeroSelect,
                image: antiHeroDeselect,

            }]
            // const data = ['Charter-wireframe', 'Anti-Hero']
            setData(data)
        }
        if (type === 'real-estate') {
            const data = [
                {
                    name: 'studio 1',
                    imageSelected: studioOneSelect,
                    image: studioOneDeselect,

                },
                {
                    name: 'Medium 1',
                    imageSelected: mediumOneSelect,
                    image: mediumOneDeselect,

                },
                {
                    name: 'Medium 2',
                    imageSelected: mediumTwoSelect,
                    image: mediumTwoDeselect,

                },
                {
                    name: 'Penthouse',
                    imageSelected: penthouseSelect,
                    image: penthouseDeselect,

                }

            ]


            setData(data)
        }

    }, [type]);

    // 'studio 1',

    // 'Studio 2', 'Medium 1', 'Medium 2', 'Penthouse'

    const ref = HandleClickOutside(() => {
        setIsOpen(false)
    })
    const selectOnclickHandle = (data, i) => {
        console.log('data.name', data.name)
        dispatch(FirstFilter(data.name))
        setSelected(data.name);
        setIsOpen(false);
        setStateActive(i)

    }

    return (
        <div ref={ref} className="pl-25 pr-25 mb-55">
            <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown btnHover ${isOpen ? 'traitCustomBtn blueDropdownBG' : ''}`} onClick={handleToggleBox}>{selected ? selected : 'Select'}
                <img src={arrow} alt="arrow" className={`float-right ml-auto ${isOpen ? 'rotateImage' : 'rotateBack'}`} />
            </button>
            <div className={`${isOpen ? 'sidebar-checkbox-wrapper' : 'd-none'} `}>
                {data.map((data, i) => {
                    return (
                        <Fragment>
                            <button className="btn sidebar--dropdown" onClick={(e) => {
                                selectOnclickHandle(data, i)
                            }} >
                                <img src={i == stateActive ? data.imageSelected : data.image} />
                                <p class="mb-0 font14Txt clr-white">{data.name}</p>
                                <span className={`ml-auto ${i == stateActive ? "activeTickIcon" : "inactive"}`}><TickIcon /></span>
                            </button>
                        </Fragment>
                    )
                })}
            </div>
        </div>
    )
}

export default SidebarDropdown