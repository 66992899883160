import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"


const CustomSlider = styled(Slider)(({ theme }) => ({

    height: 2,
    padding: "15px 0",
    "& .MuiSlider-thumb": {
        height: 15,
        width: 15,
        outline: '2px solid #00FFFF',
        outlineOffset: '2px',
        background: 'transparent',
        marginLeft: '4px',
        "&:focus, &:hover, &.Mui-active": {
            boxShadow:
                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        },
    },

    "& .MuiSlider-track": {
        border: "none",
        color: "#515151",
    },
    "& .MuiSlider-rail": {
        opacity: 0.5,

    },
    "& .MuiSlider-mark": {

        height: 10,
        width: 10,
        borderRadius: 10,
        background: '#515151',
        "&.MuiSlider-markActive": {
            opacity: 1,
            background: '#515151',
        },
    },
}))



export default CustomSlider
