import React, { Fragment } from 'react'
import "../../css/mintScreen.css"
import Countdown from "react-countdown";
import SoldOutMint from "./soldOut";
import MintForm from "./mintForm";
import MintTimer from "./mintTimer";
import ItemsDetailImage from "../Detail/ItemsDetailImage";


const ItemsMintScreen = (props) => {

    const renderer = ({ total, days, hours, minutes, seconds, milliseconds, completed, ...rest }) => {
        if (completed) {
            return (
                <Fragment>
                    <MintForm mintingDetail={props.mintingDetail} />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <MintTimer days={days}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                    />
                </Fragment>

            );
        }
    };


    return (
        <Fragment>
            <section>
                <div className="container mt-50 ">
                    <div className="mint-screen-grid">
                        <div className="left-mint-screen">
                            <ItemsDetailImage />
                        </div>
                        <div className="right-mint-screen">
                            <p className="font40Txt clr-whites mt-2 mb-0">LIGHT SABER 1 LIGHT</p>
                            <p className="font24Txt clr-red orbFont">{props.mintingDetail.totalSupply}/{props.mintingDetail.maxSupply}</p>
                            <p className="font18Txt text-capitalize">
                                The Charter Wireframe S1 (series 1) Avatar is our first Avatar. This
                                special avatar will only be available to the Founding Charter
                                Members of club metaverse. This avatar is meant to represent the
                                Faceless simple beginnings as we evolve our community into
                                greatness.
                            </p>
                            <p className="font18Txt text-capitalize">
                                Generated from 45 different possible traits and 4 unique combat
                                stats this avatar is ready to take on all the experiences club
                                metaverse has to offer.
                            </p>
                            <p className="font18Txt text-capitalize">
                                This avatar is also currently the only avatar in the roadmap who’s
                                stats can grow and evolve over time based on gameplay After you
                                connect your wallet to your Club Metaverse account you can log into
                                CLUb Metaverse and begin your virtual reality journey
                            </p>





                            {!(props.mintingDetail.totalSupply >= props.mintingDetail.maxSupply) &&

                                <>
                                    {props.saleStartTime > 0 &&
                                        <Countdown date={props.saleStartTime * 1000} zeroPadTime={1}
                                            renderer={renderer} />
                                    }
                                </>
                            }




                            {props.mintingDetail.totalSupply >= props.mintingDetail.maxSupply &&
                                <SoldOutMint />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ItemsMintScreen
