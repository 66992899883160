import React, { useEffect, useState } from "react";
import noNFTFound from "../../images/noNFTFound.svg"

const MarketNoDataFound = (props) => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [noData, setNoData] = useState(false)

    useEffect(() => {
        setLoading(props?.loading)
        setData(props?.data)
        setNoData(props?.noData)

    }, [props])

    return (
        !loading && data.length === 0 && noData &&
        <div className="col-md-12 text-center mt-5 mb-5 wallet-empty-block">
            <img src={noNFTFound} alt="noItemSelect" className="noCaseImageFix" />
        </div>
    )
}

export default MarketNoDataFound
