export const PRIMARY_SALE_ABI = [
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_maxBuyAllowed",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_fees",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "newAuthor",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "changedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "changedAt",
                type: "uint256",
            },
        ],
        name: "AuthorChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "whitestedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "blacklistedAt",
                type: "uint256",
            },
        ],
        name: "BlacklistedContract",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "buyer",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "boughtAt",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "totalUnits",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "fees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
        ],
        name: "Buy",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newFees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "changedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "changedAt",
                type: "uint256",
            },
        ],
        name: "FeesChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "withdrawalBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "withdrawalAt",
                type: "uint256",
            },
        ],
        name: "FeesWithdrawal",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "changedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "changedAt",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newPrice",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "priceTokendId",
                type: "address",
            },
        ],
        name: "PriceDetailsChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "addedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "addedAt",
                type: "uint256",
            },
        ],
        name: "PriceTokenAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "disabledBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "disabledAt",
                type: "uint256",
            },
        ],
        name: "PriceTokenDisabled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "author",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "priceTokend",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "whitestedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "whitelistedAt",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "saleStartTime",
                type: "uint256",
            },
        ],
        name: "WhitelistedContract",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newMaxBuyAllowed",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "changedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "changedAt",
                type: "uint256",
            },
        ],
        name: "maxBuyAllowedChaned",
        type: "event",
    },
    {
        inputs: [],
        name: "fees",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [],
        name: "maxBuyAllowed",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "sales",
        outputs: [
            {
                internalType: "address",
                name: "buyer",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "boughtAt",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "fees",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "feesAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalUnits",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "supportedPriceTokens",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "whitelistedContracts",
        outputs: [
            {
                internalType: "bool",
                name: "isWhitelisted",
                type: "bool",
            },
            {
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalUnitsSold",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalSale",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "statusUpdatedAt",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "author",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "saleStartTime",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        stateMutability: "payable",
        type: "receive",
        payable: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_totalUnits",
                type: "uint256",
            },
        ],
        name: "buy",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "payable",
        type: "function",
        payable: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "address",
                name: "_author",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_price",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_saleStartTime",
                type: "uint256",
            },
        ],
        name: "whitelistContract",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_newPrice",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_newPriceToken",
                type: "address",
            },
        ],
        name: "updatePriceDetails",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "address",
                name: "_newAuthor",
                type: "address",
            },
        ],
        name: "updateAuthor",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
        ],
        name: "blacklistContract",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_maxBuyAllowed",
                type: "uint256",
            },
        ],
        name: "updateMaxBuyAllowed",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_newPriceToken",
                type: "address",
            },
        ],
        name: "addPriceToken",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "disablePriceToken",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "withdrawFees",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_newFees",
                type: "uint256",
            },
        ],
        name: "updateFees",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "priceTokensList",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [],
        name: "getNFTContracts",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "getFeesDetails",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "getNFTContractPriceDetails",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
];

export const SECONDARY_SALE_ABI = [
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_fees",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "nftId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "seller",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "listedAt",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "fees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "authorFees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "saleId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
        ],
        name: "AddedToMarketplace",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "author",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "authorFees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "changedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "changedAt",
                type: "uint256",
            },
        ],
        name: "AuthorDetailsChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "blacklistedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "blacklistedAt",
                type: "uint256",
            },
        ],
        name: "BlacklistedContract",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "buyer",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "saleId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "boughtAt",
                type: "uint256",
            },
        ],
        name: "Buy",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "newFee",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "changedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "time",
                type: "uint256",
            },
        ],
        name: "FeesChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "withdrawalBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "withdrawalAt",
                type: "uint256",
            },
        ],
        name: "FeesWithdrawal",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "addedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "addedAt",
                type: "uint256",
            },
        ],
        name: "PriceTokenAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "disabledBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "disabledAt",
                type: "uint256",
            },
        ],
        name: "PriceTokenDisabled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "saleId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
        ],
        name: "PriceUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "saleId",
                type: "uint256",
            },
        ],
        name: "RemovedFromMarketplace",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "author",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "authorFees",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "whitelistedBy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "whitelistedAt",
                type: "uint256",
            },
        ],
        name: "WhitelistedContract",
        type: "event",
    },
    {
        inputs: [],
        name: "fees",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "nftSaleIds",
        outputs: [
            {
                internalType: "uint256",
                name: "currentSaleId",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "sales",
        outputs: [
            {
                internalType: "address",
                name: "seller",
                type: "address",
            },
            {
                internalType: "address",
                name: "buyer",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "nftId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "listedAt",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "fees",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "authorFees",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "priceToken",
                type: "address",
            },
            {
                internalType: "enum RevogSecondaryMarketplace.Status",
                name: "status",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "supportedPriceTokens",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "whitelistedContracts",
        outputs: [
            {
                internalType: "bool",
                name: "isWhitelisted",
                type: "bool",
            },
            {
                internalType: "address",
                name: "author",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "authorFees",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalSold",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalListed",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "totalRemoved",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        stateMutability: "payable",
        type: "receive",
        payable: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_nftId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_price",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "addToMarketplace",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_nftId",
                type: "uint256",
            },
        ],
        name: "removeFromMarketplace",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_nftId",
                type: "uint256",
            },
        ],
        name: "buy",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "payable",
        type: "function",
        payable: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_nftId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_newPrice",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "updatePrice",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "address",
                name: "_author",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_authorFees",
                type: "uint256",
            },
        ],
        name: "whitelistContract",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "address",
                name: "_author",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_authorFees",
                type: "uint256",
            },
        ],
        name: "changeAuthorDetails",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
        ],
        name: "blacklistContract",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_newFees",
                type: "uint256",
            },
        ],
        name: "updateFees",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_newPriceToken",
                type: "address",
            },
        ],
        name: "addPriceToken",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "disablePriceToken",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "withdrawFees",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_nftId",
                type: "uint256",
            },
        ],
        name: "NFTSaleDetail",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "seller",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "buyer",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "nftId",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "listedAt",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "price",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "fees",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "authorFees",
                        type: "uint256",
                    },
                    {
                        internalType: "address",
                        name: "priceToken",
                        type: "address",
                    },
                    {
                        internalType: "enum RevogSecondaryMarketplace.Status",
                        name: "status",
                        type: "uint8",
                    },
                ],
                internalType: "struct RevogSecondaryMarketplace.Sale",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "getFeesDetails",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "address",
                name: "_priceToken",
                type: "address",
            },
        ],
        name: "getNFTContractPriceDetails",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_nftId",
                type: "uint256",
            },
        ],
        name: "getAllSaleIds",
        outputs: [
            {
                internalType: "uint256[]",
                name: "",
                type: "uint256[]",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [],
        name: "priceTokensList",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
    {
        inputs: [],
        name: "getNFTContracts",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
        constant: true,
    },
];

export const NFT_CONTRACT_ABI = [
    {
        inputs: [
            { internalType: "string", name: "_name", type: "string" },
            { internalType: "string", name: "_symbol", type: "string" },
            { internalType: "string", name: "_basUri", type: "string" },
            { internalType: "string[]", name: "_immutables", type: "string[]" },
            { internalType: "string[]", name: "_mutables", type: "string[]" },
            {
                internalType: "string[]",
                name: "_immutablesDataTypes",
                type: "string[]",
            },
            {
                internalType: "string[]",
                name: "_mutablesDataTypes",
                type: "string[]",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "approved",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "Approval",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "operator",
                type: "address",
            },
            { indexed: false, internalType: "bool", name: "approved", type: "bool" },
        ],
        name: "ApprovalForAll",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_fromTokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_toTokenId",
                type: "uint256",
            },
        ],
        name: "BatchMetadataUpdate",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
            },
        ],
        name: "MetadataUpdate",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "updatedData",
                type: "string[]",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "updatedAt",
                type: "uint256",
            },
        ],
        name: "MetadataUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                components: [
                    { internalType: "string[]", name: "immutables", type: "string[]" },
                    { internalType: "string[]", name: "mutables", type: "string[]" },
                ],
                indexed: false,
                internalType: "struct Token.Metadata",
                name: "_schema",
                type: "tuple",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "updatedAt",
                type: "uint256",
            },
        ],
        name: "SchemaUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "templateId",
                type: "uint256",
            },
            {
                components: [
                    { internalType: "string[]", name: "immutables", type: "string[]" },
                    { internalType: "string[]", name: "mutables", type: "string[]" },
                ],
                indexed: false,
                internalType: "struct Token.Metadata",
                name: "_metadata",
                type: "tuple",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "createdAt",
                type: "uint256",
            },
        ],
        name: "TemplateCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "from", type: "address" },
            { indexed: true, internalType: "address", name: "to", type: "address" },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "Transfer",
        type: "event",
    },
    {
        inputs: [],
        name: "BASE_NFT_ID",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "MAX_NFT_PER_TEMPLATE",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "MAX_TEMPLATE_ID",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "MIN_TEMPLATE_ID",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    { internalType: "string[]", name: "immutables", type: "string[]" },
                    { internalType: "string[]", name: "mutables", type: "string[]" },
                ],
                internalType: "struct Token.Metadata",
                name: "_metadata",
                type: "tuple",
            },
        ],
        name: "addNewTemplate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "baseURI",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_to", type: "address" },
            { internalType: "uint256", name: "_totalNFTs", type: "uint256" },
        ],
        name: "batchMint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "currentTemplateId",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "getApproved",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getDataTypes",
        outputs: [
            {
                components: [
                    { internalType: "string[]", name: "immutables", type: "string[]" },
                    { internalType: "string[]", name: "mutables", type: "string[]" },
                ],
                internalType: "struct Token.Metadata",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
        name: "getMetadata",
        outputs: [
            {
                components: [
                    { internalType: "string[]", name: "immutables", type: "string[]" },
                    { internalType: "string[]", name: "mutables", type: "string[]" },
                ],
                internalType: "struct Token.Metadata",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getSchema",
        outputs: [
            {
                components: [
                    { internalType: "string[]", name: "immutables", type: "string[]" },
                    { internalType: "string[]", name: "mutables", type: "string[]" },
                ],
                internalType: "struct Token.Metadata",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_templateId", type: "uint256" }],
        name: "getTemplateData",
        outputs: [
            {
                components: [
                    { internalType: "string[]", name: "immutables", type: "string[]" },
                    { internalType: "string[]", name: "mutables", type: "string[]" },
                ],
                internalType: "struct Token.Metadata",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "operator", type: "address" },
        ],
        name: "isApprovedForAll",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_to", type: "address" },
            { internalType: "uint256", name: "_templateId", type: "uint256" },
        ],
        name: "mint",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "ownerOf",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
            { internalType: "bytes", name: "data", type: "bytes" },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "bool", name: "approved", type: "bool" },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "string", name: "_baseURI", type: "string" }],
        name: "setBaseTokenURI",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
        name: "supportsInterface",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "templateCount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "tokenURI",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "totalMintedNFTs",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "_tokenId", type: "uint256" },
            { internalType: "string[]", name: "_mutables", type: "string[]" },
        ],
        name: "updateMetadata",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    { internalType: "string[]", name: "immutables", type: "string[]" },
                    { internalType: "string[]", name: "mutables", type: "string[]" },
                ],
                internalType: "struct Token.Metadata",
                name: "_schema",
                type: "tuple",
            },
        ],
        name: "updateSchema",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
