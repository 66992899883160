import React, { Fragment, useState, useEffect } from "react"
import { useLocation } from "react-router-dom";
import CreateUsernameModal from "../../component/Modals/CreateUsernameModal"
import EnterEmailModal from "../../component/Modals/EnterEmailModal"
import EnterOTPModal from "../../component/Modals/EnterOTPModal"
import "../../css/header.css"
import logoIcon from "../../images/logo.svg"
import menuIcon from "../../images/menu.svg"
import { NavLink } from "react-router-dom"
import { ETHLogin, ETHLogout, logout, loginSuccess } from "../../redux/actions/actions";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as Web3 from "web3";
import { PRIMARY_SALE_ABI, SECONDARY_SALE_ABI } from "../../utils/ABI";
import { API_BASE_URL, PRIMARY_SALE_ADDRESS, REAL_STATE_URL, AVATAR_URL, SECONDARY_SALE_ADDRESS, DASHBOARD_URL } from "../../utils/config";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { setEthPrice } from "../../redux/actions/ethPriceActions";
import { AboutIcon, AboutBlueIcon, CommunityIcon, DiscordIcon, HowToPlayIcon, LoginBtn, LogoutBtn, LogoutIcon, MarketIcon, MintIcon, MyWalletIcon, PaperIcon, ProfileIcon, SmallDownArrow, TwitterIcon, BBSIcon, ConnectWalletBtn } from "../../utils/svgConst"
import HandleClickOutside from "../../utils/helper"
import {
    CreateUsernameModalAction,
    EnterEmailModalAction,
    EnterOTPModalAction,
    SellNFTModalAction
} from "../../redux/actions/modalActions";
import USER_HELPER from "../../helper/userHelper";

const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(SECONDARY_SALE_ABI, SECONDARY_SALE_ADDRESS);

const Header = () => {

    const { getTemplateInfo } = USER_HELPER()
    const templateInfo = getTemplateInfo()
    const dispatch = useDispatch()
    const selector = useSelector(state => state)

    const location = useLocation();

    const { pathname } = location;

    const history = useHistory();
    const [accountOpen, setAccountOpen] = useState(false)
    const [accountOpen2, setAccountOpen2] = useState(false)

    const [openCommunity, setOpenCommunity] = useState(false)
    const [activeM, setActiveM] = useState('false')
    const { addToast } = useToasts();
    const splitLocation = pathname.split("/");
    //consollog('split location is', { splitLocation })


    const connectToMetaMask = async () => {

        if (selector.user.address) {
            // if user is login then do logout
            handleLogout()
        } else {
            // if user is not logined then try login
            try {
                const { ethereum } = window;
                const { chainId } = ethereum;


                let metaMaskAddress = "0x13881"; // polygon mumbai test network

                // we are using etherum Ropsten test or mainnet network
                if (chainId === '0x1' || chainId === metaMaskAddress) {
                    if (!!ethereum) {
                        const accounts = await ethereum.request({
                            method: "eth_requestAccounts",
                        });

                        //consollog("accounts", accounts, chainId)
                        localStorage.setItem("address", accounts[0])
                        dispatch(ETHLogin({ address: accounts[0] }));
                        localStorage.setItem('metaMask', 'true')
                    }
                } else {
                    alert(`Please select Ropsten test or Mainnet network and then connect`);
                }
            } catch (e) {
                //consollog("something went wrong ", e);
            }
        }

    };

    const handleLogout = () => {
        localStorage.removeItem("address")
        localStorage.removeItem("metaMask")
        dispatch(ETHLogout())
        history.push("/");
        setAccountOpen(false)

    }

    const handleLogoutOtp = () => {
        localStorage.removeItem('userRevog')
        dispatch(logout())
        // after logout remoe auth token from Header
        delete axios.defaults.headers.common["Authorization"]
        history.push('/')
    }

    const handleToggleAccount = () => {
        setAccountOpen(!accountOpen)
    }
    const handleToggleAccount2 = () => {
        setAccountOpen2(!accountOpen2)
    }


    const handleToggleCommunity = () => {
        setActiveM(!activeM)
        //consollog('active m is', activeM)
        setOpenCommunity(!openCommunity)
    }



    useEffect(() => {
        const getMetaLocal = localStorage.getItem("metaMask")
        const userAddressLocal = localStorage.getItem("address")
        if (!!getMetaLocal && getMetaLocal === "true" && !!userAddressLocal) {
            //making login
            dispatch(ETHLogin({ address: userAddressLocal }))
        }
    }, [selector.user.address])



    const communityRef = HandleClickOutside(() => {
        setOpenCommunity(false)
    }
    )
    // const accountRef = HandleClickOutside(() => {
    //     setAccountOpen(false)
    // })

    const goToProfile = () => {
        if (selector.user.address) {
            history.push('/profile/collection')
        } else {
            addToast("Please login first", { appearance: "error", autoDismiss: true })
        }
        setAccountOpen(false)
        setAccountOpen2(false)

    }
    const handleEmailLoginModalShow = () => {
        dispatch(EnterEmailModalAction(true))

    }


    const testingShow = () => {
        dispatch(CreateUsernameModalAction(true))

    }


    const getUserInfo = async () => {
        const userAccessToken = selector.userNormal.token

        const headers = {
            'User-access-token': userAccessToken,
            'Content-Type': 'application/json',
            'Authorization': 'Bearer H7nTO^CJ0O%6'
        }
        axios
            .get(`https://metaverse-accounts.herokuapp.com/v1/user/metadata`,
                {
                    headers: headers
                }

            )
            .then(function (response) {
                if (response.status === 200) {

                    //consollog({response})

                }
            })
            .catch(function (error) {
                //consollog("error  api", error)
                addToast(error.message, { appearance: "error", autoDismiss: true })
            })
            .finally(() => {
            })
    }



    useEffect(() => {
        const getETHPrice = () => {
            axios.get(API_BASE_URL + '/smart-contract/eth-price')
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(setEthPrice(res.data))
                    }
                }).catch((err) => {
                    //consollog("failed to fetch eth price", err)
                })
        }
        getETHPrice();

    }, [])

    useEffect(() => {
        // getUserInfo()
        const userAccessToken = selector.userNormal.token

        const user = window.localStorage.getItem('userRevog')
        if (!!user) {
            const JsonUser = JSON.parse(user)
            // if user has local storage info

            if (JsonUser) {
                //consollog('json user', JsonUser)
                dispatch(loginSuccess(JsonUser))
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + 'H7nTO^CJ0O%6'
                axios.defaults.headers.common['User-access-token'] = userAccessToken

            } else {
                delete axios.defaults.headers.common["Authorization"];
            }
        }

    }, [])

    // useEffect(() => {
    //     //consollog('selector.userNormal.token',selector.userNormal.token)
    //     if (selector.userNormal.token) {
    //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + selector.userNormal.token
    //     }
    // }, [selector.userNormal.token])

    return (
        <Fragment>
            <nav className="navbar navbar-expand-lg navbar-light headerBG">
                <NavLink to="/" className="navbar-brand">
                    <img src={templateInfo?.navbar?.navLogo} alt="logo" />
                </NavLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <img src={menuIcon} alt="menu" />
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <NavLink className={`nav-link navbar-link font16Txt clr-white mb-0 ${splitLocation[1] === "mint" ? "activeM" : ""}`}
                                to={`/mint/${AVATAR_URL}`}
                            >
                                <MintIcon className="mr-2" />
                                Mint
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={` nav-link ${splitLocation[1] === "dashboard" ? "activeM" : ""} navbar-link font16Txt clr-white mb-0 ${splitLocation[1] === "market" ? "activeM" : ""}`}

                                to={`/${DASHBOARD_URL}`}
                            >
                                <MarketIcon className="mr-2" />
                                Marketplace
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={`nav-link navbar-link font16Txt clr-white mb-0 ${splitLocation[1] === "whitepaper" ? "" : ""}`}
                                to={``}
                            >
                                <PaperIcon className="mr-2" />
                                Whitepaper
                            </NavLink>
                        </li>
                        <li className="nav-item pos-rel" ref={communityRef}>
                            <button
                                className={`btn nav-link navbar-link font16Txt clr-white mb-0 `}
                                onClick={handleToggleCommunity}
                            >
                                <CommunityIcon className="mr-2" />
                                Community
                                <SmallDownArrow className="ml-2" />
                            </button>
                            <div className={`${openCommunity ? 'account-block' : 'd-none'}`}>
                                <a href="https://discord.com/invite/jvq7SCjUhz" target="_blank" class="btn account-dropdown-item btnWithIcon" onClick={(e) => { setOpenCommunity(false) }}>
                                    <DiscordIcon />
                                    <p class="mb-0 font14Txt clr-white">Discord</p>
                                </a>
                                <a href="https://twitter.com/ClubMetaNFT" target="_blank" class="btn account-dropdown-item btnWithIcon" onClick={(e) => { setOpenCommunity(false) }}>
                                    <TwitterIcon />
                                    <p class="mb-0 font14Txt clr-white">Twitter</p>
                                </a>
                                <button class="btn account-dropdown-item btnWithIcon" onClick={(e) => { setOpenCommunity(false) }}>
                                    <BBSIcon />
                                    <p class="mb-0 font14Txt clr-white">BBS</p>
                                </button>
                            </div>
                        </li>

                        <li className="nav-item">
                            <NavLink
                                className="nav-link navbar-link font16Txt clr-white mb-0"
                                to={``}
                            >
                                <AboutIcon className="mr-2" fill="#FFF" />
                                About
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link navbar-link font16Txt clr-white mb-0"
                                to={``}
                            >
                                <HowToPlayIcon className="mr-2" fill="#FFF" />
                                How to play
                            </NavLink>
                        </li>
                    </ul>


                    {/* <div className="header-btn-block">
                        <button className="btn outlineBtn font16Txt clr-white playBtnPadding mx-2">
                            Play Now
                        </button>
                    </div> */}
                    {!selector.user.address &&
                        <button
                            className={`btn font16Txt clr-Primary mx-2 customLogoutBtn pt-0 pb-0`}
                            onClick={connectToMetaMask}
                        >
                            <ConnectWalletBtn />

                            {/* <LogoutBtn text={selector.user.address ? "Logout" : "Connect Wallet"} /> */}
                            {/* <img src={iconConnect} alt="connect" className="mr-3" /> */}
                            {/* {selector.user.address ? "Logout" : "Connect Wallet"} */}
                        </button>
                    }
                    {selector.user.address &&
                        <div className="pos-rel"
                        // ref={accountRef}
                        >
                            <button
                                className={`btn profileBtn clr-white d-flex align-items-center ${splitLocation[1] === "profile" ? "activeM" : ""}`}
                                onClick={handleToggleAccount}>
                                <ProfileIcon className="mr-2" />
                                My Wallet
                            </button>


                            <div className={` ${accountOpen ? 'account-block' : 'd-none'}`}>
                                <button class="btn account-dropdown-item btnWithIcon" onClick={goToProfile}>
                                    <MyWalletIcon />
                                    <p class="mb-0 font14Txt clr-white">My Wallet</p>
                                </button>
                                <button class="btn account-dropdown-item btnWithIcon">
                                    <AboutBlueIcon fill="#00FFFF" />
                                    <p class="mb-0 font14Txt clr-white">Account Info</p>
                                </button>
                                {selector.user.address &&
                                    <>
                                        <button class="btn account-dropdown-item btnWithIcon" onClick={handleLogout}>
                                            <LogoutIcon />
                                            <p class="mb-0 font14Txt clr-white">Logout</p>
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    }

                    {!selector.userNormal.token &&

                        <button className="btn" onClick={handleEmailLoginModalShow}>

                            <LoginBtn />
                        </button>


                    }
                    {selector.userNormal.token &&
                        <div className="pos-rel" >
                            <button
                                className={`btn profileBtn clr-white d-flex align-items-center ${splitLocation[1] === "dffdfd" ? "activeM" : ""}`}
                                onClick={handleToggleAccount2}>
                                <ProfileIcon className="mr-2" />
                                My Account
                            </button>

                            <div className={` ${accountOpen2 ? 'account-block' : 'd-none'}`}>

                                <button className="btn account-dropdown-item btnWithIcon">
                                    <AboutBlueIcon fill="#00FFFF" />
                                    <p className="mb-0 font14Txt clr-white"
                                    // onClick={testingShow}

                                    >Account Info</p>
                                </button>
                                {selector.userNormal.token &&
                                    <>
                                        <button className="btn account-dropdown-item btnWithIcon" onClick={handleLogoutOtp}>
                                            <LogoutIcon />
                                            <p className="mb-0 font14Txt clr-white">Logout</p>
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    }



                </div>
            </nav>

            <EnterEmailModal />
            {/*<EnterOTPModal />*/}
            {/*<CreateUsernameModal />*/}
        </Fragment >
    )
}


export default Header
