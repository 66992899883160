import React, {Fragment} from "react"
import CustomTooltip from "../Common/CutomTooltip";


const SaleHistory = (props) => {
    return (
        <Fragment>
            <div className="main-dark-card p-25 mb-20">
                <p className="font21Txt clr-red orbFont mb-10 ">Sale History</p>
                <div className="divider-line mb-10"/>
                {props.previousSales && props.previousSales.length > 0 && props.previousSales.map((previousSales, value) => {
                    return (
                        <div className="detail-history-card">
                            <div>
                                <p className="font12Txt clr-gray text-uppercase mb-1">
                                    BUYER
                                </p>
                                {/* <p className="font16Txt mb-1">Dippler</p>*/}

                                <CustomTooltip title={previousSales.buyer}>
                                    <p className="font12Txt mb-1 clr-gray">
                                        {previousSales.buyer?.length > 10 ? previousSales.buyer?.substr(0, 20 - 1) + '.....' : previousSales.buyer}
                                    </p>
                                </CustomTooltip>


                            </div>
                            <div>
                                <p className="font12Txt clr-gray text-uppercase mb-1">
                                    SELLER
                                </p>
                                {/*<p className="font16Txt mb-1">Dippler</p>*/}
                                <CustomTooltip title={previousSales.seller}>
                                    <p className="font12Txt mb-1 clr-gray">
                                        {previousSales.seller?.length > 10 ? previousSales.seller?.substr(0, 20 - 1) + '.....' : previousSales.seller}
                                    </p>
                                </CustomTooltip>
                            </div>
                            <div>
                                <p className="font24Txt mb-1">{previousSales.price} ETH</p>
                                <p className="font12Txt mb-1 clr-gray">{previousSales.boughtAt}</p>
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </Fragment>
    )
}

export default SaleHistory
