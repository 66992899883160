import React, { Fragment, useEffect, useState } from "react"
import "../../css/dashboard.css"
import "../../css/marketCard.css"
import saberIcon from "../../images/saber.svg"
import CustomTooltip from "../Common/CutomTooltip"
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRarityClass, getRarityText, getUSDPrice } from '../../utils/helper'
import DashboardNoItemsFound from './noItemListed'

const SoldItems = (props) => {

    const [data, setData] = useState([])

    const selector = useSelector(state => state);

    useEffect(() => {
        //consollog("props real sold", props?.data)
        setData(props?.data)
    }, [props])



    return (
        data && data.length > 0 ? data.map((key, index) => {
            return (
                <Fragment key={index}>
                    <Link to={"/detail/" + key?.nftContract.toLowerCase() + "/" + key?.nftId}>
                        <div className="listed-block-item custom-dashboard-card main-dark-card">
                            <img src={saberIcon} alt={saberIcon} className="thumb-icon" />
                            <div>
                                <div className={`br-0 ${getRarityClass(key.rarity)}`}>
                                    <p className={`${getRarityText(key?.rarity)}`}>{key?.rarity}</p>
                                </div>
                                <p className="font12Txt clr-Primary mb-0 mt-2">#{key?.nftId}</p>
                            </div>
                            <div>
                                <p className="font12Txt mb-0 clr-Primary">Buyer</p>
                                <CustomTooltip title={key?.buyer}>
                                    <p className="font14Txt mb-0 fontOverflowFix clr-white">
                                        {key?.buyer}
                                    </p>
                                </CustomTooltip>
                            </div>
                            <div>
                                <p className="font12Txt mb-0 clr-Primary">Seller</p>
                                <CustomTooltip title={key?.seller}>
                                    <p className="font14Txt mb-0 fontOverflowFix clr-white">
                                        {key?.seller}
                                    </p>
                                </CustomTooltip>
                            </div>
                            <div className="img--block">
                                <p className="font21Txt mb-0 clr-white">Ξ {key?.price}</p>
                                <p className="font18Txt clr-gray mb-0">$ {getUSDPrice(key?.price, selector.ethPrice.price)}</p>
                            </div>
                        </div>
                    </Link>
                </Fragment>
            )
        }) :
            <DashboardNoItemsFound />
    )
}

export default SoldItems
