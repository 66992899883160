import React, { Fragment, useEffect, useState } from "react"
import heartIcon from "../../images/heart.svg";


const TraitsDetailPage = (props) => {
    const [data, setData] = useState({})
    const [attributes, setAttributes] = useState([])


    useEffect(() => {

        //consollog("traits", props?.detailPageData)
        setData(props?.detailPageData)
        setAttributes(props?.detailPageData?.metadata?.attributes)
    }, [props])

    return (
        <>
            <div className="main-dark-card p-25 mb-20">
                <p className="font21Txt clr-red orbFont mb-10 ">Traits</p>
                <div className="divider-line mb-10"></div>
                <div className="detail-traits-card">



                    {/*new data map populate for traits*/}


                    {attributes && attributes.length > 0 && attributes.map((key, index) => {
                        return (
                            <Fragment key={index}>
                                {key?.type === 2 &&
                                    <div>
                                        <p className="font12BoldTxt clr-gray text-uppercase mb-1">
                                            {key?.attribute}
                                        </p>
                                        <p className="font16Txt mb-1">{key?.value}</p>
                                    </div>
                                }

                            </Fragment>
                        )
                    })
                    }




                    {/*old data  populate for traits*/}


                    {/*{props.detailPageData.namePlateColor &&*/}
                    {/*    <div>*/}
                    {/*        <p className="font12Txt clr-Primary text-uppercase mb-1">*/}
                    {/*            NAME PLATE COLOR*/}
                    {/*        </p>*/}
                    {/*        <p className="font16Txt mb-1">{props.detailPageData.namePlateColor}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/*{props.detailPageData.wireFrame &&*/}
                    {/*    <div>*/}
                    {/*        <p className="font12Txt clr-Primary text-uppercase mb-1">*/}
                    {/*            WIREFRAME*/}
                    {/*        </p>*/}
                    {/*        <p className="font16Txt mb-1">{props.detailPageData.wireFrame}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/*{props.detailPageData.head &&*/}
                    {/*    <div>*/}
                    {/*        <p className="font12Txt clr-Primary text-uppercase mb-1">*/}
                    {/*            HEAD*/}
                    {/*        </p>*/}
                    {/*        <p className="font16Txt mb-1">{props.detailPageData.head}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{props.detailPageData.bodyTexture &&*/}
                    {/*    <div>*/}
                    {/*        <p className="font12Txt clr-Primary text-uppercase mb-1">*/}
                    {/*            BODY TEXTURE*/}
                    {/*        </p>*/}
                    {/*        <p className="font16Txt mb-1">{props.detailPageData.bodyTexture}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{props.detailPageData.head &&*/}
                    {/*    <div>*/}
                    {/*        <p className="font12Txt clr-Primary text-uppercase mb-1">*/}
                    {/*            HEAD*/}
                    {/*        </p>*/}
                    {/*        <p className="font16Txt mb-1">Brute</p>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{props.detailPageData.watch &&*/}
                    {/*    <div>*/}
                    {/*        <p className="font12Txt clr-Primary text-uppercase mb-1">*/}
                    {/*            WATCH*/}
                    {/*        </p>*/}
                    {/*        <p className="font16Txt mb-1">{props.detailPageData.watch}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{props.detailPageData.hands &&*/}
                    {/*    <div>*/}
                    {/*        <p className="font12Txt clr-Primary text-uppercase mb-1">*/}
                    {/*            HANDS*/}
                    {/*        </p>*/}
                    {/*        <p className="font16Txt mb-1">{props.detailPageData.hands}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            </div>
        </>
    )

}
export default TraitsDetailPage