import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from "react-router";
import ethereum from "../../images/ethereum.svg";
import { Field, Form, Formik } from "formik";
import minusIcon from "../../images/minus.svg";
import plusIcon from "../../images/plus.svg";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import SuccessModal from '../Detail/SuccessModal'

import { PRIMARY_SALE_ABI, SECONDARY_SALE_ABI } from "../../utils/ABI";
import {
    API_BASE_URL,
    AVATAR_URL, DEFAULT_WEB3_ADDRESS,
    ITEMS_URL,
    PRIMARY_SALE_ADDRESS,
    REAL_STATE_ADDRESS,
    REAL_STATE_URL,
    SECONDARY_SALE_ADDRESS
} from "../../utils/config";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import * as Web3 from "web3";
import { getContractAddressByURL } from "../../utils/helper";
import axios from "axios";
import BlockChainMsg from "../blockChsainTransactionMsg";


const defaultWeb3 = new Web3(DEFAULT_WEB3_ADDRESS)
const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(SECONDARY_SALE_ABI, SECONDARY_SALE_ADDRESS);


const MintForm = (props) => {


    const { addToast } = useToasts()
    const selector = useSelector(state => state)
    const params = useParams();
    const nftContract = params.nftContract

    const [maxInput, setMaxInput] = useState(0)
    const [price, setPrice] = useState(null)
    const [saleStartTime, setSaleStartTime] = useState(0)
    const [priceWithOutpow, setPriceWithOutpow] = useState(null)

    const [qty, setQty] = useState(1)
    const [loading, setLoading] = useState(false)
    const [submitBtnDisable, setSubmitBtnDisable] = useState(false)
    const [message, setMessage] = useState({ "message": "", res: false })
    const [mintingDetail, setMintingDetail] = useState({})
    const [hashValue, setHashValue] = useState('')
    const [showSuccessForMint, setShowSuccessForMint] = useState(false)

    const NFT_URL = params.nftContract


    const getNFTMintingDetail = () => {

        const contract = getContractAddressByURL(nftContract)
        axios.get(API_BASE_URL + 'smart-contract/mint-details/' + contract)
            .then((res) => {
                setMintingDetail(res.data)

            }).catch(() => {

            }).finally(() => {

            })
    }
    const handleInputChange = (e, direct = false) => {

        const maxInputPlusInputQty = qty + mintingDetail.totalSupply
        const maxSelect = mintingDetail.maxSupply - mintingDetail.totalSupply


        // dont allow user to select more quantity if selected quantity + already minted > maxSupply
        if (maxInputPlusInputQty >= mintingDetail.maxSupply) {

            addToast("You can max select " + maxSelect + " because allready minted quantity is  " + mintingDetail.maxSupply, { appearance: "error", autoDismiss: true })

        }
        else if (maxInput <= qty) {
            //when quantity is greater than entered
            addToast("You can add only " + maxInput, { appearance: "error", autoDismiss: true })
        }

        else if (direct) {
            // when you are changing quantity directly by input then check null value and then entered case
            if (!e.target.value) {
                setQty(0)
            } else if (maxInput <= e.target.value) {
                //when you are trying to enter greator than maxInput
                addToast("You can add only " + maxInput, { appearance: "error", autoDismiss: true })
            }
            else {
                setQty(parseInt(e.target.value))
            }
        } else {
            // call when increment button click
            setQty(prevState => prevState + 1)
        }
    }
    const handleDecreaseQuant = () => {

        if (qty === 1) {
            addToast("You should choose atleast 1 quantity", { appearance: "error", autoDismiss: true })
        } else {
            setQty(prevState => prevState - 1)
        }
    }

    const InitialValues = {
        quantity: "",
        price: "",
    }
    const getMintPriceAndToken = async () => {
        try {
            const contractCall = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS)
            const data = await contractCall.methods.whitelistedContracts(REAL_STATE_ADDRESS).call();
            //consollog('balance', data)
            setPriceWithOutpow(data.price)
            setSaleStartTime(data.saleStartTime)
            const finalData = data.price / Math.pow(10, 18)
            //consollog('balance', finalData)
            setPrice(finalData)
        } catch (e) {
            //consollog('balance error', e)
        }
    }


    const getMaxUnitAllowed = async () => {
        try {
            const contractCall = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS)
            const data = await contractCall.methods.maxBuyAllowed().call();
            //consollog('max unit', data)
            setMaxInput(data)
        } catch (e) {
            //consollog('balance error', e)
        }
    }

    // form submit of minting form
    const handleBuyPrimaryNFT = () => {
        if (!selector.user.address) {
            // toast message please login
            addToast("Please Login to continue", { appearance: "error", autoDismiss: true })
            //consollog("login failed")
        } else {
            setMessage({ "message": false })

            setLoading(true)
            setSubmitBtnDisable(true)

            const _totalUnits = qty
            const _nftContract = getContractAddressByURL(NFT_URL)
            // const _nftContract = nftContract === 'real-estate'?  REAL_STATE_ADDRESS:   nftContract === "items"? ITEMS_ADDRESS: AVATAR_ADDRESS

            try {
                PRIMARY_SALE_CONTRACT.methods
                    .buy(_nftContract, _totalUnits)  //method and parameter from docs
                    .send({
                        from: selector.user.address, // login user address
                        value: parseInt(priceWithOutpow * qty),
                    })
                    .on("transactionHash", (hash) => {

                        //consollog("transactionHash  buyToken", hash);
                        setMessage({ "message": hash, "res": true })
                        setHashValue(hash)

                    })
                    .on("receipt", (receipt, hash) => {
                        //consollog("receipt sendToken", receipt);
                    })
                    .on("confirmation", (confirmationNumber, receipt, hash) => {
                        //consollog("confirmationNumber", { confirmationNumber }, { receipt }, { hash });

                        if (confirmationNumber === 0) {
                            setShowSuccessForMint(true)
                            setLoading(false)
                            setSubmitBtnDisable(false)
                            getNFTMintingDetail()
                            addToast(`Success, Transaction Hash: ${hash}`, {
                                appearance: "success",
                                autoDismiss: true,
                            })
                            setMessage({ "message": hash, "res": true })
                        }


                    })
                    .on("error", (error) => {
                        //consollog("error sendToken", error);
                        setLoading(false)
                        setSubmitBtnDisable(false)
                        setMessage({ "message": error.message, "res": false })


                    });
            } catch (e) {
                //consollog("err", e)
                setLoading(false)
                setSubmitBtnDisable(false)


            }
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getNFTMintingDetail()
        }, 5000);
        return () => clearInterval(interval);
    }, [nftContract]);

    useEffect(() => {
        getMintPriceAndToken()
        getMaxUnitAllowed()
    }, [])

    useEffect(() => {
        getNFTMintingDetail()
    }, [nftContract])



    return (

        <>

            <div className="main-dark-card p-25 mt-50 mb-40 pos-rel">
                <div className="pb-10 mb-30 text-center">
                    <img src={ethereum} alt="ethereum" />
                    <p className="font24Txt mb-0 d-inline-block ml-3">
                        Mint on Ethereum
                    </p>
                </div>
                <div className="mint-bdr-top" />
                <Formik
                    initialValues={InitialValues}
                    onSubmit={handleBuyPrimaryNFT}
                >
                    <Form>
                        <div className="mint-btn-block w-85">
                            <div>
                                <p className="font12Txt clr-Primary text-uppercase">Quantity</p>

                                <div className="quantity-tab">
                                    <div className="input-group ">
                                        <div className="input-group-prepend">
                                            <p
                                                className=" btn mb-0"
                                                onClick={handleDecreaseQuant}
                                            >
                                                <img src={minusIcon} alt="minus" className="mb-1" />
                                            </p>
                                        </div>
                                        <Field
                                            type="text"
                                            className="form-control customInput p-0"
                                            value={qty}
                                            onChange={(e) => handleInputChange(e, true)}
                                            id="usr"
                                            name="quantity"
                                        />

                                        <div className="input-group-prepend">
                                            <p className=" btn mb-0"
                                                onClick={(e) => handleInputChange(e, false)}
                                            >
                                                <img src={plusIcon} alt="plus" className="mb-1" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="font12Txt clr-Primary text-uppercase">Price</p>
                                <div className="quantity-tab">
                                    <div className="input-group ">
                                        <Field
                                            type="text"
                                            className="form-control customInput p-0"
                                            value={`${(price * qty).toFixed(2)} ETH`}
                                            id="usr"
                                            name="price"
                                            disabled
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="text-center">
                            <button
                                className={`btn mt-3 p-0 br-0`}
                                disabled={submitBtnDisable}>
                                <svg className="svgBlueBtn" width="320" height="40" viewBox="0 0 357 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M356.5 0.5H0.5V39.5H346.56L356.5 29.7895V0.5Z" fill="#072D2D" stroke="#00FFFF" className="svgBlueBtnPath" />
                                    <text x='180' y='26' text-anchor='middle' fill='#00FFFF' className="font18Txt clr-Primary orbFont">Mint now</text>
                                </svg>

                                {loading &&
                                    <Loader
                                        type="Oval"
                                        className="ml-10"
                                        color="#00BFFF"
                                        height={20}
                                        width={20}
                                    />
                                }

                            </button>



                            <BlockChainMsg message={message} />


                        </div>
                    </Form>
                </Formik>


                <p className="font24Txt mt-20 text-center mb-0 clr-red orbFont mb-40">
                    <span
                        className="font24BoldTxt">{mintingDetail.totalSupply}/{mintingDetail.maxSupply}</span> Minted
                </p>

                <div className="mint-bdr-btm" />

                <div className="text-center mt-40">
                    <button className="btn br-0">
                        <svg width="220" height="42" viewBox="0 0 220 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.2634 41H1V1H219V31L208.763 41H51.2634" stroke="#00FFFF" stroke-linecap="round" />
                            <text x='110' y='26' text-anchor='middle' fill='#00FFFF' className="font14Txt clr-Primary orbFont">View in Marketplace</text>
                        </svg>


                    </button>
                </div>
            </div>

            {showSuccessForMint &&

                <SuccessModal message={message} />
            }



        </>
    )
}
export default MintForm
