import React, { Fragment, useCallback, useEffect, useState } from 'react'
import ActivityTable from './ActivityTable'
import NoActivity from './noActivity'
import axios from "axios";
import { API_BASE_URL } from "../../utils/config";
import { useSelector } from "react-redux";
import LeftArrow from "../../images/arrowLeft.svg";
import RightArrow from "../../images/arrowRight.svg";
import ListViewSkeltonCard from '../MarketPlace/ListViewSkeletonCard';

const WalletActivity = () => {
    const selector = useSelector(state => state)
    const userAddress = selector.user.address;
    const date = new Date();

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [noData, setNoData] = useState(false)
    const [pageLimit] = useState(10)
    const [pageNo, setPageNo] = useState(1)
    const [maxResult, setMaxResult] = useState(0)

    const maxPage = Math.ceil(maxResult / pageLimit)

    const getActivities = useCallback(() => {

        //consollog('getActivities')

        setLoading(true)
        setNoData(false)

        const limit = "?limit=" + pageLimit;
        const offset = "&skip=" + (pageNo === 1 ? 0 : ((pageNo - 1) * pageLimit))


        //adding all required params in one varaiable
        const params = limit + offset

        //api call to get results
        axios.get(API_BASE_URL + '/smart-contract/user/activities/' + userAddress + params)
            .then(function (res) {
                //consollog(res.data)
                setMaxResult(res.data.totalActivities)
                setData(res.data.activities)
                if (res.data.activities.length === 0) {
                    setNoData(true)
                }
            }).catch(() => {
                setData([])
                setNoData(true)

            }).finally(() => {
                setLoading(false)
            })
    }, [pageNo])

    useEffect(() => {
        if (!userAddress) return
        getActivities()
    }, [userAddress, pageNo])

    const handlePageChange = (type) => {
        // //consollog('hit', type, maxPage, pageNo)
        if (type === "INC") {
            //when max page is matched with current page no then dont call any api
            if (pageNo >= maxPage) return
            setPageNo(prevState => prevState + 1)
        } else if (type === "DEC") {
            if (pageNo === 1) return
            setPageNo(prevState => prevState - 1)
        } else {
            //consollog("type not matched")
        }
    }

    return (
        <Fragment>
            {!loading && <>
                <section>
                    <div className="wallet-table-wrapper">
                        <div className="wallet-activity-table-heading-grid">
                            <p className="font16Txt clr-gray">Action</p>
                            <p className="font16Txt clr-gray">Item</p>
                            <p className="font16Txt clr-gray">Price</p>
                            <p className="font16Txt clr-gray">Quantity</p>
                            <p className="font16Txt clr-gray">From</p>
                            <p className="font16Txt clr-gray">To</p>
                            <p className="font16Txt clr-gray">Time</p>
                        </div>
                    </div>
                </section>

                {data.map((item, index) => {
                    return (
                        <ActivityTable key={index} image={item.image} name={item.name} from={item.from} to={item.to}
                            time={Math.floor((date.getTime() - new Date(item.transactionTime).getTime()) / 86400000)} />
                    )
                })}
            </>}

            {noData && <NoActivity />}

            {loading &&
                <ListViewSkeltonCard loop={10} />
            }

            {data.length > 0 &&
                <div className="pageNavigator">
                    <button className="btn" onClick={() => handlePageChange("DEC")}>
                        <img src={LeftArrow} alt="leftArrow" />
                    </button>
                    <p className="page--txt">Page</p>
                    <p className="page--count">{pageNo}</p>
                    <p className="page--txt">of &nbsp; {maxPage}</p>
                    <button className="btn" onClick={() => handlePageChange("INC")}>
                        <img src={RightArrow} alt="rightArrow" />
                    </button>
                </div>
            }
        </Fragment>
    )
}

export default WalletActivity
