import React, { Fragment, useState } from 'react'
import logo from "../../images/logo.svg"
import OtpInput from 'react-otp-input';


const UserEmailExampleModal = () => {

    const [showModal, setShowModal] = useState(false)
    const [otp, setOtp] = useState()

    const handleToggleModal = () => {
        setShowModal(!showModal)
    }
    const handleChange = (otp) => setOtp(otp);

    return (
        <Fragment>
            <button className='btn' onClick={handleToggleModal}>open</button>
            <div className={`modal fade ${showModal ? "show" : ""}`} role="dialog">
                <div className="modal-dialog centered username-modal-box no-transform-txt">
                    <div className="modal-content welcome-content-box text-center">
                        <p className='clr-gray font14Txt'>Having trouble viewing this email?</p>
                        <p className='font24Txt'>Welcome</p>
                        <div className='modal-logo-block'>
                            <img src={logo} alt="logo" className='modal-logo-item' />
                        </div>

                        <p className='font18Txt mt-10 mb-20 w-80'>Welcome to Club Metaverse. Please use the below mentioned 4-Digit code to verify your e-mail.</p>

                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            className="otp-input-form emailotp"
                            containerStyle="otp-grid"
                            isInputNum
                        />
                        <p className='font18Txt clr-gray mb-50 mt-20'>[This code will expire in 1 hour]</p>
                        <p className='font18Txt clr-gray mb-0'>Thank you</p>
                        <p className='font18Txt clr-gray'>The CLUB METAVERSE team</p>
                        <div className='bdr-btm mt-50 mb-20' />
                        <p className='mb-0 font12Txt clr-gray'>Problems or Questions? | <span className='clr-primary'>support@clubmetaverse.com</span></p>
                        <p className='font12Txt clr-gray'>® 2022 Ron Revog Inc. All rights reserved</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UserEmailExampleModal