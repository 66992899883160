const initialState = {
    price: ''
}

const ethPriceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ETH_PRICE":
            return {
                ...state,
                price: action.price
            }
        default:
            return state
    }
}

export default ethPriceReducer
