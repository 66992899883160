import React from "react"


const BlockChainMsg = (props) => {

    const message = props?.message

    return (
        <>
            {message.message &&
                <p className={`alert fixAlert mx-auto ${message.res ? "alert-success" : "alert-danger"}`}>
                    {message.res ? <p>
                        <a href={`https://mumbai.polygonscan.com/tx` + '/' + message.message} className="underline"
                            target="_blank">
                            Click Here
                        </a> to see Transaction

                    </p> : message.message}
                </p>
            }
        </>
    )
}
export default BlockChainMsg
