import React, { Fragment } from 'react'

const WalletGridIcon = () => {
    return (
        <Fragment>
            <div className="wallet-tab-icon">
                <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="IconHoverEffect" d="M32.3011 34.2068H0.777597C0.570888 34.2054 0.373119 34.1224 0.227443 33.9757C0.0817681 33.829 1.30957e-05 33.6307 1.77512e-05 33.424V1.77966C-0.0006752 1.67711 0.0189262 1.57543 0.0576929 1.48048C0.0964596 1.38553 0.153625 1.29919 0.225899 1.22643C0.298173 1.15367 0.384127 1.09592 0.478811 1.05651C0.573496 1.01711 0.67504 0.996824 0.777597 0.996826H32.3011C32.4036 0.996824 32.5052 1.01711 32.5999 1.05651C32.6946 1.09592 32.7805 1.15367 32.8528 1.22643C32.9251 1.29919 32.9822 1.38553 33.021 1.48048C33.0598 1.57543 33.0794 1.67711 33.0787 1.77966V33.4187C33.0794 33.5215 33.0598 33.6235 33.0211 33.7187C32.9824 33.814 32.9253 33.9007 32.8531 33.9738C32.7809 34.047 32.695 34.1053 32.6003 34.1452C32.5056 34.1852 32.4039 34.2061 32.3011 34.2068ZM0.961484 33.2086H32.1014V1.98982H0.977245L0.961484 33.2086Z" />
                    <path className="IconHoverEffect" d="M9.61989 11.4417H4.36598C4.26317 11.441 4.16151 11.42 4.0668 11.3801C3.97208 11.3401 3.88617 11.2818 3.81396 11.2087C3.74176 11.1355 3.68467 11.0488 3.64597 10.9536C3.60727 10.8583 3.5877 10.7564 3.5884 10.6536V5.39966C3.5877 5.29686 3.60727 5.19492 3.64597 5.09968C3.68467 5.00444 3.74176 4.91775 3.81396 4.84457C3.88617 4.77139 3.97208 4.71315 4.0668 4.67317C4.16151 4.63319 4.26317 4.61226 4.36598 4.61157H9.61989C9.7227 4.61226 9.82436 4.63319 9.91907 4.67317C10.0138 4.71315 10.0997 4.77139 10.1719 4.84457C10.2441 4.91775 10.3012 5.00444 10.3399 5.09968C10.3786 5.19492 10.3982 5.29686 10.3975 5.39966V10.6536C10.3982 10.7564 10.3786 10.8583 10.3399 10.9536C10.3012 11.0488 10.2441 11.1355 10.1719 11.2087C10.0997 11.2818 10.0138 11.3401 9.91907 11.3801C9.82436 11.42 9.7227 11.441 9.61989 11.4417ZM4.57613 10.4434H9.40448V5.59931H4.57613V10.4434Z" />
                    <path className="IconHoverEffect" d="M19.1657 11.4414H13.9118C13.7037 11.44 13.5046 11.3564 13.3579 11.2088C13.2113 11.0611 13.129 10.8614 13.129 10.6533V5.39943C13.1276 5.29549 13.1468 5.19231 13.1854 5.09582C13.2241 4.99934 13.2814 4.91145 13.3542 4.83722C13.427 4.76299 13.5137 4.70389 13.6094 4.66332C13.7051 4.62275 13.8079 4.60151 13.9118 4.60083H19.1657C19.3738 4.60222 19.5729 4.68586 19.7196 4.8335C19.8663 4.98115 19.9486 5.18081 19.9486 5.38892V10.6428C19.9499 10.7468 19.9308 10.8499 19.8921 10.9464C19.8535 11.0429 19.7961 11.1308 19.7233 11.205C19.6506 11.2793 19.5638 11.3384 19.4681 11.3789C19.3724 11.4195 19.2697 11.4407 19.1657 11.4414ZM14.122 10.4432H18.9503V5.59907H14.122V10.4432Z" />
                    <path className="IconHoverEffect" d="M28.7175 11.4417H23.4636C23.3608 11.441 23.2592 11.42 23.1645 11.3801C23.0697 11.3401 22.9838 11.2818 22.9116 11.2087C22.8394 11.1355 22.7823 11.0488 22.7436 10.9536C22.7049 10.8583 22.6854 10.7564 22.6861 10.6536V5.39966C22.6854 5.29686 22.7049 5.19492 22.7436 5.09968C22.7823 5.00444 22.8394 4.91775 22.9116 4.84457C22.9838 4.77139 23.0697 4.71315 23.1645 4.67317C23.2592 4.63319 23.3608 4.61226 23.4636 4.61157H28.7175C28.8204 4.61226 28.922 4.63319 29.0167 4.67317C29.1114 4.71315 29.1974 4.77139 29.2696 4.84457C29.3418 4.91775 29.3988 5.00444 29.4376 5.09968C29.4763 5.19492 29.4958 5.29686 29.4951 5.39966V10.6536C29.4958 10.7564 29.4763 10.8583 29.4376 10.9536C29.3988 11.0488 29.3418 11.1355 29.2696 11.2087C29.1974 11.2818 29.1114 11.3401 29.0167 11.3801C28.922 11.42 28.8204 11.441 28.7175 11.4417ZM23.6738 10.4434H28.5021V5.59931H23.6738" />
                    <path className="IconHoverEffect" d="M9.61983 21.0197H4.36591C4.26222 21.0197 4.15956 20.9991 4.06389 20.9591C3.96822 20.9191 3.88145 20.8605 3.80862 20.7867C3.73578 20.7129 3.67834 20.6253 3.63963 20.5291C3.60091 20.4329 3.58169 20.33 3.58308 20.2263V14.9724C3.58239 14.8696 3.60195 14.7677 3.64065 14.6724C3.67936 14.5772 3.73644 14.4905 3.80864 14.4173C3.88085 14.3441 3.96676 14.2859 4.06148 14.2459C4.15619 14.2059 4.25785 14.185 4.36066 14.1843H9.61457C9.71782 14.1843 9.82005 14.2047 9.91537 14.2444C10.0107 14.2841 10.0972 14.3422 10.17 14.4155C10.2427 14.4887 10.3003 14.5756 10.3393 14.6712C10.3784 14.7668 10.3981 14.8692 10.3974 14.9724V20.2263C10.3988 20.3296 10.3797 20.4321 10.3414 20.5279C10.303 20.6238 10.246 20.7111 10.1737 20.7848C10.1014 20.8586 10.0153 20.9173 9.92023 20.9576C9.82517 20.9979 9.72308 21.019 9.61983 21.0197ZM4.57607 20.0214H9.40442V15.1773H4.57607V20.0214Z" />
                    <path className="IconHoverEffect" d="M19.1657 21.0193H13.9118C13.8081 21.0193 13.7055 20.9987 13.6098 20.9587C13.5141 20.9187 13.4273 20.8601 13.3545 20.7863C13.2817 20.7125 13.2242 20.625 13.1855 20.5288C13.1468 20.4326 13.1276 20.3297 13.129 20.226V14.9721C13.1283 14.8686 13.148 14.7659 13.187 14.6701C13.2259 14.5742 13.2834 14.4869 13.3561 14.4132C13.4288 14.3396 13.5153 14.2809 13.6106 14.2407C13.706 14.2005 13.8083 14.1794 13.9118 14.1787H19.1657C19.2692 14.1794 19.3716 14.2005 19.4669 14.2407C19.5623 14.2809 19.6488 14.3396 19.7215 14.4132C19.7941 14.4869 19.8516 14.5742 19.8906 14.6701C19.9296 14.7659 19.9493 14.8686 19.9486 14.9721V20.226C19.95 20.3297 19.9307 20.4326 19.892 20.5288C19.8533 20.625 19.7959 20.7125 19.723 20.7863C19.6502 20.8601 19.5634 20.9187 19.4678 20.9587C19.3721 20.9987 19.2694 21.0193 19.1657 21.0193ZM14.122 20.0211H18.9503V15.177H14.122V20.0211Z" />
                    <path className="IconHoverEffect" d="M28.718 21.0197H23.4641C23.3604 21.0197 23.2577 20.9991 23.162 20.9591C23.0664 20.9191 22.9796 20.8605 22.9068 20.7867C22.8339 20.7129 22.7765 20.6253 22.7378 20.5291C22.6991 20.4329 22.6798 20.33 22.6812 20.2263V14.9724C22.6805 14.8696 22.7001 14.7677 22.7388 14.6724C22.7775 14.5772 22.8346 14.4905 22.9068 14.4173C22.979 14.3441 23.0649 14.2859 23.1596 14.2459C23.2543 14.2059 23.356 14.185 23.4588 14.1843H28.7127C28.816 14.1843 28.9182 14.2047 29.0135 14.2444C29.1088 14.2841 29.1954 14.3422 29.2681 14.4155C29.3409 14.4887 29.3984 14.5756 29.4375 14.6712C29.4765 14.7668 29.4962 14.8692 29.4956 14.9724V20.2263C29.4969 20.3296 29.4779 20.4321 29.4395 20.5279C29.4011 20.6238 29.3441 20.7111 29.2719 20.7848C29.1996 20.8586 29.1134 20.9173 29.0184 20.9576C28.9233 20.9979 28.8212 21.019 28.718 21.0197ZM23.6742 20.0214H28.5026V15.1773H23.6742V20.0214Z" />
                    <path className="IconHoverEffect" d="M9.61987 30.5974H4.36596C4.15925 30.596 3.96148 30.5129 3.8158 30.3663C3.67013 30.2196 3.58837 30.0213 3.58838 29.8146V24.5607C3.58837 24.354 3.67013 24.1556 3.8158 24.009C3.96148 23.8623 4.15925 23.7792 4.36596 23.7778H9.61987C9.82658 23.7792 10.0244 23.8623 10.17 24.009C10.3157 24.1556 10.3975 24.354 10.3975 24.5607V29.8146C10.3975 30.0213 10.3157 30.2196 10.17 30.3663C10.0244 30.5129 9.82658 30.596 9.61987 30.5974ZM4.57611 29.5992H9.40446V24.7551H4.57611V29.5992Z" />
                    <path className="IconHoverEffect" d="M19.1658 30.5976H13.9119C13.7043 30.5976 13.5052 30.5151 13.3584 30.3683C13.2115 30.2215 13.1291 30.0223 13.1291 29.8147V24.5608C13.127 24.4567 13.1457 24.3532 13.1841 24.2564C13.2225 24.1596 13.2798 24.0714 13.3527 23.997C13.4256 23.9227 13.5126 23.8636 13.6086 23.8233C13.7047 23.7829 13.8078 23.7622 13.9119 23.7622H19.1658C19.3734 23.7622 19.5726 23.8447 19.7194 23.9915C19.8662 24.1383 19.9486 24.3374 19.9486 24.545V29.799C19.9507 29.9031 19.932 30.0066 19.8936 30.1034C19.8552 30.2002 19.7979 30.2884 19.725 30.3627C19.6521 30.4371 19.5651 30.4962 19.4691 30.5365C19.3731 30.5768 19.27 30.5976 19.1658 30.5976ZM14.1221 29.5993H18.9504V24.7552H14.1221V29.5993Z" />
                    <path className="IconHoverEffect" d="M28.7175 30.5974H23.4636C23.2569 30.596 23.0591 30.5129 22.9135 30.3663C22.7678 30.2196 22.686 30.0213 22.686 29.8146V24.5607C22.686 24.354 22.7678 24.1556 22.9135 24.009C23.0591 23.8623 23.2569 23.7792 23.4636 23.7778H28.7175C28.9242 23.7792 29.122 23.8623 29.2677 24.009C29.4134 24.1556 29.4951 24.354 29.4951 24.5607V29.8146C29.4951 30.0213 29.4134 30.2196 29.2677 30.3663C29.122 30.5129 28.9242 30.596 28.7175 30.5974ZM23.6738 29.5992H28.5021V24.7551H23.6738V29.5992Z" />
                </svg>

            </div>
        </Fragment>
    )
}
export default WalletGridIcon