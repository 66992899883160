import React, { useState, useEffect } from "react";
import ListViewSkeltonCard from "../MarketPlace/ListViewSkeletonCard";
import MarketListViewCard from "../MarketPlace/MarketListViewCard";
import WalletListViewCard from "./WalletListViewCard";

const WalletListViewItems = (props) => {
  const [viewType, setViewType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [type, setType] = useState("");

  useEffect(() => {
    setViewType(props?.viewType);
    setLoading(props?.loading);
    setData(props?.data);
    setType(props?.type);
  }, [props]);

  return (
    viewType == 2 && (
      <div className=" mt-3">
        {loading && <ListViewSkeltonCard loop={10} data="market" />}
        {!loading && data.length > 0 && (
          <WalletListViewCard data={data} type={type} />
        )}
      </div>
    )
  );
};

export default WalletListViewItems;
