import React, {Fragment, useEffect, useState} from "react";
import SkeletonCard from "./SkeletonCard";
import AvatarCard from "../AvatarCard";

const MarketPlaceGridViewItems = (props) => {

    const [viewType, setViewType] = useState(0)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        setViewType(props?.viewType)
        setLoading(props?.loading)
        setData(props?.data)
    }, [props])

    return (
        viewType === 1 &&
        <div className="avatar-card-grid">
            {loading &&
            <Fragment>
                <SkeletonCard loop={10}/>
            </Fragment>
            }

            {!loading && data.length > 0 &&
            <AvatarCard data={data}/>
            }
        </div>

    )
}

export default MarketPlaceGridViewItems
