import React, {Fragment, useEffect, useState} from "react";
import * as Web3 from "web3";
import {useParams} from "react-router-dom"
import {PRIMARY_SALE_ABI, SECONDARY_SALE_ABI} from "../../utils/ABI";
import {PRIMARY_SALE_ADDRESS, SECONDARY_SALE_ADDRESS} from "../../utils/config";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {getContractAddressByURL} from "../../utils/helper";
import BlockChainMsg from "../blockChsainTransactionMsg";
import SellNFTModal from "./SellNFTModal";
import {BuySuccessModalShowHide, SellNFTModalAction,SaleSuccessModalShowHide} from "../../redux/actions/modalActions";
import BuySuccessModal from '../Detail/SuccessModal'


const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(SECONDARY_SALE_ABI, SECONDARY_SALE_ADDRESS);

const ListAndBuyFormNFT = (props) => {

    const [data, setData] = useState({})
    const [message, setMessage] = useState({"message": "", res: false})

    useEffect(() => {
        setData(props?.data)
    }, [props])

    const params = useParams()
    const NFT_CONTRACT = (params.nftContract)
    const NFT_ID = params.nftId
    const dispatch = useDispatch()

    const selector = useSelector(state => state)
    const userAddress = selector.user.address
    //consollog('userAddress',userAddress)
    const {addToast} = useToasts()

    const handleOpenSellModal = () => {
        dispatch(SellNFTModalAction(true))
    }

    const handleBuyNFT = () => {
        if (!userAddress) {
            addToast("Please Login to continue", {appearance: "error", autoDismiss: true})
        } else {
            try {
                const listedPrice = (Math.pow(10, 18) * data.price).toString()
                //consollog('listedPrice',listedPrice)
                SECONDARY_SALE_CONTRACT.methods
                    .buy(NFT_CONTRACT, NFT_ID)
                    .send({
                        from: userAddress,
                        value: listedPrice,
                    })
                    .on("transactionHash", (hash) => {
                        //consollog("transactionHash  sendToken", hash);
                        setMessage({"message": hash, "res": true})
                        // dispatch(BuySuccessModalShowHide(true))

                    })
                    .on("receipt", (receipt) => {
                        //consollog("receipt sendToken", receipt);
                    })
                    .on("confirmation", (confirmationNumber, receipt) => {
                        //consollog("confirmationNumber sendToken", confirmationNumber);
                        //consollog("receipt sendToken", receipt);
                        if (confirmationNumber === 0) {
                            dispatch(BuySuccessModalShowHide(true))
                        }
                    })
                    .on("error", (error) => {
                        //consollog("error sendToken", error);
                        addToast("Failed" + error?.message, {appearance: "error", autoDismiss: true})
                    });

            } catch (e) {
                addToast("Failed to buy NFT " + e, {appearance: "error", autoDismiss: true})
            }
        }
    }


    return (
        <Fragment>

            <div className="custom-dropdown-width mt-3">
                {(data.owner && userAddress && (data.owner).toLowerCase() === userAddress.toLowerCase()) && !(data.isListed) &&
                <Fragment>

                    <button className="btn applyCustomBtn br-0" onClick={handleOpenSellModal}>
                        <div className="pos-rel">
                            <p className="font16Txt orbFont mb-0 clr-Primary">Sell Items</p>
                        </div>
                    </button>
                    <BlockChainMsg message={message}/>
                    <SellNFTModal/>
                </Fragment>
                }

                {(data.owner && userAddress && data.isListed && (data.owner).toLowerCase() !== userAddress.toLowerCase()) &&
                <Fragment>
                    <button className="btn applyCustomBtn br-0" onClick={handleBuyNFT}>
                        <div className="pos-rel">
                            <p className="font16Txt orbFont mb-0 clr-Primary">Buy Item</p>
                        </div>
                    </button>
                    <BlockChainMsg message={message}/>
                    <BuySuccessModal hash={message} cardName={data?.metadata?.name}/>
                </Fragment>
                }

            </div>
        </Fragment>
    )
}

export default ListAndBuyFormNFT
