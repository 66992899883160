import React, { Fragment, useState } from "react"
import "../css/sidebar.css"
import GeneralTab from "./Sidebar/General"
import RealEstateGeneral from "./Sidebar/RealEstate/RealEstateGeneral"
import RealEstateStats from "./Sidebar/RealEstate/RealEstateStats"
import RealEstateTraits from "./Sidebar/RealEstate/RealEstateTraits"
import AvatarGeneral from "./Sidebar/Avatar/AvatarGeneral"
import StatsTab from "./Sidebar/Stats"
import TraitsTab from "./Sidebar/Traits"
import AvatarStats from "./Sidebar/Avatar/AvatarStats"
import AvatarTraits from "./Sidebar/Avatar/AvatarTraits"
import { useParams } from "react-router";
import { getRarityClass } from "../utils/helper";
import { useDispatch } from "react-redux";
import filterReducer from "./../redux/reducers/filtersChanges";
import { ClearAllFilter } from "../redux/actions/actions";

const Sidebar = (props) => {

    const dispatch = useDispatch()
    const params = useParams()

    const NFT_URL = params.nftContract

    const [active, setActive] = useState(1)
    const [rarityApiData, setRarityApiData] = useState([])
    const ToggleTab = (index) => {
        setActive(index)
    }
    // props.handleRarityChange(rarityApiData)

    const clearAll = () => {
        console.log("call")
        dispatch(ClearAllFilter())
        // window.location.reload()
    }



    return (
        <Fragment>
            <div className="avatar-sidebar">
                <div className="sidebar-heading">
                    <p className="font26Txt orbFont clr-red mb-0">Filter</p>
                    <button className="btn font12Txt clr-primary blueHoverBtn" onClick={clearAll}>Clear Filter</button>
                </div>
                <div className="side-tab-group">
                    <button
                        className={`btn font16Txt br-0  ${active === 1 ? "generalSidebarActiveBtn clr-Primary" : "generalSidebarGrayBtn clr-gray"
                            }`}
                        onClick={() => ToggleTab(1)}
                    >
                        <div className="pos-rel">
                            <p className="font18Txt mb-0">General</p>
                        </div>
                    </button>
                    <button
                        className={`btn font16Txt br-0 ${active === 2 ? "traitSidebarActiveBtn clr-Primary" : "traitSidebarGrayBtn clr-gray"
                            }`}
                        onClick={() => ToggleTab(2)}
                    >
                        <div className="pos-rel">
                            <p className="font18Txt mb-0">Traits</p>
                        </div>

                    </button>
                    <button
                        className={`btn font16Txt br-0 ${active === 3 ? "statSidebarActiveBtn clr-Primary" : "statSidebarGrayBtn clr-gray"
                            }`}
                        onClick={() => ToggleTab(3)}
                    >
                        <div className="pos-rel">
                            <p className="font18Txt mb-0">Stats</p>
                        </div>
                    </button>
                </div>


                {/*old code*/}

                {/* {active === 1 && <GeneralTab/>}*/}
                {/*{active === 2 && <TraitsTab />}*/}
                {/*{active === 3 && <StatsTab />}*/}
                {/*old code*/}


                {NFT_URL === 'real-estate' &&
                    <>
                        <p className="font14Txt clr-white text-uppercase mt-30 pl-25">Apartment</p>
                        {active === 1 && <RealEstateGeneral />}
                        {active === 2 && <RealEstateTraits />}
                        {active === 3 && <RealEstateStats />}
                    </>
                }


                {NFT_URL === 'avatar' &&
                    <>
                        <p className="font14Txt clr-white text-uppercase mt-30 pl-25">Avatar</p>
                        {active === 1 && <AvatarGeneral setRarityApiData={setRarityApiData} />}
                        {active === 2 && <AvatarTraits onClick={clearAll} />}
                        {active === 3 && <AvatarStats />}
                    </>
                }




            </div>
        </Fragment>
    )
}

export default Sidebar






