import React, { Fragment } from 'react'
import noItemSale from "../../images/noItemSale.svg"


const NoItemSale = () => {
    return (
        <Fragment>
            <div className="wallet-no-case-wrapper">
                <div className="wallet-empty-block">
                    <img src={noItemSale} alt="noItemSelect" className="noCaseImageFix" />
                </div>
            </div>
        </Fragment>
    )
}

export default NoItemSale
