import React, { Fragment, useEffect, useState } from 'react';
import "../css/wallet.css"
import { useHistory, useParams } from "react-router-dom"
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import WalletForSale from '../component/MyWallet/ForSale';
import MyCollectionList from '../component/MyWallet/MyCollectionList';
import WalletActivity from '../component/MyWallet/Activity';
import { useSelector } from "react-redux";
import metaLogo from '../images/metaverseLogo.png'
import WalletGridIcon from '../component/MyWallet/GridIcon';
import WalletListIcon from '../component/MyWallet/ListIcon';
import WalletActivityIcon from '../component/MyWallet/ActivityIcon';
import WalletOfferIcon from '../component/MyWallet/OfferIcon';

const MyWallet = () => {
    const selector = useSelector(state => state)
    const history = useHistory()
    const params = useParams()
    const tabName = params.tabName

    const userAddress = selector.user.address

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        if (newValue === "1") {
            history.push("/profile/collection")
        } else if (newValue === "2") {
            history.push("/profile/sale")
        } else if (newValue === "3") {
            history.push("/profile/activity")
        }
    };

    useEffect(() => {
        //detect change of tab
        if (tabName === "collection") {
            setValue("1")
        } else if (tabName === "sale") {
            setValue("2")
        } else if (tabName === "activity") {
            setValue("3")
        }

    }, [tabName])

    return (
        <Fragment>
            <div className="container mt-50 pb-5">
                <div className="text-center">
                    <div className="wallet-thumb-block">
                        <img src={metaLogo} alt="metaLogo" className="wallet-thumb" />
                    </div>
                    <p className="font24BoldTxt clr-red orbFont mt-15 mb-1">My Wallet</p>
                    {/*<p className="font14Txt clr-white mb-0">JOINED: FEBRUARY 2021</p>*/}
                    <p className="font12Txt clr-gray">{userAddress}</p>

                </div>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example"
                                variant="scrollable"
                                TabIndicatorProps={{ style: { background: "#00FFFF" } }}
                                className="main-dark-card"
                            >
                                <Tab icon={<WalletGridIcon />} label="Collected" value="1"
                                    className="chakraFont clr-gray font16Txt wallet-tab-name dash-tab-fix cp" />
                                <Tab icon={<WalletListIcon />} label="My Listing" value="2"
                                    className="chakraFont clr-gray font16Txt wallet-tab-name dash-tab-fix cp" />
                                <Tab icon={<WalletActivityIcon />} label="Activity" value="3"
                                    className="chakraFont clr-gray font16Txt wallet-tab-name dash-tab-fix cp" />
                                {/* <Tab icon={<WalletOfferIcon />} label="Offers" value="4" className="chakraFont clr-gray font16Txt wallet-tab-name" /> */}
                            </TabList>
                        </Box>
                        <TabPanel value="1" className="wallet-tab"><MyCollectionList /></TabPanel>
                        <TabPanel value="2" className="wallet-tab"><WalletForSale /></TabPanel>
                        <TabPanel value="3" className="wallet-tab"><WalletActivity /></TabPanel>
                        {/* <TabPanel value="4" className="wallet-tab"><WalletOffers /></TabPanel> */}
                    </TabContext>
                </Box>
            </div>
        </Fragment>
    )
}
export default MyWallet
