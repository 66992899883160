import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import "../css/detail.css";
import LeftArrow from "../images/leftArrowBlue.svg";
import AvatarStatsDetailPage from "../component/Detail/AvatarStatsDetailPage";
import axios from "axios";
import Loader from "react-loader-spinner";
import {
  API_BASE_URL,
  AVATAR_URL,
  DEFAULT_WEB3_ADDRESS,
  ITEMS_URL,
  PRIMARY_SALE_ADDRESS,
  REAL_STATE_URL,
  SECONDARY_SALE_ADDRESS,
} from "../utils/config";
import AboutDetail from "../component/Detail/aboutDetailPage";
import TraitsDetailPage from "../component/Detail/TraitsDetailPage";
import SaleHistory from "../component/Detail/SaleHistoryDetailPage";
import AvatarDetailImage from "../component/Detail/AvatarDetailImage";
import ItemsDetailImage from "../component/Detail/ItemsDetailImage";
import RealEstateDetailImage from "../component/Detail/RealEstateDetailImage";
import * as Web3 from "web3";
import { PRIMARY_SALE_ABI, SECONDARY_SALE_ABI } from "../utils/ABI";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import { getContractBasedName, getContractURL } from "../utils/helper";
import SuccessModal from "../component/Detail/SuccessModal";
import RealEstateStatsDetailPage from "../component/Detail/RealEstateStatsDetailPage";
import ItemsStatsDetailPage from "../component/Detail/ItemsStatsDetailPage";

const defaultWeb3 = new Web3(DEFAULT_WEB3_ADDRESS);
const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(
  PRIMARY_SALE_ABI,
  PRIMARY_SALE_ADDRESS
);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(
  SECONDARY_SALE_ABI,
  SECONDARY_SALE_ADDRESS
);

const Detail = () => {
  const params = useParams();
  const history = useHistory();

  const selector = useSelector((state) => state);
  const userAddress = selector.user.address;
  const { addToast } = useToasts();
  const [thumbImage, setThumbImage] = useState();

  const [previousSales, setPreviousSales] = useState([]);
  const [detailPageData, setDetailPageData] = useState({});
  const [loading, setLoading] = useState(false);

  const NFT_CONTRACT = params.nftContract;
  const NFT_URL = getContractURL(params.nftContract).toLowerCase();
  const NFT_ID = params.nftId;

  const getDetailPageData = () => {
    if (!NFT_CONTRACT || !NFT_ID) return;
    setLoading(true);
    axios
      .get(
        API_BASE_URL +
          "smart-contract/nft-details/" +
          NFT_CONTRACT +
          "/" +
          NFT_ID
      )
      .then(function (res) {
        setDetailPageData(res?.data);
      })
      .catch((err) => {
        //consollog("error fetching nft details", err)
        setDetailPageData({});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saleHistory = () => {
    if (!NFT_CONTRACT || !NFT_ID) return;
    axios
      .get(
        API_BASE_URL +
          "marketplace/secondary/previous-sales/" +
          NFT_CONTRACT +
          "/" +
          NFT_ID
      )
      .then(function (res) {
        setPreviousSales(res?.data?.previousSales);
        setThumbImage(res?.data?.image);
      })
      .catch((res) => {
        setPreviousSales([]);
      });
  };
  const backBtnDetailPage = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetailPageData();
    saleHistory();
  }, [NFT_CONTRACT, NFT_ID]);

  return (
    <Fragment>
      {loading && (
        <div className="col-md-12 mt-5 mb-5 text-center">
          <Loader
            className="loaderClass"
            type="Oval"
            color="#00BFFF"
            height={60}
            width={60}
          />
        </div>
      )}
      {!loading && (
        <section>
          <div className="container mt-50">
            <button className="btn d-flex align-items-center">
              <img src={LeftArrow} alt="left arrow" />
              <p
                className="font16Txt mb-0 ml-2 clr-white"
                onClick={backBtnDetailPage}
              >
                Back
              </p>
            </button>

            <div className="detail-page-grid">
              <div className="left-detail-block">
                {NFT_URL === REAL_STATE_URL.toLowerCase() && (
                  <RealEstateDetailImage image={thumbImage} />
                )}

                {NFT_URL === AVATAR_URL.toLowerCase() && (
                  <AvatarDetailImage image={thumbImage} />
                )}

                {NFT_URL === ITEMS_URL.toLowerCase() && (
                  <ItemsDetailImage image={thumbImage} />
                )}
              </div>
              <div className="right-detail-block">
                <p className="font40Txt clr-white">
                  {getContractBasedName(NFT_CONTRACT.toLowerCase())}

                  {/*{detailPageData?.metadata?.name}*/}
                </p>
                {(detailPageData.name !== "" ||
                  detailPageData.rarityScore !== "" ||
                  detailPageData.maxSupply !== "" ||
                  detailPageData.owner !== "") && (
                  <AboutDetail detailPageData={detailPageData} nftId={NFT_ID} />
                )}
                {/*{(detailPageData.baseHP !== '' || detailPageData.healthRegen !== '' || detailPageData.attackPower !== '' || detailPageData.defense !== '') &&*/}

                {/* -------------------This is RealEstate Stats Card------------------- */}

                <AvatarStatsDetailPage
                  detailPageData={detailPageData}
                  // healthRegen={detailPageData.healthRegen}
                  // attackPower={detailPageData.attackPower}
                  // defense={detailPageData.defense}
                />

                {/*}*/}
                {/* -------------------This is RealEstate Stats Card------------------- */}

                {/* <RealEstateStatsDetailPage /> */}

                {/* -------------------This is Items Stats Card------------------- */}

                {/* <ItemsStatsDetailPage /> */}

                <TraitsDetailPage detailPageData={detailPageData} />

                {/*{(detailPageData.namePlateColor !== '' || detailPageData.wireFrame !== '' || detailPageData.head !== '' || detailPageData.bodyTexture !== '' || detailPageData.watch !== '' || detailPageData.hands !== '') &&*/}
                {/*<TraitsDetailPage detailPageData={detailPageData}/>*/}
                {/*}*/}
                <SaleHistory previousSales={previousSales} />
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default Detail;
