import {Route, Switch} from "react-router-dom";
import MyWallet from "./myWallet";
import Header from "../component/Header/Header";
import {Fragment} from "react"

const PrivateRoutes = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path="/profile/:tabName" component={MyWallet}/>
            </Switch>
        </Fragment>

    )
}

export default PrivateRoutes
