import React, { Fragment, useEffect, useState } from "react"
import heartIcon from "../../images/heart.svg"
import heartbeatIcon from "../../images/heartbeat.svg"
import punchIcon from "../../images/punch.svg"
import shieldIcon from "../../images/shield.svg"
import "../../css/detail.css"
import { getRarityClass, getRarityText } from "../../utils/helper";
import { HeartbeatIcon, HeartIcon, PunchIcon, ShieldIcon } from "../../utils/svgConst"

const AvatarStatsDetailPage = (props) => {
    const [data, setData] = useState({})
    const [attributes, setAttributes] = useState([])

    useEffect(() => {

        //consollog("traits", props?.detailPageData)
        setData(props?.detailPageData)
        setAttributes(props?.detailPageData?.metadata?.attributes)
    }, [props])
    return (
        <Fragment>
            <div className="main-dark-card p-25 mb-20">
                <p className="font21Txt clr-red orbFont mb-10">Stats</p>
                <div className="divider-line mb-10"></div>

                <div className="detail-stats-card">

                    {attributes && attributes.length > 0 && attributes.map((key, index) => {
                        return (
                            <Fragment key={index}>
                                {key?.attribute === "baseHP" && key?.type === 3 &&
                                    <div className="text-center">
                                        <p className="mb-2 font12BoldTxt clr-gray text-uppercase">Base HP</p>
                                        <div className="text-center">
                                            <HeartIcon />
                                            <p className="font21Txt d-inline-block ml-2 mb-0">{key?.value}</p>
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        )
                    })
                    }


                    {attributes && attributes.length > 0 && attributes.map((key, index) => {
                        return (
                            <Fragment key={index}>
                                {key?.attribute === "healthReganRate" && key?.type === 3 &&
                                    <div className="text-center">
                                        <p className="mb-2 font12BoldTxt clr-gray text-uppercase">HEALTH REGEN</p>
                                        <div className="text-center">
                                            <HeartbeatIcon />
                                            <p className="font21Txt d-inline-block ml-2 mb-0">{key?.value}</p>
                                        </div>
                                    </div>

                                }

                            </Fragment>
                        )
                    })
                    }

                    {attributes && attributes.length > 0 && attributes.map((key, index) => {
                        return (
                            <Fragment key={index}>
                                {key?.attribute === "bonusToDMG" && key?.type === 3 &&
                                    <div className="text-center">
                                        <p className="mb-2 font12BoldTxt clr-gray text-uppercase">bonus To DMG</p>
                                        <div className="text-center">
                                            <PunchIcon />
                                            <p className="font21Txt d-inline-block ml-2">{key?.value}</p>
                                        </div>
                                    </div>
                                }

                            </Fragment>
                        )
                    })
                    }

                    {attributes && attributes.length > 0 && attributes.map((key, index) => {
                        return (
                            <Fragment key={index}>
                                {key?.attribute === "bonusToDefence" && key?.type === 3 &&
                                    <div className="text-center">
                                        <p className="mb-2 font12BoldTxt clr-gray text-uppercase">DEFENSE</p>
                                        <div className="text-center">
                                            <ShieldIcon />
                                            <p className="font21Txt d-inline-block ml-2">{key?.value}</p>
                                        </div>
                                    </div>
                                }

                            </Fragment>
                        )
                    })
                    }



                    {/*comment about below code:          /!*old data show*!/*/}


                    {/*{props.baseHP &&*/}
                    {/*    <div>*/}
                    {/*        <p className="mb-2 font12Txt clr-Primary text-uppercase">Base HP</p>*/}
                    {/*        <img src={heartIcon} alt="heartIcon" />*/}
                    {/*        <p className="font21Txt d-inline-block ml-2">{props.baseHP}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/*{props.healthRegen &&*/}
                    {/*    <div>*/}
                    {/*        <p className="mb-2 font12Txt clr-Primary text-uppercase">HEALTH REGEN</p>*/}
                    {/*        <img src={heartbeatIcon} alt="heartIcon" />*/}
                    {/*        <p className="font21Txt d-inline-block ml-2">{props.healthRegen}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/*{props.attackPower &&*/}
                    {/*    <div>*/}
                    {/*        <p className="mb-2 font12Txt clr-Primary text-uppercase">ATTACK POWER</p>*/}
                    {/*        <img src={punchIcon} alt="heartIcon" />*/}
                    {/*        <p className="font21Txt d-inline-block ml-2">{props.attackPower}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/*{props.defense &&*/}
                    {/*    <div>*/}
                    {/*        <p className="mb-2 font12Txt clr-Primary text-uppercase">DEFENSE</p>*/}
                    {/*        <img src={shieldIcon} alt="heartIcon" />*/}
                    {/*        <p className="font21Txt d-inline-block ml-2">{props.defense}</p>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            </div>
        </Fragment>
    )
}

export default AvatarStatsDetailPage
