import React, { Fragment, useCallback, useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL, AVATAR_ADDRESS, ITEMS_ADDRESS, PRICE_TOKEN_ETH, REAL_STATE_ADDRESS } from "../../utils/config";
import { useSelector } from "react-redux";
import NoItemSale from "./noItemSale";
import ViewListIcon from "@mui/icons-material/ViewList"
import WalletGridViewItems from './WalletGridViewItems';
import WalletListViewItems from './WalletListViewItems';
import { Field, Form, Formik } from 'formik'
import DropdownInput from '../Dropdown/DropdownInput'
import LeftArrow from "../../images/arrowLeft.svg";
import RightArrow from "../../images/arrowRight.svg";
import searchIcon from "../../images/searchIcon.svg";
import { ListViewIcon, GridViewIcon } from '../../utils/svgConst';
import NavbarDropdown from "../Dropdown/NavbarDropdown"
import { SORTING_DATA } from "../../utils/helper";

const MyCollectionList = () => {

    const selector = useSelector(state => state)
    const userAddress = selector.user.address
    const [viewType, setViewType] = useState(1) // 1 used for grid view and 2 used for list view
    const [forSale, setForSale] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [noData, setNoData] = useState(false)
    const [pageLimit] = useState(10)
    const [pageNo, setPageNo] = useState(1)
    const [maxResult, setMaxResult] = useState(0)

    const [sortValue, setSortValue] = useState("0")
    const [sortValueSort, setSortValueSort] = useState("21")

    const maxPage = Math.ceil(maxResult / pageLimit)
    //
    const getContractAddress = () => {
        if (sortValue === "0") {
            return AVATAR_ADDRESS
        } else if (sortValue === '1') {
            return REAL_STATE_ADDRESS
        } else if (sortValue === "2") {
            return ITEMS_ADDRESS
        }
    }
    const contractBasedOnDropDownFilter = getContractAddress(sortValue)


    const getMyCollectionList = useCallback(() => {
        setLoading(true)
        setNoData(false)

        //to test card use this address 0xB6e3974F93B9e5790Ae0a3f4Aea00c83bdD26bfc

        const limit = "limit=" + pageLimit;
        const offset = "&skip=" + (pageNo === 1 ? 0 : ((pageNo - 1) * pageLimit))

        //sort by case with orderby and order
        const sortSplitValues = sortValueSort ? sortValueSort.toString().split("") : ""
        const orderBy = sortSplitValues.length > 0 ? "&orderBy=" + sortSplitValues[0] : "&orderBy=2";
        const order = sortSplitValues.length > 0 ? "&order=" + sortSplitValues[1] : "&order=1";
        //adding all required params in one varaiable

        const nftContract = "nftContracts=" + PRICE_TOKEN_ETH + "&nftContracts=" + contractBasedOnDropDownFilter;

        //if nft contract exist then pass in in params
        const nft = sortValue ? ("?" + nftContract + '&') : "?"

        const params = userAddress + nft + limit + offset + orderBy + order

        axios.get(API_BASE_URL + 'smart-contract/user-nfts/' + params)
            .then(function (res) {
                //consollog('rrr', res.data)
                setData(res.data.nfts)
                setMaxResult(res.data.totalNFTs)
                if (res.data.nfts.length === 0) {
                    setNoData(true)
                }
            }).catch((err) => {
                //consollog("my collection list error ", err)
                setData([])
                setNoData(true)

            }).finally(() => {
                setLoading(false)
            })

    }, [pageNo, sortValue, sortValueSort, contractBasedOnDropDownFilter])


    useEffect(() => {
        if (!userAddress) return
        getMyCollectionList()
    }, [userAddress, pageNo, sortValue, sortValueSort])

    const sortValues = [
        {
            name: 'ABCD',
            value: '1'
        },
        {
            name: 'EFGH',
            value: '2'
        },
        {
            name: 'IJKL',
            value: '3'
        },
    ]
    const sortValuesNft = [
        {
            name: 'Avatar',
            value: '0'
        },
        {
            name: 'Real Estate',
            value: '1'
        },
        {
            name: 'Items',
            value: '2'
        },
    ]
    const initialValues = {
        name: '',
    }

    const handleSubmit = () => {
        //consollog('values printed')
    }

    const handlePageChange = (type) => {
        //consollog('hit', type, maxPage, pageNo)
        if (type === "INC") {
            //when max page is matched with current page no then dont call any api
            if (pageNo >= maxPage) return
            setPageNo(prevState => prevState + 1)
        } else if (type === "DEC") {
            if (pageNo === 1) return
            setPageNo(prevState => prevState - 1)
        } else {
            //consollog("type not matched")
        }
    }
    const handleSortChange = (selectedSort) => {
        setSortValue(selectedSort)

    }
    const handleSortChangeSort = (selectedSort) => {
        debugger
        setSortValueSort(selectedSort)

    }

    return (
        <Fragment>

            {/* <WalletSearchbar /> */}

            {/* ------------------------------- This is the WalletSearchBar Code Starts ------------------------------- */}

            <div className="searchbar-grid">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    <Form className="search-area pos-rel">
                        <span>
                            <img src={searchIcon} alt="search" aria-hidden="true" className="search-icon" />
                        </span>
                        <Field type="text" name="search" placeholder="Search" className="w-100 h-100 wallet-input" />
                    </Form>
                </Formik>

                {/*<DropdownInput data={sortValuesNft}*/}
                {/*               setSortByNft={setSortByNft}*/}

                {/*/>*/}
                <NavbarDropdown
                    data={sortValuesNft}
                    selected={sortValue}
                    defaultOption={"Sort By"}
                    handleChangeSelection={handleSortChange}
                    className="h-100 chakraFont" />



                {/* <DropdownInput data={sortValuesNft} selected={sortValue}
                    defaultOption={"Sort By"}
                    handleChangeSelection={handleSortChange} /> */}


                <NavbarDropdown data={SORTING_DATA}
                    selected={sortValueSort}
                    defaultOption={"Sort By"}
                    handleChangeSelection={handleSortChangeSort}
                    className="h-100 chakraFont" />

                {/*<DropdownInput data={sortValues}*/}
                {/*               selected={1}*/}
                {/*/>*/}


                <div className="viewBtn-block ml-0">
                    <button
                        className={`btn br-0 gridBtn ${viewType === 1 ? "blueBtn lightBlueBtn" : "clr-gray"}`}
                        onClick={() => setViewType(1)}
                    >
                        <GridViewIcon />
                    </button>
                    <button
                        className={`btn br-0 gridBtn ${viewType === 2 ? "blueBtn lightBlueBtn" : "clr-gray"}`}
                        onClick={() => setViewType(2)}
                    >
                        <ListViewIcon />
                    </button>
                </div>
            </div>

            {/* ------------------------------- This is the WalletSearchBar Code Ends ------------------------------- */}

            <WalletGridViewItems
                viewType={viewType}
                data={data}
                loading={loading}
                type="collected"
                forSale={forSale}
            />

            <WalletListViewItems
                viewType={viewType}
                data={data}
                loading={loading}
                type="collected"
            />


            {!loading && noData && data.length === 0 &&
                <NoItemSale />
            }

            {data.length > 0 &&
                <div className="pageNavigator">
                    <button className="btn" onClick={() => handlePageChange("DEC")}>
                        <img src={LeftArrow} alt="leftArrow" />
                    </button>
                    <p className="page--txt">Page</p>
                    <p className="page--count">{pageNo}</p>
                    <p className="page--txt">of &nbsp; {maxPage}</p>
                    <button className="btn" onClick={() => handlePageChange("INC")}>
                        <img src={RightArrow} alt="rightArrow" />
                    </button>
                </div>
            }

        </Fragment>
    )
}
export default MyCollectionList