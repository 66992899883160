import React, {useEffect, useState} from 'react'
import SkeletonCard from "../MarketPlace/SkeletonCard";
import AvatarMarketplaceImage from "../MarketPlace/AvatarMarketplaceImage";
import {useToasts} from "react-toast-notifications";
import {useDispatch, useSelector} from "react-redux";
import * as Web3 from "web3";
import {PRIMARY_SALE_ABI, SECONDARY_SALE_ABI} from "../../utils/ABI";
import {PRIMARY_SALE_ADDRESS, SECONDARY_SALE_ADDRESS} from "../../utils/config";

const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(SECONDARY_SALE_ABI, SECONDARY_SALE_ADDRESS);


const WalletGridViewItems = (props) => {

    const {addToast} = useToasts()
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const userAddress = selector.user.address

    const [viewType, setViewType] = useState(0)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [forSale, setForSale] = useState()
    const [type, setType] = useState("")
    const [message, setMessage] = useState({"message": "", res: false})

    useEffect(() => {
        setViewType(props?.viewType)
        setLoading(props?.loading)
        setData(props?.data)
        setForSale(props?.forSale)
        setType(props?.type)
    }, [props])


    return (
        viewType === 1 &&
        <div className="avatar-card-grid my-wallet-block mt-3">
            {loading &&
            <SkeletonCard loop={10}/>
            }
            {!loading && data && data.length > 0 &&
            <AvatarMarketplaceImage collectorData={data}
                                    forSale={forSale}
                                    type={type}/>
            }

        </div>
    )
}

export default WalletGridViewItems
