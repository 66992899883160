export const loginSuccess = (payload) => {
    return {
        type: 'NORMAL_LOGIN_SUCCESS',
        payload,
    }
}

export const logout = () => {
    return {
        type: 'LOGOUT'
    }
}


export const ETHLogin = ({address}) => {
    return {
        type: 'ETH_LOGIN',
        address
    }
}

export const ETHLogout = () => {
    return {
        type: 'ETH_LOGOUT'
    }
}

export const AvatarRarityScore= (payloads)=>{
    return {
        type: "AvatarRarityScore",
        payloads
    }
}

export const AvatarTraitsCount= (payloads)=>{
    return {
        type: "AvatarTraitsCount",
        payloads
    }
}
export const AvatarMinMaxEthPrice= (payloads)=>{
    return {
        type: "AvatarMinMaxEthPrice",
        payloads
    }
}
export const AvatarTraitsUserNameColor= (payloads)=>{
    return {
        type: "AvatarTraitsUserNameColor",
        payloads
    }
}

//head
export const AvatarTraitsHead= (payloads)=>{
    return {
        type: "AvatarTraitsHead",
        payloads
    }
}
export const AvatarTraitsHeadStyle= (payloads)=>{
    return {
        type: "AvatarTraitsHeadStyle",
        payloads
    }
}
export const AvatarTraitsHeadColor= (payloads)=>{
    return {
        type: "AvatarTraitsHeadColor",
        payloads
    }
}

// torso
export const AvatarTorso= (payloads)=>{
    return {
        type: "AvatarTorso",
        payloads
    }
}
export const AvatarTorsoStyle= (payloads)=>{
    return {
        type: "AvatarTorsoStyle",
        payloads
    }
}
export const AvatarTorsoColor= (payloads)=>{
    return {
        type: "AvatarTorsoColor",
        payloads
    }
}

export const AvatarHand =(payloads)=>{
    return {
        type: "AvatarHand",
        payloads
    }
}
export const AvatarHandStyle =(payloads)=>{
    return {
        type: "AvatarHandStyle",
        payloads
    }
}

export const AvatarHandColor =(payloads)=>{
    return {
        type: "AvatarHandColor",
        payloads
    }
}

//watch
export const AvatarWatch =(payloads)=>{
    return {
        type: "AvatarWatch",
        payloads
    }
}
export const AvatarWatchColor =(payloads)=>{
    return {
        type: "AvatarWatchColor",
        payloads
    }
}



//rarity score for realEstate
export const RealEstateRarityScore =(payloads)=>{
    return {
        type: "RealEstateRarityScore",
        payloads
    }
}

export const RealEstateMinMaxEthPrice= (payloads)=>{
    return {
        type: "RealEstateMinMaxEthPrice",
        payloads
    }
}
export const RealEstateFloorNumber =(payloads)=>{
    return {
        type: "RealEstateFloorNumber",
        payloads
    }
}
export const RealEstateApartmentNumber =(payloads)=>{
    return {
        type: "RealEstateApartmentNumber",
        payloads
    }
}

export const RealEstateStatsFloorNumber =(payloads)=>{
    return {
        type: "RealEstateStatsFloorNumber",
        payloads
    }
}

export const RealEstateStatsApartmentNumber =(payloads)=>{
    return {
        type: "RealEstateStatsApartmentNumber",
        payloads
    }
}


//avatar Stats HEALTH REGAN RATE
export const AvatarBaseHp =(payloads)=>{
    return {
        type: "AvatarBaseHp",
        payloads
    }
}

export const AvatarHeathReaganRate =(payloads)=>{
    return {
        type: "AvatarHeathReaganRate",
        payloads
    }
}

export const AvatarBonusToDmg =(payloads)=>{
    return {
        type: "AvatarBonusToDmg",
        payloads
    }
}

export const AvatarBonusToDefence =(payloads)=>{
    return {
        type: "AvatarBonusToDefence",
        payloads
    }
}

export const ClearAllFilter = (payloads)=>{
    return {
        type: "ClearAllFilter",
        payloads
    }
}

export const FirstFilter = (payloads)=>{
    return {
        type: "FirstFilter",
        payloads
    }
}
