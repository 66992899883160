import React, { Fragment, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import CardImage from "../images/cardImage.svg";
import "../css/marketCard.css";
import "../css/CustomCard.css";
import { REAL_STATE_URL } from "../utils/config";
import { getContractBasedName, getUSDPrice } from "../utils/helper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRarityBG, getRarityText } from "../utils/helper";

const MarketCard = (props) => {
  const [data, setData] = useState([]);
  const selector = useSelector((state) => state);

  useEffect(() => {
    setData(props?.data);
  }, [props]);

  return (
    data &&
    data.length > 0 &&
    data.map((key, index) => {
      return (
        <Fragment key={key?.saleId}>
          <Link
            to={"/detail/" + key?.nftContract.toLowerCase() + "/" + key?.nftId}
          >
            <div className="marketCard">
              <div className={`profile-card ${getRarityBG(key.rarity)}`}>
                <div className="pos-rel ml-2">
                  <PersonIcon className={`${getRarityText(key.rarity)}`} />
                  <p
                    className={`font12BoldTxt ml-2 mb-0 d-ib ${getRarityText(
                      key.rarity
                    )}`}
                  >
                    #{key?.nftId}
                  </p>
                </div>
              </div>
              <div className="card-grayBG">
                <p className="font14Txt mt-2 text-center clr-white">
                  {getContractBasedName(key.nftContract.toLowerCase())}
                </p>
                <div className="text-center Avatar_image_block">
                  <img
                    src={key?.image ? key?.image : CardImage}
                    alt="cardImage"
                    className="avatarThumb"
                  />
                  {/* <img src={CardImage} alt="cardImage"
                                        className="avatarThumb" /> */}
                </div>
                <p className="font21BoldTxt text-center mb-0 mt-3 clr-white">
                  Ξ {key?.price}
                </p>
                <p className="font18Txt text-center mb-0 clr-gray">
                  $ {getUSDPrice(key?.price, selector.ethPrice.price)}
                </p>
              </div>
            </div>
          </Link>
        </Fragment>
      );
    })
  );
};

export default MarketCard;
