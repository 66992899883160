import React, { Fragment, useState } from "react";
import HandleClickOutside from "../../utils/helper";
import { CrossIcon, GetCodeBtn } from "../../utils/svgConst";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { API_BASE_URL, PRICE_TOKEN_ETH } from "../../utils/config";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import EnterOTPModal from "./EnterOTPModal";

import {
  EnterEmailModalAction,
  EnterOTPModalAction,
} from "../../redux/actions/modalActions";

const EnterEmailModal = () => {
  const dispatch = useDispatch();

  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const selector = useSelector((state) => state);

  const [showLogin, setShowLogin] = useState(false);
  const showModal = selector.modals.EnterEmailModalState;
  console.log({ showModal });

  const hideModalEmailLoginModal = () => {
    dispatch(EnterEmailModalAction(false));
  };
  const handleToggleLoginModal = () => {
    setShowLogin(!showLogin);
  };
  const handleCloseModal = () => {
    setShowLogin(false);
  };

  const loginModalRef = HandleClickOutside(() => {
    setShowLogin(false);
  });

  const [email, setEmail] = useState();
  const handleChange = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
  };
  const formInitialValues = {
    email: "",
  };
  const formValidationSchema = Yup.object({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email  is required"),
  });
  const handleFormSubmit = async (values) => {
    setLoading(true);
    const main = {
      email: values.email,
    };

    setEmail(values.email);

    const param = "?email=" + values.email;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer H7nTO^CJ0O%6",
    };
    axios
      .get(`https://metaverse-accounts.herokuapp.com/v1/nopwd/start` + param, {
        headers,
      })
      .then(function (response) {
        if (response.status === 200) {
          addToast("Verification code sent successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          dispatch(EnterEmailModalAction(false));
          dispatch(EnterOTPModalAction(true));
          // setEmail('')

          // setOtpDiv(true)
        }
      })
      .catch(function (error) {
        console.log("error verification api", error);
        addToast(error.message, { appearance: "error", autoDismiss: true });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {/*<button className="btn clr-white" onClick={handleToggleLoginModal}>login</button>*/}
      <div className={`modal fade ${showModal ? "show" : ""}`} role="dialog">
        <div className="modal-dialog username-modal-box">
          <div
            ref={loginModalRef}
            className="modal-content login-content-box pos-rel"
          >
            <button className="btn ml-auto" onClick={hideModalEmailLoginModal}>
              <CrossIcon />
            </button>
            <h1 className="font42Txt clr-white text-capitalize mt-50 text-center">
              Log in / Sign Up{" "}
            </h1>
            <h6 className="font21Txt text-capitalize w-80 mt-20">
              Get a code sent to your email to log in or sign up
            </h6>

            <Formik
              enableReinitialize="true"
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              <Form>
                <Field
                  className=" mt-50 detail-input-form font24Txt chakraFont text-center clr-white p-25"
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                />
                <p className="text-danger text-left mt-1">
                  <ErrorMessage name="email" />
                </p>

                <button className="btn mt-50 mb-50 w-100">
                  <GetCodeBtn />
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>

      <EnterOTPModal email={email} />
    </Fragment>
  );
};

export default EnterEmailModal;
