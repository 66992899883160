import React, { Fragment, useEffect, useState } from "react";
import HandleClickOutside from "../../utils/helper";
import logo from "../../images/logo.svg";
import { CreateUSernameBtn, CrossIcon, GetCodeBtn } from "../../utils/svgConst";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  EnterOTPModalAction,
  CreateUsernameModalAction,
} from "../../redux/actions/modalActions";
import axios from "axios";
import { loginSuccess } from "../../redux/actions/actions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const CreateUsernameModal = () => {
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const showModal = selector.modals.userNameCreatedModalState;

  const [showLogin, setShowLogin] = useState(false);
  const handleToggleLoginModal = () => {
    setShowLogin(!showLogin);
  };

  const formInitialValues = {
    userName: "",
  };
  const formValidationSchema = Yup.object({
    userName: Yup.string().required("Username is required"),
  });

  const handleFormSubmit = async (values) => {
    const userAccessToken = selector.userNormal.token;

    const para = {
      userAccessToken: "H7nTO^CJ0O%6",
      metadata: {
        userName: values.userName,
      },
    };
    //

    const data = {
      userName: values.userName,
    };

    // const param = '&userName=' + values.userName

    const headers = {
      "User-access-token": userAccessToken,
      "Content-Type": "application/json",
      Authorization: "Bearer H7nTO^CJ0O%6",
    };

    axios
      .patch(
        `https://metaverse-accounts.herokuapp.com/v1/user/metadata`,
        JSON.stringify(data),
        {
          headers: headers,
        }
      )

      .then(function (response) {
        if (response.status === 200) {
          dispatch(CreateUsernameModalAction(false));

          addToast("User Name Created  successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        }
      })
      .catch(function (error) {
        console.log("error  api", error);
        addToast(error.message, { appearance: "error", autoDismiss: true });
      })
      .finally(() => {});
  };

  const verifySubmit = () => {
    const userAccessToken = selector.userNormal.token;

    const data =
      "?User-Access-Token= eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIiwiaXNzIjoiaHR0cHM6Ly9kZXYtejVxMHd2Y2QudXMuYXV0aDAuY29tLyJ9..GsejG9mbug5p1YJ-.ev6FH9biRcj7u90rUOOrztK4WGhfe7oC240OVpjM8PAJgL4sIjDfZMfvEpeiCiE01FKCKnPEPal1wrMp17Yvy3WWvp9faYkiH8QNjcRRvNk2yGc0lciqsVZwjE2ERwaC4IsfQA1VnF09t9R4TRWSev_9XYQOq6BfeZJw5SbsCjZAotJCEOg_0KXydxvW4EitWzwRgHQJ8DMtaniOigoV-QFHPQ7tgItKr5-s_YWkIxeuxcY3CW8qwV9D23PF_LeOqYT6G6m4qfO1sdM6pk5wwE_NdYLkJ_HeAakiPbwrTojc9zjA6fb5k6dbX76OCbsyjDf2Cw4rtF9RFrM5B1rHnPcMhrYHuJE9YyeA1sucf_R5.5V14U4bF4dJM5VscuKRigQ";

    const headers = {
      "User-Access-Token": `eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIiwiaXNzIjoiaHR0cHM6Ly9kZXYtejVxMHd2Y2QudXMuYXV0aDAuY29tLyJ9..GsejG9mbug5p1YJ-.ev6FH9biRcj7u90rUOOrztK4WGhfe7oC240OVpjM8PAJgL4sIjDfZMfvEpeiCiE01FKCKnPEPal1wrMp17Yvy3WWvp9faYkiH8QNjcRRvNk2yGc0lciqsVZwjE2ERwaC4IsfQA1VnF09t9R4TRWSev_9XYQOq6BfeZJw5SbsCjZAotJCEOg_0KXydxvW4EitWzwRgHQJ8DMtaniOigoV-QFHPQ7tgItKr5-s_YWkIxeuxcY3CW8qwV9D23PF_LeOqYT6G6m4qfO1sdM6pk5wwE_NdYLkJ_HeAakiPbwrTojc9zjA6fb5k6dbX76OCbsyjDf2Cw4rtF9RFrM5B1rHnPcMhrYHuJE9YyeA1sucf_R5.5V14U4bF4dJM5VscuKRigQ`,
    };
    axios
      .get(
        `https://metaverse-accounts.herokuapp.com/v1/user/verify-access-token?User-Access-Token="eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIiwiaXNzIjoiaHR0cHM6Ly9kZXYtejVxMHd2Y2QudXMuYXV0aDAuY29tLyJ9..GsejG9mbug5p1YJ-.ev6FH9biRcj7u90rUOOrztK4WGhfe7oC240OVpjM8PAJgL4sIjDfZMfvEpeiCiE01FKCKnPEPal1wrMp17Yvy3WWvp9faYkiH8QNjcRRvNk2yGc0lciqsVZwjE2ERwaC4IsfQA1VnF09t9R4TRWSev_9XYQOq6BfeZJw5SbsCjZAotJCEOg_0KXydxvW4EitWzwRgHQJ8DMtaniOigoV-QFHPQ7tgItKr5-s_YWkIxeuxcY3CW8qwV9D23PF_LeOqYT6G6m4qfO1sdM6pk5wwE_NdYLkJ_HeAakiPbwrTojc9zjA6fb5k6dbX76OCbsyjDf2Cw4rtF9RFrM5B1rHnPcMhrYHuJE9YyeA1sucf_R5.5V14U4bF4dJM5VscuKRigQ"`
      )
      .then(function (response) {
        if (response.status === 200) {
        }
      })
      .catch(function (error) {
        console.log("error  api", error);
        addToast(error.message, { appearance: "error", autoDismiss: true });
      })
      .finally(() => {});
  };

  const handleCloseModal = () => {
    dispatch(CreateUsernameModalAction(false));
  };
  const loginModalRef = HandleClickOutside(() => {
    setShowLogin(false);
  });
  const [username, setUsername] = useState();
  // const handleChange = (e) => {
  //     setUsername(e.target.value)
  // }
  useEffect(() => {
    // verifySubmit()
  }, []);

  return (
    <Fragment>
      {/*<button className="btn clr-white" onClick={handleToggleLoginModal}>login</button>*/}
      <div className={`modal fade  ${showModal ? "show" : ""}`} role="dialog">
        <div className="modal-dialog centered username-modal-box no-transform-txt">
          <div
            ref={loginModalRef}
            className="modal-content welcome-content-box text-center"
          >
            <button className="btn ml-auto" onClick={handleCloseModal}>
              <CrossIcon />
            </button>
            <div className="modal-logo-block">
              <img src={logo} alt="logo" className="modal-logo-item" />
            </div>
            <h1 className="font42Txt clr-white no-transform-txt">
              Welcome to the club!
            </h1>
            <p className="font21Txt mt-10 mb-40">
              Please create a username for your account.
            </p>
            <Formik
              enableReinitialize="true"
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              <Form>
                <Field
                  className=" detail-input-form font24Txt chakraFont text-center clr-white p-25"
                  type="text"
                  name="userName"
                  placeholder="Enter Your Username"
                />
                <p className="text-danger text-left mt-1">
                  <ErrorMessage name="userName" />
                </p>
                <p className="font18Txt clr-gray mt-15">
                  Username can be no longer than 10characters
                </p>
                <button className="btn w-100">
                  <CreateUSernameBtn />
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateUsernameModal;
