import React, {Fragment, useEffect, useState} from 'react';
import "../../css/wallet.css"
import "../../css/detail.css"
import {useDispatch, useSelector} from "react-redux";
import * as Web3 from "web3";
import {NFT_CONTRACT_ABI, PRIMARY_SALE_ABI, SECONDARY_SALE_ABI} from "../../utils/ABI";
import {PRICE_TOKEN_ETH, PRIMARY_SALE_ADDRESS, SECONDARY_SALE_ADDRESS} from "../../utils/config";
import {useToasts} from "react-toast-notifications";
import BlockChainMsg from "../blockChsainTransactionMsg";
import {useParams} from "react-router-dom";
import {getContractAddressByURL} from "../../utils/helper";
import {SaleSuccessModalShowHide, SellNFTModalAction} from "../../redux/actions/modalActions";
import ItemForSaleModal from "./ItemForSaleModal";


const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(SECONDARY_SALE_ABI, SECONDARY_SALE_ADDRESS);


const SellNFTModal = (props) => {


    const params = useParams()
    const NFT_URL = params.nftContract

    // old code not getting contract
    // const NFT_CONTRACT = getContractAddressByURL(params.nftContract)

    const NFT_CONTRACT = (params.nftContract)
    console.log('NFT_URL',NFT_URL)
    const NFT_ID = params.nftId

    const {addToast} = useToasts()
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const userAddress = selector.user.address
    const showModal = selector.modals.sellNFTModal
    const [message, setMessage] = useState({"message": "", res: false})
    const [showSuccessForSell, setShowSuccessForSell] = useState(false)


    const [price, setPrice] = useState("0.00")

    const hideModal = () => {
        dispatch(SellNFTModalAction(false))
    }


    const handleListNFT = () => {
        if (!userAddress) {
            addToast("Please Login to continue", {appearance: "error", autoDismiss: true})
        } else {
            try {
                const _nftContract = NFT_CONTRACT
                const _nftId = NFT_ID
                const _price = (Math.pow(10, 18) * parseFloat(price)).toString()
                const _priceToken = PRICE_TOKEN_ETH
                if (!_nftContract) {
                    addToast("Contract not found", {appearance: "error", autoDismiss: true})
                    return
                }
                const CONTRACT = new web3.eth.Contract(NFT_CONTRACT_ABI, _nftContract);


                CONTRACT.methods
                    .approve(SECONDARY_SALE_ADDRESS, _nftId) // you can pass parameters
                    .send({
                        from: userAddress,
                    })
                    .on("transactionHash", (hash) => {
                        console.log("transactionHash approve ", hash);
                        setMessage({"message": hash, "res": true})

                    })
                    .on("receipt", (receipt) => {
                        console.log("receipt approve", receipt);
                    })
                    .on("confirmation", (confirmationNumber, receipt) => {
                        console.log("confirmationNumber approve", confirmationNumber);
                        console.log("receipt approve", receipt);
                    })
                    .on("error", (error) => {
                        console.log("error approve", error);
                        addToast("Failed" + error?.message, {appearance: "error", autoDismiss: true})
                    })
                    .then(() => {
                        SECONDARY_SALE_CONTRACT.methods
                            .addToMarketplace(_nftContract, _nftId, _price, _priceToken)
                            .send({
                                from: userAddress,
                            })
                            .on("transactionHash", (hash) => {
                                console.log("transactionHash  sendToken", hash);
                                setMessage({"message": hash, "res": true})

                            })
                            .on("receipt", (receipt) => {
                                console.log("receipt sendToken", receipt);
                            })
                            .on("confirmation", (confirmationNumber, receipt) => {
                                console.log("confirmationNumber sendToken", confirmationNumber);
                                console.log("receipt sendToken", receipt);
                                if (confirmationNumber === 0) {
                                    hideModal()
                                    dispatch(SaleSuccessModalShowHide(true))
                                }
                            })
                            .on("error", (error) => {
                                console.log("error sendToken", error);
                                addToast("Failed" + error?.message, {appearance: "error", autoDismiss: true})
                            });
                    });
            } catch (e) {
                addToast("Failed to list NFT " + e, {appearance: "error", autoDismiss: true})
            }
        }
    }


    return (
        <Fragment>
            <div className={`modal ${showModal ? "show" : ""}`} role="dialog">
                <div className="modal-dialog wallet-modal-box">
                    <div className="modal-content modal-content-box pos-rel">
                        <div className=" text-center">
                            <h5 className="font18Txt clr-Primary" id="exampleModalLabel">PLEASE SET THE SELLING
                                PRICE</h5>
                        </div>
                        <div className="wallet-bdr-top"/>
                        <div className="modal-input-box d-flex align-items-center mt-30">
                            <input className="detail-input-form font48Txt chakraFont text-center clr-gray p-0"
                                   type="number" placeholder="0.00" value={price} min={0}
                                   onChange={(e) => setPrice(e.target.value)}
                            />
                            <p className="font24Txt clr-white mb-0 d-ib ml-4">ETH</p>
                        </div>
                        <div className="wallet-bdr-btm"/>
                        <div className="modal-btn-group">
                            <button className="btn br-0 p-2 modalNoBtn orbFont" onClick={hideModal}>
                                <div className="pos-rel font16Txt clr-Primary">
                                    No
                                </div>
                            </button>
                            <button className="btn br-0 modalYesBtn p-2 orbFont"
                                    onClick={handleListNFT}>
                                <div className="pos-rel font16Txt clr-Primary">
                                    yes
                                </div>
                            </button>
                        </div>
                        <div className="modal-btn-group">
                            <BlockChainMsg message={message}/>
                        </div>
                    </div>
                </div>
            </div>

            <ItemForSaleModal/>

        </Fragment>
    )
}
export default SellNFTModal
