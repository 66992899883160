import React, {Fragment, useState} from 'react'
import "../../css/wallet.css"
import "../../css/detail.css"
import {useDispatch, useSelector} from "react-redux";
import {BuySuccessModalShowHide, EditPriceModal} from "../../redux/actions/modalActions";
import * as Web3 from "web3";
import {PRIMARY_SALE_ABI, SECONDARY_SALE_ABI} from "../../utils/ABI";
import {PRICE_TOKEN_ETH, PRIMARY_SALE_ADDRESS, SECONDARY_SALE_ADDRESS} from "../../utils/config";
import {useToasts} from "react-toast-notifications";
import BlockChainMsg from "../blockChsainTransactionMsg";
import BuySuccessModal from "../Detail/SuccessModal";


const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(SECONDARY_SALE_ABI, SECONDARY_SALE_ADDRESS);


const WalletEditModal = (props) => {

    const {addToast} = useToasts()
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const userAddress = selector.user.address
    const showModal = selector.modals.editPriceNFTModal
    const editNftObj = selector.modals.editPriceNftInfo
    const [message, setMessage] = useState({"message": "", res: false})

    const [price, setPrice] = useState("0.00")

    const hideModal = () => {
        dispatch(EditPriceModal(false))
    }

    const handleUpdatePriceNFT = () => {
        if (!userAddress) {
            addToast("Please Login to continue", {appearance: "error", autoDismiss: true})
        } else {
            try {

                const _nftContract = editNftObj.nftContract
                const _nftId = editNftObj.nftId
                const _newPrice = (Math.pow(10, 18) * parseFloat(price)).toString()
                const _newPriceToken = PRICE_TOKEN_ETH


                //consollog({_nftContract}, {_nftId}, {_newPrice}, {_newPriceToken}, {userAddress})

                SECONDARY_SALE_CONTRACT.methods
                    .updatePrice(_nftContract, _nftId, _newPrice, _newPriceToken)
                    .send({
                        from: userAddress,
                    })
                    .on("transactionHash", (hash) => {
                        //consollog("transactionHash  sendToken", hash);
                        setMessage({"message": hash, "res": true})
                    })
                    .on("receipt", (receipt) => {
                        //consollog("receipt sendToken", receipt);
                    })
                    .on("confirmation", (confirmationNumber, receipt) => {
                        //consollog("confirmationNumber sendToken", confirmationNumber);
                        //consollog("receipt sendToken", receipt);
                        if (confirmationNumber === 0) {
                            dispatch(BuySuccessModalShowHide(true))
                        }
                    })

                    .on("error", (error) => {
                        //consollog("error sendToken", error);
                        addToast("Failed" + error?.message, {appearance: "error", autoDismiss: true})
                    });

            } catch (e) {
                addToast("NFT price change failed " + e, {appearance: "error", autoDismiss: true})
            }
        }
    }


    return (
        <Fragment>
            <div className={`modal fade ${showModal ? "show" : ""}`} role="dialog">
                <div className="modal-dialog wallet-modal-box">
                    <div className="modal-content modal-content-box pos-rel">
                        <div className=" text-center">
                            <h5 className="font18Txt clr-Primary" id="exampleModalLabel">PLEASE SET THE SELLING
                                PRICE</h5>
                        </div>
                        <div className="wallet-bdr-top"/>
                        <div className="modal-input-box d-flex align-items-center mt-30">
                            <input className="detail-input-form font48Txt chakraFont text-center clr-gray p-0"
                                   type="number" placeholder="0.00" value={price} min={0}
                                   onChange={(e) => setPrice(e.target.value)}
                            />
                            <p className="font24Txt clr-white mb-0 d-ib ml-4">ETH</p>
                        </div>
                        <div className="wallet-bdr-btm"/>
                        <div className="modal-btn-group">
                            <button className="btn blueBtn br-0 font16Txt clr-Primary p-2 orbFont"
                                    onClick={hideModal}>
                                No
                            </button>
                            <button className="btn blueBtn br-0 lightBlueBtn font16Txt clr-Primary p-2 orbFont"
                                    onClick={handleUpdatePriceNFT}
                            >yes
                            </button>

                        </div>
                        <div className="modal-btn-group">
                            <BlockChainMsg message={message}/>
                        </div>
                    </div>
                </div>
            </div>


            {showModal &&
            <BuySuccessModal hash={message} messageText="Price Successfully updated"/>
            }

        </Fragment>
    )
}

export default WalletEditModal
