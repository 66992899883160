import React, {Fragment} from "react"
import "../../css/dashboard.css"
import "../../css/avatar.css"


const ListViewSkeltonCard = (props) => {

    const N = props?.loop;
    const arr = Array.from({length: N}, (_, index) => index + 1);

    const ListData = props?.data

    return (
        arr.length > 0 && arr.map((key, index) => {
            return (
                <Fragment key={index}>
                    <div className={`listed-block-item ${ListData === "dashboard" ? '' : 'marketListViewBlock'}`}>
                        <div
                            className={`${ListData === "dashboard" ? 'dashboardListViewImgBlock' : 'marketListViewImgBlock'}`}>
                            <div
                                className={`skeleton-animation ${ListData === "dashboard" ? 'dashboardThumb' : 'listThumbBlock'}`}/>
                        </div>
                        <div className="skeleton-text skeleton-animation w-50"/>
                        <div className="w-80">
                            <div className="skeleton-text skeleton-animation"/>
                            <div className="skeleton-text mt-2 skeleton-animation w-80"/>
                        </div>
                        <div className="skeleton-text skeleton-animation w-50"/>
                    </div>

                </Fragment>
            )
        })
    )
}
export default ListViewSkeltonCard
