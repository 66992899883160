import "./App.css"
import { BrowserRouter, Switch } from "react-router-dom"
import { useSelector } from "react-redux";
import PrivateRoutes from "./pages/PrivatesRoutes";
import PublicRoutes from "./pages/PublicRoutes";
import Header from "./component/Header/Header";

function App() {

    const selector = useSelector(state => state)
    //consollog("selector from app", selector)

    const userAddress = selector.user.address

    return (
        <BrowserRouter>
            <Header />
            <Switch>
                <PublicRoutes />
                {userAddress &&
                    <PrivateRoutes />
                }
            </Switch>

        </BrowserRouter>
    )
}

export default App
