import React, { Fragment, useEffect, useState } from "react"
import ListAndBuyFormNFT from "./NFTListingForm";
import { useDispatch } from "react-redux";
import SuccessModal from "./SuccessModal";
import { getRarityClass, getRarityText } from '../../utils/helper'


const AboutDetail = (props) => {

    const [data, setData] = useState({})
    const [attributes, setAttributes] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {

        //consollog("about page", props?.detailPageData)
        setData(props?.detailPageData)
        setAttributes(props?.detailPageData?.metadata?.attributes)
    }, [props])

    return (
        <>
            <div className="main-dark-card p-25 mb-20">
                <p className="font21Txt clr-red orbFont mb-10">About
                </p>
                <div className="divider-line mb-10" />
                <div className="detail-about-card">
                    {data.name &&
                        <div className="left-about">
                            <p className="mb-2 font12BoldTxt clr-gray text-uppercase">
                                avatar
                            </p>
                            <p className="font16Txt d-inline-block ml-2">
                                {data.name}
                            </p>
                        </div>

                    }

                    <div className="right-about d-flex">
                        {attributes && attributes.length > 0 && attributes.map((key, index) => {
                            return (
                                <Fragment key={index}>
                                    {key?.attribute === "rarity" &&
                                        <div className="">
                                            <p className="mb-2 font12BoldTxt clr-gray text-uppercase">
                                                {key?.attribute === "rarity" ? " RARITY SCORE" : null}
                                            </p>

                                            <div className={`br-0 text-center profileID ${getRarityClass(key?.value)}`}>
                                                <p className={`font12Txt mb-0 ${getRarityText(key?.value)}`}>{key?.value}</p>
                                            </div>
                                        </div>
                                    }

                                </Fragment>
                            )
                        }
                        )}

                        <div className="ml-3">
                            <p className="mb-2 font12BoldTxt clr-gray text-uppercase">
                                MINT COUNT
                            </p>
                            <p className="font16Txt">{props.nftId}/{data.maxSupply}</p>
                        </div>
                    </div>
                </div>
                {data.owner &&
                    <div className="mt-20">
                        <p className="mb-1 font12Txt clr-gray text-uppercase">Owner</p>
                        <p className="mb-2 font12Txt clr-gray text-uppercase mb-0">
                            {data.owner}
                        </p>

                    </div>
                }

                {/* for the Sell Screen, the user needs to input the price */}
                <ListAndBuyFormNFT data={data} />


            </div>
        </>


    )
}
export default AboutDetail
