import React, { Fragment, memo } from "react";
import avatarDetailImage from "../../images/avatarDetail.svg";
import gridThumb from "../../images/grid.svg";

const AvatarDetailImage = ({ image }) => {
  return (
    <Fragment>
      <div className="mintThumbBlock image-stack">
        <img
          src={gridThumb}
          alt="mintThumb"
          className="mintThumbImg stack-top"
        />
        <img
          src={image ? image : avatarDetailImage}
          alt="Avatar detail image"
          className="mintThumbImg stack-bottom w-85"
        />
      </div>
    </Fragment>
  );
};

export default memo(AvatarDetailImage);
