const initialState = {
    editPriceNFTModal: false,
    editPriceNftInfo: {},
    sellNFTModal: false,
    sellNFTInfo: {},
    buySuccessModal: false,
    saleSuccessModal: false,
    nftRemoveConfirmModal: false,
    nftRemoveInfo: {},
    EnterEmailModalState: false,
    enterOtpModalState:false,
    userNameCreatedModalState:false

}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "EDIT_PRICE_NFT":
            return {
                ...state,
                editPriceNFTModal: action.open,
                editPriceNftInfo: action.nftInfo
            }
        case "SELL_NFT_MODAL":
            return {
                ...state,
                sellNFTModal: action.open,
                sellNFTInfo: action.nftInfo
            }
        case "SUCCESS_MODEL_BUY":
            return {
                ...state,
                buySuccessModal: action.open,
            }

        case "SALE_SUCCESS_MODAL" : {
            return {
                ...state,
                saleSuccessModal: action.open,
            }
        }
        case "NFT_REMOVE_CONFIRM_MODAL" : {
            return {
                ...state,
                nftRemoveConfirmModal: action.open,
                nftRemoveInfo: action.nftObj
            }
        }
        case "ENTER_EMAIL_MODAL" : {
            return {
                ...state,
                EnterEmailModalState: action.open,
            }
        }
        case "ENTER_OTP_MODAL" : {
            return {
                ...state,
                enterOtpModalState: action.open,
            }
        }
        case "CREATE_USERNAME_MODAL" : {
            return {
                ...state,
                userNameCreatedModalState: action.open,
            }
        }

        default:
            return state
    }
}

export default modalReducer
