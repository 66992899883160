const initialState = {
    avatarRariryScore: [],
    typeAvatarRariryScore:'',

    avatarTraitsCount:'',

    avatarMinEthPrice : '',
    avatarMaxEthPrice : '',

    avatarTraitsUserNameColor: [],
    typeAvatarTraitsUserNameColor:'',

    avatarTraitsHead:[],
    typeAvatarTraitsHead:'',

    avatarTraitsHeadStyle:[],
    typeAvatarTraitsHeadStyle:'',

    avatarTraitsHeadColor:[],
    typeAvatarTraitsHeadColor:'',

    avatarTorso:[],
    typeAvatarTorso:'',

    avatarTorsoStyle:[],
    typeTorsoStyle:'',

    avatarTorsoColor:[],
    typeTorsoColor:'',

    avatarHand:[],
    typeAvatarHand:'',

    avatarHandStyle:[],
    typeAvatarHandStyle:'',

    avatarHandColor:[],
    typeAvatarHandColor: '',

    avatarWatch: [],
    typeAvatarWatch:'',

    avatarWatchColor: [],
    typeAvatarWatchColor:'',

    realEstateRarityScore:[],
    typeRealEstateRarityScore:'',

    realEstateMinEthPrice : '',
    realEstateMaxEthPrice : '',

    realEstateFloorNumber:[],
    typeRealEstateFloorNumber:'',

    realEstateApartmentNumber:[],
    typeRealEstateApartmentNumber:'',

    avatarBaseHp:[],
    typeAvatarBaseHp:'',

    avatarHeathReaganRate:[],
    typeHeathReaganRate:'',

    avatarBonusToDmg:[],
    typeBonusToDmg:'',

    avatarBonusToDefence:[],
    typeBonusToDefence:'',

    realEstateStatsFloorNumber:[],
    typeRealEstateStatsFloorNumber:'',

    realEstateStatsApartmentNumber:[],
    typeRealEstateStatsApartmentNumber:'',

    firstFilter:''
}

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "AvatarRarityScore":
            return {
                ...state,
                avatarRariryScore: action.payloads,
                typeAvatarRariryScore:'rarity'

            }
        case "AvatarTraitsCount":
            return {
                ...state,
                avatarTraitsCount: action.payloads,
                type:''

            }
        case "AvatarMinMaxEthPrice":
            return {
                ...state,
                avatarMinEthPrice: action.payloads.min,
                avatarMaxEthPrice: action.payloads.max,
                type:''

            }
        case "AvatarTraitsUserNameColor":
            return {
                ...state,
                avatarTraitsUserNameColor: action.payloads,
                typeAvatarTraitsUserNameColor:'userNameColor'

            }
        case "AvatarTraitsHead":
            return {
                ...state,
                avatarTraitsHead: action.payloads,
                typeAvatarTraitsHead:'head'

            }
        case "AvatarTraitsHeadStyle":
            return {
                ...state,
                avatarTraitsHeadStyle: action.payloads,
                typeAvatarTraitsHeadStyle:'headStyle'

            }
        case "AvatarTraitsHeadColor":
            return {
                ...state,
                avatarTraitsHeadColor: action.payloads,
                typeAvatarTraitsHeadColor:'headColor'

            }
        case "AvatarTorso":
            return {
                ...state,
                avatarTorso: action.payloads,
                typeAvatarTorso:'torso'

            }
        case "AvatarTorsoStyle":
            return {
                ...state,
                avatarTorsoStyle: action.payloads,
                typeTorsoStyle:'torsoStyle'

            }
        case "AvatarTorsoColor":
            return {
                ...state,
                avatarTorsoColor: action.payloads,
                typeTorsoColor:'torsoColor'

            }
        case "AvatarHand":
            return {
                ...state,
                avatarHand: action.payloads,
                typeAvatarHand:'hand'

            }

        case "AvatarHandStyle":
            return {
                ...state,
                avatarHandStyle: action.payloads,
                typeAvatarHandStyle:'handStyle'

            }
        case "AvatarHandColor":
            return {
                ...state,
                avatarHandColor: action.payloads,
                typeAvatarHandColor:'handColor'

            }
        case "AvatarWatch":
            return {
                ...state,
                avatarWatch: action.payloads,
                typeAvatarWatch:'watch'

            }
        case "AvatarWatchColor":
            return {
                ...state,
                avatarWatchColor: action.payloads,
                typeAvatarWatchColor:'watchColor'

            }
        case "RealEstateRarityScore":
            return {
                ...state,
                realEstateRarityScore: action.payloads,
                typeRealEstateRarityScore:'realEsateRarity'

            }
        case "RealEstateMinMaxEthPrice":
            return {
                ...state,
                realEstateMinEthPrice: action.payloads.min,
                realEstateMaxEthPrice: action.payloads.max,
                type:''
            }
        case "RealEstateFloorNumber":
            return {
                ...state,
                realEstateFloorNumber: action.payloads,
                typeRealEstateFloorNumber:'floor'

            }
        case "RealEstateApartmentNumber":
            return {
                ...state,
                realEstateApartmentNumber: action.payloads,
                typeRealEstateApartmentNumber:'apartmentNumber'


            }
        case "AvatarBaseHp":
            return {
                ...state,
                avatarBaseHp: action.payloads,
                typeAvatarBaseHp:'baseHp'
            }
        case "AvatarHeathReaganRate":
            return {
                ...state,
                avatarHeathReaganRate: action.payloads,
                typeHeathReaganRate:'heathReaganRate'

            }
        case "AvatarBonusToDmg":
            return {
                ...state,
                avatarBonusToDmg: action.payloads,
                typeBonusToDmg:'bonusToDmg'

            }
        case "AvatarBonusToDefence":
            return {
                ...state,
                avatarBonusToDefence: action.payloads,
                typeBonusToDefence:'bonusToDefence'

            }


        case "RealEstateStatsFloorNumber":
            return {
                ...state,
                realEstateStatsFloorNumber: action.payloads,
                typeRealEstateStatsFloorNumber:'statsFloor'

            }
        case "RealEstateStatsApartmentNumber":
            return {
                ...state,
                realEstateStatsApartmentNumber: action.payloads,
                typeRealEstateStatsApartmentNumber:'statsApartmentNumber'

            }
        case "FirstFilter":
            return {
                ...state,
                firstFilter: action.payloads,
                dataArticleTrue:''

            }
        case "ClearAllFilter":
            return {
                ...state,
                avatarRariryScore: [],
                typeAvatarRariryScore:'',

                avatarTraitsCount:0,

                avatarMinEthPrice : '',
                avatarMaxEthPrice : '',

                avatarTraitsUserNameColor: [],
                typeAvatarTraitsUserNameColor:'',

                avatarTraitsHead:[],
                typeAvatarTraitsHead:'',

                avatarTraitsHeadStyle:[],
                typeAvatarTraitsHeadStyle:'',

                avatarTraitsHeadColor:[],
                typeAvatarTraitsHeadColor:'',

                avatarTorso:[],
                typeAvatarTorso:'',

                avatarTorsoStyle:[],
                typeTorsoStyle:'',

                avatarTorsoColor:[],
                typeTorsoColor:'',

                avatarHand:[],
                typeAvatarHand:'',

                avatarHandStyle:[],
                typeAvatarHandStyle:'',

                avatarHandColor:[],
                typeAvatarHandColor: '',

                avatarWatch: [],
                typeAvatarWatch:'',

                avatarWatchColor: [],
                typeAvatarWatchColor:'',

                realEstateRarityScore:[],
                typeRealEstateRarityScore:'',

                realEstateMinEthPrice : '',
                realEstateMaxEthPrice : '',

                realEstateFloorNumber:[],
                typeRealEstateFloorNumber:'',

                realEstateApartmentNumber:[],
                typeRealEstateApartmentNumber:'',

                avatarBaseHp:[],
                typeAvatarBaseHp:'',

                avatarHeathReaganRate:[],
                typeHeathReaganRate:'',

                avatarBonusToDmg:[],
                typeBonusToDmg:'',

                avatarBonusToDefence:[],
                typeBonusToDefence:'',

                realEstateStatsFloorNumber:[],
                typeRealEstateStatsFloorNumber:'',

                realEstateStatsApartmentNumber:[],
                typeRealEstateStatsApartmentNumber:'',

                firstFilter:''

            }

        default:
            return state
    }
}

export default filterReducer
