import React, { Fragment, memo, useEffect, useState } from "react"
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import saleIcon from "../../images/sale.svg";
import ethereumIcon from "../../images/ethereum.svg";
import soldNFTIcon from "../../images/soldNFT.svg";
import axios from "axios";
import { API_BASE_URL, REAL_STATE_ADDRESS } from "../../utils/config";
import Typography from "@mui/material/Typography";
import Loader from "react-loader-spinner";
import { TotalSoldIcon, TotalUnsoldIcon, TotalVolumeIcon } from "../../utils/svgConst";

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const DashboardDataDayWise = () => {

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const getDashboardData = () => {

        setLoader(true)
        const nftContract = "?nftContract=" + REAL_STATE_ADDRESS

        axios.get(API_BASE_URL + "marketplace/secondary/dashboard-data" + nftContract)
            .then((res) => {
                if (res.status === 200) {
                    //consollog("day wise dashboard ", res.data)
                    setData(res.data)
                }
            }).catch((err) => {
                //consollog("err", err)
            }).finally(() => setLoader(false))
    }

    useEffect(() => {
        getDashboardData()
    }, [])

    return (
        <Fragment>
            <div className="row tabContainer main-dark-card">
                <div className="col-md-12 p-0">
                    <Box sx={{ width: "100%" }}>

                        {loader &&
                            <div className="col-md-12 mt-5 mb-5 text-center">
                                <Loader
                                    type="Oval"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        }

                        {!loader &&
                            <Fragment>
                                <Box sx={{ borderBottom: 1, borderColor: "#353535" }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        textColor="secondary"
                                        TabIndicatorProps={{ style: { background: "#00FFFF" } }}
                                        aria-label="secondary tabs example"
                                    >
                                        <Tab
                                            label="Last 24"
                                            className="tab--name font16Txt dash-tab-fix"
                                        />
                                        <Tab
                                            label="7 Days"
                                            className="tab--name font16Txt dash-tab-fix"
                                        />
                                        <Tab
                                            label="30 Days"
                                            className="tab--name font16Txt dash-tab-fix"
                                        />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <div className="tab--one--block">
                                        <div className="tab-block-1">
                                            <TotalSoldIcon className="mx-3" />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0 text-uppercase">Total Sold</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastTwentyFourHoursSold}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-block-2">
                                            <TotalUnsoldIcon className="mx-3" />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0">Total Unsold</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastTwentyFourHoursUnsold}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-block-3">
                                            <TotalVolumeIcon className="mx-3" />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0 text-uppercase">Total Volume</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastTwentyFourHoursVolume}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div className="tab--one--block">
                                        <div className="tab-block-1">
                                            <img src={saleIcon} alt="saleIcon" className="mx-3" />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0 text-uppercase">Total Sold</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastSevenDaysSold}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-block-2">
                                            <img
                                                src={ethereumIcon}
                                                alt="ethereumIcon"
                                                className="mx-3"
                                            />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0 text-uppercase">Total Unsold</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastSevenDaysUnsold}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-block-3">
                                            <img src={soldNFTIcon} alt="soldNFTIcon" className="mx-3" />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0 text-uppercase">Total Volume</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastSevenDaysVolume}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <div className="tab--one--block">
                                        <div className="tab-block-1">
                                            <img src={saleIcon} alt="saleIcon" className="mx-3" />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0 text-uppercase">Total Sold</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastThirtyDaysSold}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-block-2">
                                            <img
                                                src={ethereumIcon}
                                                alt="ethereumIcon"
                                                className="mx-3"
                                            />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0 text-uppercase">Total Unsold</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastThirtyDaysUnsold}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-block-3">
                                            <img src={soldNFTIcon} alt="soldNFTIcon" className="mx-3" />
                                            <div>
                                                <p className="font12Txt clr-Primary mb-0 text-uppercase">Total Volume</p>
                                                <p className="font28Txt clr-white mb-0 text-uppercase bigFontOverflowFix">
                                                    {data?.lastThirtyDaysVolume}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>

                            </Fragment>
                        }
                    </Box>
                </div>
            </div>



        </Fragment>
    )
}

export default memo(DashboardDataDayWise)
