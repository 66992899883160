import React, { Fragment, memo } from "react";
import gridThumb from "../../images/grid.svg";
import estateThumb from "../../images/estateThumb.svg";

const RealEstateDetailImage = ({ image }) => {
  return (
    <Fragment>
      <div className="mintThumbBlock image-stack">
        <img
          src={gridThumb}
          alt="mintThumb"
          className="mintThumbImg stack-top"
        />
        <img
          //   src={image ? image : estateThumb}
          src={estateThumb}
          alt="mintThumb"
          className="mintThumbImg stack-bottom w-85"
        />
      </div>
    </Fragment>
  );
};

export default memo(RealEstateDetailImage);
