import React, { Fragment, useEffect, useState } from "react"
import MarketListViewCard from "./MarketListViewCard";
import ListViewSkeltonCard from "./ListViewSkeletonCard";
import RealEstateListViewCard from "./RealEstateListViewCard";
import ItemsListViewCard from "./ItemsListViewCard";
import { useParams } from "react-router";

const MarketPlaceListViewItems = (props) => {

    const [viewType, setViewType] = useState(0)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const params = useParams()

    const NFT_URL = params.nftContract

    useEffect(() => {
        setViewType(props?.viewType)
        setLoading(props?.loading)
        setData(props?.data)
    }, [props])


    return (
        viewType === 2 &&
        <div className="">
            {loading &&
                <Fragment>
                    <ListViewSkeltonCard loop={10} data="market" />
                </Fragment>
            }
            {/*className="font36Txt clr-red orbFont"> {NFT_URL === "real-estate" ? 'Real estate' : NFT_URL === 'avatar' ? 'Avatar' : NFT_URL === 'items' ? " Item" : ""}</span>*/}

            {!loading && data.length > 0 &&
                <div className="listViewScroll">
                    {NFT_URL === 'real-estate' ?
                        <RealEstateListViewCard data={data} />
                        : NFT_URL === 'avatar' ?
                            <MarketListViewCard data={data} /> :
                            <ItemsListViewCard data={data} />

                    }


                    {/*<MarketListViewCard data={data} />*/}
                    {/*<RealEstateListViewCard data={data} />*/}
                    {/*<ItemsListViewCard data={data} />*/}
                </div>
            }
        </div>
    )
}

export default MarketPlaceListViewItems