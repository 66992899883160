import React, { Fragment, useState } from 'react'
import "../../../css/sidebar.css"
import arrow from "../../../images/dropdownArrow.svg"
import CustomLongCheckbox from '../../Common/CustomLongCheckbox'
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import { Formik, Form, Field ,ErrorMessage} from "formik"
import SidebarDropdown from '../../Dropdown/SidebarDropdown'
import {getRarityClass} from "../../../utils/helper";
import {useDispatch, useSelector} from "react-redux";
import {AvatarRarityScore,AvatarTraitsCount,AvatarMinMaxEthPrice} from "../../../redux/actions/actions";

import * as Yup from "yup"

const AvatarGeneral = (props) => {
    const dispatch = useDispatch()

    const selector = useSelector(state => state);

    const CustomSlider = styled(Slider)(({ theme }) => ({
        color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
        height: 2,
        padding: "15px 0",
        "& .MuiSlider-thumb": {
            height: 17,
            width: 17,
            backgroundColor: "#00ffff !important",
            "&:focus, &:hover, &.Mui-active": {
                boxShadow:
                    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            },
        },

        "& .MuiSlider-track": {
            border: "none",
            color: "#919191",
        },
        "& .MuiSlider-rail": {
            opacity: 0.5,
            backgroundColor: "#919191 !important",
        },
        "& .MuiSlider-mark": {
            backgroundColor: "#000",
            height: 10,
            width: 10,
            borderRadius: 10,
            border: '2px solid #919191 !important',
        },

        "& .MuiSlider-markLabel": {
            color: "#919191",
        },

        "& .MuiSlider-markLabelActive": {
            color: "#00FFFF",
        },
    }))



    const marks = [
        {
            value: 0,
            label: "Any1",
        },
        {
            value: 1,
            label: "1",
        },
        {
            value: 2,
            label: "2",
        },
        {
            value: 3,
            label: "3",
        },
        {
            value: 4,
            label: "4",
        },
        {
            value: 5,
            label: "5",
        },
        {
            value: 6,
            label: "6",
        },
        {
            value: 7,
            label: "7",
        },
    ]
    const [valueSliderTraits,setValueSliderTraits]= useState()

    const handleSelectTraits =(e,value)=>{
        console.log('e',value)
        setValueSliderTraits(value)
        dispatch(AvatarTraitsCount(value))


    }



    const [minEth,setMinEth]= useState('')
    const [maxEth,setMaxEth]= useState('')

    // const validationMinMaxEth = Yup.object({
    //     minETH: Yup.string().required("MinEth  is required"),
    //     maxETH: Yup.string().required("maxEth  is required"),
    //
    // })
    const handleSubmitPrice = (values) => {

        console.log("value submit", values.minETH)
        console.log("value2 submit", values.maxETH)
        setMinEth(values.minETH)
        setMaxEth(values.maxETH)
        let minMacObject = {
            min: values.minETH,
            max:values.maxETH
        }
        console.log({minMacObject})

        dispatch(AvatarMinMaxEthPrice(minMacObject))

    }


    const [color, setColor] = useState(false)
    const handleColorPick = () => {
        setColor(!color)
        setTrait(false)
        setPrice(false)
    }
    const [trait, setTrait] = useState(false)
    const handleTraitPick = () => {
        setTrait(!trait)
        setColor(false)
        setPrice(false)
    }
    const [price, setPrice] = useState(false)
    const handlePricePick = () => {
        setPrice(!price)
        setColor(false)
        setTrait(false)
    }
    const [selected, setSelected] = useState("")

const [rarityScore,setRarityScore] =useState([
    {
        value: 'Grail',
        name: 'Grail',
        color:"clr-cream"
    },

    {
        value: 'Uncommon',
        name: 'Uncommon',
        color:"clr-green"
    },
    {
        value: 'Legendry',
        name: 'Legendry',
        color:"clr-orange"
    },
    {
        value: 'Common',
        name: 'Common',
        color:"clr-white"
    },
    {
        value: 'Epic',
        name: 'Epic',
        color:"clr-purple"
    },{
        value: 'Floor',
        name: 'Floor',
        color:"clr-gray"
    },
    {
        value: 'Rare',
        name: 'Rare',
        color:"clr-blue"
    }
])

    const rarityArrayForApi = selector.filterData.avatarRariryScore

    // const [rarityArrayForApi,setRarityArrayForApi]= useState([])
    const handleChangeRarity = (param)=>{


        console.log({param})
        let newArray= rarityArrayForApi.some(item => param === item)

        if(newArray){

            let newArrayReduce= rarityArrayForApi.reduce((acc, curr) => {
                if (curr !== param) acc.push(curr);
                return acc;
            }, []);
            console.log({newArrayReduce})
            // setRarityArrayForApi(newArrayReduce)
            props.setRarityApiData(newArrayReduce)

            dispatch(AvatarRarityScore(newArrayReduce))


        }
        else  {
            let newArray = [...rarityArrayForApi]
            newArray.push(param)
            // setRarityArrayForApi(newArray)
            console.log({newArray})
            props.setRarityApiData(newArray)
            dispatch(AvatarRarityScore(newArray))


        }
    }
    // const minRangeValueLocal = 0;
    // const [maxRangeValueLocal, setMaxRangeValueLocal] = useState(7);

    // const [rangeValue, setRangeValue] = useState({
    //     min: minRangeValueLocal,
    //     max: maxRangeValueLocal,
    // });
    //
    // const changeRange=(value)=>{
    //     setRangeValue({min: value.min, max: value.max});
    //
    //
    //   // var ab=  marks.map((key)=>{
    //   //       return key.value
    //   //   })
    //     console.log(marks)
    //
    // }

    return (
        <Fragment>

            <SidebarDropdown selected={selected} setSelected={setSelected} type='avatar' />

            {selected &&
            <>
                <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown`} onClick={handleColorPick}>Rarity Score
                    <span className="font22Txt orbFont clr-red mb-0 fontFix ml-1">{rarityArrayForApi.length>0 ? `(${rarityArrayForApi.length})`: ''}</span>

                    <img src={arrow} alt="arrow" className={`float-right ml-auto ${color ? 'rotateImage' : 'rotateBack'}`} />
                </button>
                <div className={`${color ? 'long-checkDropdown-box' : 'd-none'} `}>
                    <div className="long-checkbox-grid">

                        {rarityScore.map((key)=>{
                            const isImgActive = rarityArrayForApi.indexOf(key.value) > -1

                            return (
                                <Fragment>
                                    <CustomLongCheckbox name={key.name} value={key.value} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} color={key.color} onClick={()=>{
                                        handleChangeRarity(key.value)
                                    }
                                    } />

                                </Fragment>
                            )
                        })

                        }


                    </div>
                </div>


                <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown`} onClick={handleTraitPick}>Trait Count
                    <img src={arrow} alt="arrow" className={`float-right ml-auto ${trait ? 'rotateImage' : 'rotateBack'}`} />
                </button>
                <div className={`${trait ? 'long-checkDropdown-box sidebar-bg' : 'd-none'} `}>
                    <div className="avatar-slider-block">
                        <Box>
                            <CustomSlider
                                aria-label="ios slider"
                                defaultValue={5}
                                min={0}
                                max={7}
                                value={valueSliderTraits}
                                marks={marks}
                                className="avatarSlider"
                                onChange ={(event, value)=>{
                                    handleSelectTraits(event, value)
                                }}

                            />
                        </Box>
                    </div>
                </div>


                <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown`} onClick={handlePricePick}>Price
                    <img src={arrow} alt="arrow" className={`float-right ml-auto ${price ? 'rotateImage' : 'rotateBack'}`} />
                </button>
                <div className={`${price ? 'long-checkDropdown-box' : 'd-none'} `}>
                    <div className="text-center sidebar-bg">
                        <Formik
                            initialValues={{ minETH: "", maxETH: "" }}
                            // validationSchema={validationMinMaxEth}

                            onSubmit={(values) => handleSubmitPrice(values)}
                        >
                            <Form>
                                <div className='sidebar-input-box'>

                                <Field
                                    name="minETH"
                                    type="number"
                                    placeholder="minETH"
                                    className="sidebar-input br-0"
                                />
                                <p className="mx-2 font14Txt mb-0">to</p>
                                <Field
                                    name="maxETH"
                                    type="number"
                                    placeholder="maxETH"
                                    className="sidebar-input br-0"

                                />
                                </div>
                                <p className="text-danger text-left mt-1">
                                    <ErrorMessage name="minETH" />
                                </p>
                                <p className="text-danger text-left mt-1">
                                    <ErrorMessage name="maxETH" />
                                </p>
                                <button className="btn mt-3 br-0 orbFont applyCustomBtn text-center">
                                    <div className="pos-rel">
                                        <p className="font16Txt orbFont mb-0 clr-Primary">Apply</p>
                                    </div>
                                </button>

                            </Form>
                        </Formik>


                    </div>
                </div>

            </>
            }


        </Fragment>
    )
}

export default AvatarGeneral
