const initialState = {
    token: '',
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "NORMAL_LOGIN_SUCCESS":
            return {
                ...state,
                token: action.payload,

            }
        case "LOGOUT":
            return {
                ...state,
                token: '',
            }

        default:
            return state
    }
}

export default userReducer
