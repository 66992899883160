import React, { Fragment, useState } from 'react'
import "../../../css/sidebar.css"
import heartIcon from "../../../images/blue-heart.svg"
import beatIcon from "../../../images/blue-beat.svg"
import punchIcon from "../../../images/blue-punch.svg"
import shieldIcon from "../../../images/blue-shield.svg"
import CustomSlider from "../../Common/CustomSlider"
import SidebarDropdown from '../../Dropdown/SidebarDropdown'
import {
    AvatarBaseHp,
    AvatarBonusToDefence,
    AvatarBonusToDmg,
    AvatarHeathReaganRate,
    AvatarTraitsCount
} from "../../../redux/actions/actions";
import {useDispatch} from "react-redux";


const AvatarStats = () => {
    const dispatch= useDispatch()

    const [selected, setSelected] = useState("")

    const marks = [
        {
            value: 0,
        },
        {
            value: 1,
        },
        {
            value: 2,
        },
        {
            value: 3,
        },
        {
            value: 4,
        },
        {
            value: 5,
        },
        {
            value: 6,
        },
    ]

    const marks2 = [
        {
            value: 0,
        },
        {
            value: 1,
        },
        {
            value: 2,
        },
        {
            value: 3,
        },
        {
            value: 4,
        },
        {
            value: 5,
        },
        {
            value: 6,
        },
    ]
    const [valueBaseHp,setValueBaseHp]= useState(5)
    const handleSelectBaseHp =(e,value)=>{
        console.log('handleSelectBaseHp',value)
        setValueBaseHp(value)
        let convertNumberValue= Number(value)
        let array = [convertNumberValue]
        dispatch(AvatarBaseHp(array))

    }
    const [valueReaganRate,setValueReaganRate]= useState(5)

    const handleSelectReaganRate =(e,value)=>{
        console.log('handleSelectReaganRate',value)
        setValueReaganRate(value)
        dispatch(AvatarHeathReaganRate([value]))
    }
    const resetBaseHp=()=>{
        dispatch(AvatarBaseHp([]))
        setValueBaseHp(5)
    }
    const resetReaganRate=()=>{
        dispatch(AvatarHeathReaganRate([]))
        setValueReaganRate(5)
    }

    const [valueBonusToDmg,setValueBonusToDmg]= useState(5)

    const handleSelectBonusToDmg  =(e,value)=>{
        console.log('handleSelectBonusToDmg',value)
        setValueBonusToDmg(value)
        dispatch(AvatarBonusToDmg([value]))
    }
    const resetBonusToDmg =()=>{
        dispatch(AvatarHeathReaganRate([]))
        setValueBonusToDmg(5)
    }

    const [valueBonusToDefence,setValueBonusToDefence]= useState(5)

    const handleSelectBonusToDefence =(e,value)=>{
        console.log('handleSelectBonusToDefence',value)
        setValueBonusToDefence(value)
        dispatch(AvatarBonusToDefence([value]))


    }

    const resetBonusToDefence =()=>{
        dispatch(AvatarBonusToDefence([]))
        setValueBonusToDefence(5)
    }

    return (
        <Fragment>

            <SidebarDropdown selected={selected} setSelected={setSelected} type='avatar' />

            {selected &&
                <>

                    <div className="sidebar-heading mt-30">
                        <div className="d-flex align-items-center">
                            <img src={heartIcon} alt="heart" />
                            <p className="font12Txt clr-gray text-uppercase mb-0 w-100 ml-2">Base HP</p>
                        </div>

                        <button className="btn font14Txt clr-Primary" onClick={resetBaseHp}>Reset</button>
                    </div>
                    <div className="slider-block mt-2">
                        <CustomSlider
                            aria-label="ios slider"
                            defaultValue={5}
                            min={0}
                            max={6}
                            marks={marks}
                            value={valueBaseHp}
                            onChange ={(event, value)=>{
                                handleSelectBaseHp(event, value)
                            }}
                        />
                    </div>
                    <div className="sidebar-heading mt-30">
                        <div className="d-flex align-items-center">
                            <img src={beatIcon} alt="heart" />
                            <p className="font12Txt clr-gray text-uppercase mb-0 w-100 ml-2">
                                HEALTH REGAN RATE
                            </p>
                        </div>

                        <button className="btn font14Txt clr-Primary" onClick={resetReaganRate}>Reset</button>
                    </div>
                    <div className="slider-block mt-2">
                        <CustomSlider
                            aria-label="ios slider"
                            defaultValue={5}
                            min={0}
                            max={6}
                            marks={marks2}
                            value={valueReaganRate}
                            onChange ={(event, value)=>{
                                handleSelectReaganRate(event, value)
                            }}
                        />
                    </div>
                    <div className="sidebar-heading mt-30">
                        <div className="d-flex align-items-center">
                            <img src={punchIcon} alt="heart" />
                            <p className="font12Txt clr-gray text-uppercase mb-0 w-100 ml-2">
                                BONUS TO DMG
                            </p>
                        </div>

                        <button className="btn font14Txt clr-Primary" onClick={resetBonusToDmg}>Reset</button>
                    </div>
                    <div className="slider-block mt-2">
                        <CustomSlider
                            aria-label="ios slider"
                            defaultValue={5}
                            min={0}
                            max={6}
                            marks={marks}
                            value={valueBonusToDmg}
                            onChange ={(event, value)=>{
                                handleSelectBonusToDmg(event, value)
                            }}
                        />
                    </div>
                    <div className="sidebar-heading mt-30">
                        <div className="d-flex align-items-center">
                            <img src={shieldIcon} alt="heart" />
                            <p className="font12Txt clr-gray text-uppercase mb-0 w-100 ml-2">
                                BONUS TO DEFENSE
                            </p>
                        </div>

                        <button className="btn font14Txt clr-Primary" onClick={resetBonusToDefence}>Reset</button>
                    </div>
                    <div className="slider-block mt-2">
                        <CustomSlider
                            aria-label="ios slider"
                            defaultValue={5}
                            min={0}
                            max={6}
                            marks={marks}
                            value={valueBonusToDefence}
                            onChange ={(event, value)=>{
                                handleSelectBonusToDefence(event, value)
                            }}
                        />
                    </div>
                </>
            }
        </Fragment>
    )
}

export default AvatarStats
