import React, { Fragment, useState } from 'react'
import { CrossIcon, GetCodeBtn, ResendCodeIcon, VarifyCodeBtn } from '../../utils/svgConst'
import HandleClickOutside from "../../utils/helper"
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { EnterEmailModalAction, EnterOTPModalAction, CreateUsernameModalAction } from "../../redux/actions/modalActions";
import { loginSuccess } from "../../redux/actions/actions"
import axios from "axios";
import { useToasts } from "react-toast-notifications";

import CreateUsernameModal from "./CreateUsernameModal";


const EnterOTPModal = (props) => {
    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const showModal = selector.modals.enterOtpModalState

    const [showLogin, setShowLogin] = useState(false)
    const handleToggleLoginModal = () => {
        setShowLogin(!showLogin)
    }
    const handleCloseModal = () => {
        dispatch(EnterOTPModalAction(false))

    }

    const loginModalRef = HandleClickOutside(() => {
        setShowLogin(false)
    })
    const [otp, setOtp] = useState()

    const handleChange = (otp) => setOtp(otp);
    const handleShowOTP = () => {
        console.log("otp value is", otp)
    }
    const handleFormSubmit = async () => {
        console.log(props.email)




        const param = "?email=" + props.email + '&' + 'code=' + otp
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer H7nTO^CJ0O%6'
        }
        axios
            .get(`https://metaverse-accounts.herokuapp.com/v1/nopwd/verify` + param,
                { headers }

            )
            .then(function (response) {
                if (response.status === 200) {
                    addToast("login sent successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    })
                    console.log('response.data.result.access_token', response.data.result.access_token)
                    window.localStorage.setItem("userRevog", JSON.stringify(response.data.result.access_token))
                    addToast("Login Success", {
                        appearance: "success",
                        autoDismiss: true,
                    })
                    dispatch(loginSuccess(response.data.result.access_token))
                    dispatch(EnterOTPModalAction(false))
                    dispatch(CreateUsernameModalAction(true))

                    setOtp('')


                }
            })
            .catch(function (error) {
                console.log("error  api", error)
                addToast(error.message, { appearance: "error", autoDismiss: true })
            })
            .finally(() => {
            })
    }



    return (
        <Fragment>
            {/*<button className="btn clr-white" onClick={handleToggleLoginModal}>login</button>*/}
            <div className={`modal fade vertical-scroll ${showModal ? "show" : ""}`} role="dialog">
                <div className="modal-dialog username-modal-box">
                    <div ref={loginModalRef} className="modal-content login-content-box pos-rel">
                        <button className="btn ml-auto" onClick={handleCloseModal}>
                            <CrossIcon />
                        </button>
                        <h1 className="font42Txt clr-white text-capitalize mt-40 text-center">Check Your Email</h1>
                        <h6 className="font21Txt no-transform-txt w-85 mt-20">Please enter the verification code we sent to
                            brian@revog.com in order to log in / sign up.
                            Wrong email? <a href='#' className='clr-primary'>Re-enter your email address.</a></h6>

                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            className="otp-input-form"
                            containerStyle="otp-grid"
                            isInputNum
                        />
                        <p className='mt-20 font18Txt clr-gray no-transform-txt text-left'>Code will expire shortly, so enter it soon. Remember to try your spam folder.</p>
                        <button className="btn mt-15 mb-20" onClick={handleFormSubmit}>
                            <VarifyCodeBtn />
                        </button>
                        <button className="btn resendCodeBtn clr-primary text-capitalize">
                            <ResendCodeIcon className="mr-15" />
                            Resend Code
                        </button>
                    </div>
                </div>
            </div>


            <CreateUsernameModal />

        </Fragment>
    )
}

export default EnterOTPModal