import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "../../css/dashboard.css";
import "../../css/marketCard.css";
import "../../css/pageNavigator.css"
import ListedAvatar from './ListedAvatar';
import ListedItems from './ListedItems';
import {API_BASE_URL, AVATAR_ADDRESS, ITEMS_ADDRESS, PRICE_TOKEN_ETH, REAL_STATE_ADDRESS} from "../../utils/config";
import axios from "axios";
import LeftArrow from "../../images/arrowLeft.svg";
import RightArrow from "../../images/arrowRight.svg";
import ListedRealEstate from "./ListedRealEstate";
import ListViewSkeletonCard from "../MarketPlace/ListViewSkeletonCard"


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const ListedSecondarySaleDashboard = () => {

    // value is used for active tabs
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [loading, setLoading] = useState(false)
    const [pageLimit] = useState(10)
    const [pageNo, setPageNo] = useState(1)
    const [maxResult, setMaxResult] = useState(0)
    const [data, setData] = useState([])


    const getContractAddress = () => {
        if (value === 0) {
            return AVATAR_ADDRESS
        } else if (value === 1) {
            return REAL_STATE_ADDRESS
        } else if (value === 2) {
            return ITEMS_ADDRESS
        }
    }
    const contractBasedOnTab = getContractAddress(value)
    //consollog("contractBasedOnTab",contractBasedOnTab)


    const maxPage = Math.ceil(maxResult / pageLimit)


    const getSecondaryListedSale = useCallback(() => {

        setLoading(true)

        // change contract address based on active tabs
        // const contractBasedOnTab = getContractAddress(value)

        //setting all required params
        const nftContract = "?nftContracts=" + PRICE_TOKEN_ETH + "&nftContracts=" + contractBasedOnTab;
        const limit = "&limit=" + pageLimit;
        const offset = "&skip=" + (pageNo === 1 ? 0 : ((pageNo - 1) * pageLimit))


        //adding all required params in one varaiable
        const params = nftContract + limit + offset

        //api call to get results
        axios.get(API_BASE_URL + "marketplace/secondary/listed" + params)
            .then((res) => {
                //consollog("%c result listed secondary sale", {color: "red"}, res.data)
                setMaxResult(res.data.totalListedSales)
                setData(res.data.listedSales)
            }).catch((err) => {
            setData([])
        }).finally(() => setLoading(false))
    }, [pageNo, value,contractBasedOnTab])


    const handlePageChange = (type) => {
        if (type === "INC") {
            //when max page is matched with current page no then dont call any api
            if (pageNo >= maxPage) return
            setPageNo(prevState => prevState + 1)
        } else if (type === "DEC") {
            if (pageNo === 1) return
            setPageNo(prevState => prevState - 1)
        } else {
            //consollog("type not matched")
        }
    }

    useEffect(() => {
        getSecondaryListedSale()
    }, [getSecondaryListedSale])


    return (
        <Fragment>

            <div>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: '#353535'}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="secondary"
                            TabIndicatorProps={{style: {background: '#00FFFF'}}}
                            aria-label="secondary tabs example"
                        >
                            <Tab label="Avatar" className="tab--name font16Txt dash-tab-fix"/>
                            <Tab label="Real Estate" className="tab--name font16Txt dash-tab-fix"/>
                            <Tab label="Items" className="tab--name font16Txt dash-tab-fix"/>
                        </Tabs>
                    </Box>
                    <div>
                        <TabPanel value={value} index={0}>
                            <div className="list-detail-items">
                                {loading &&
                                <ListViewSkeletonCard loop={pageLimit} data="dashboard"/>
                                }
                                {!loading &&
                                <ListedAvatar data={data}/>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className="list-detail-items">
                                {loading &&
                                <ListViewSkeletonCard loop={pageLimit} data="dashboard"/>
                                }
                                {!loading &&
                                <ListedRealEstate data={data}/>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className="list-detail-items">
                                {loading &&
                                <ListViewSkeletonCard loop={pageLimit} data="dashboard"/>
                                }
                                {!loading &&
                                <ListedItems data={data}/>
                                }
                            </div>
                        </TabPanel>
                    </div>
                </Box>
            </div>

            {!loading && data.length > 0 &&
            <div className="pageNavigator">
                <button className="btn" onClick={() => handlePageChange("DEC")}>
                    <img src={LeftArrow} alt="leftArrow"/>
                </button>
                <p className="page--txt">Page</p>
                <p className="page--count">{pageNo}</p>
                <p className="page--txt">of &nbsp; {maxPage}</p>
                <button className="btn" onClick={() => handlePageChange("INC")}>
                    <img src={RightArrow} alt="rightArrow"/>
                </button>
            </div>
            }

        </Fragment>
    )
}

export default ListedSecondarySaleDashboard;
