import React from 'react'
import "../../css/dashboard.css"
import "../../css/marketCard.css"
import thumb from "../../images/real-estate-small.svg"
import { DamageIcon, ElectricIcon, HeadIcon, NoticeIcon, WeaponIcon } from '../../utils/svgConst'
import { getRarityClass, getRarityText } from '../../utils/helper'


const ItemsListViewCard = (props) => {
    return (
        <div className="listed-market-block-item marketListViewBlock">
            <div className="marketListViewImgBlock">
                <img src={thumb} alt="cardImage"
                    className="list-thumb-icon" />
            </div>

            <div>
                <div className={`profileID ${getRarityClass("common")}`}>
                    <div className="pos-rel">
                        {/* <PersonIcon className={`${getRarityText("common")}`} /> */}
                        <p className={`font14Txt ml-2 mb-0 d-ib ${getRarityText("common")}`}>Common</p>
                    </div>
                </div>
                <p className="font21Txt mt-2 mb-0 text-center clr-white">
                    {/* {getContractBasedName((key.nftContract).toLowerCase())} */}
                    LIGHT SABER 1 LIGHT
                </p>

            </div>

            <div className="stats-block">
                <div className="stats-icons">
                    <p className="font12Txt clr-gray ">BASE DAMAGE</p>
                    <DamageIcon />
                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>
                </div>
                <div className="stats-icons">
                    <p className="font12Txt clr-gray ">WEAPON BONUS</p>
                    <WeaponIcon />
                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>
                </div>
                <div className="stats-icons">
                    <p className="font12Txt clr-gray ">HEAD DAMAGE BONUS</p>
                    <HeadIcon />
                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">6</p>
                </div>
                <div className="stats-icons">
                    <p className="font12Txt clr-gray ">CRIT DAMAGE</p>
                    <ElectricIcon />
                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">6</p>
                </div>
                <div className="stats-icons">
                    <p className="font12Txt clr-gray ">CRIT CHANCE</p>
                    <NoticeIcon />
                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">6</p>
                </div>

            </div>

            {props.type !== "collected" &&
                <div className="price-block">
                    <p className="font24BoldTxt mb-0">Ξ 2022</p>
                    <p className="font21Txt clr-gray mb-0">$ 25</p>
                </div>
            }
        </div>
    )
}

export default ItemsListViewCard