import React, { Fragment, useState } from 'react'
import "../../../css/sidebar.css"
import CustomLongCheckbox from '../../Common/CustomLongCheckbox'
import arrow from "../../../images/dropdownArrow.svg"
import {Formik, Form, Field, ErrorMessage} from "formik"
import SidebarDropdown from '../../Dropdown/SidebarDropdown'
import {
    AvatarMinMaxEthPrice,
    AvatarRarityScore,
    RealEstateMinMaxEthPrice,
    RealEstateRarityScore
} from "../../../redux/actions/actions";

import {useDispatch, useSelector} from "react-redux";


const RealEstateGeneral = () => {
    const selector = useSelector(state => state);

    const dispatch = useDispatch()

    const handleSubmit = (values) => {
        //consollog("value submit", values)
    }

    const [color, setColor] = useState(false)
    const handleColorPick = () => {
        setColor(!color)
        setPrice(false)
    }

    const [price, setPrice] = useState(false)
    const handlePricePick = () => {
        setPrice(!price)
        setColor(false)
    }


    const [selected, setSelected] = useState("")
    const rarityScoreReactEstate=[
        {
            value: 'Grail',
            name: 'Grail',
            color:"clr-cream"
        },

        {
            value: 'Uncommon',
            name: 'Uncommon',
            color:"clr-green"
        },
        {
            value: 'Legendry',
            name: 'Legendry',
            color:"clr-orange"
        },
        {
            value: 'Common',
            name: 'Common',
            color:"clr-white"
        },
        {
            value: 'Epic',
            name: 'Epic',
            color:"clr-purple"
        },{
            value: 'Floor',
            name: 'Floor',
            color:"clr-gray"
        },
        {
            value: 'Rare',
            name: 'Rare',
            color:"clr-blue"
        }
    ]
    // const [rarityArrayForApiRealEstate,setRarityArrayForApiRealEstate]= useState([])
    const rarityArrayForApiRealEstate = selector.filterData.realEstateRarityScore

    const handleChangeRarityRealEstate = (param)=>{


        //consollog({param})
        let newArray= rarityArrayForApiRealEstate.some(item => param === item)

        if(newArray){

            let newArrayReduce= rarityArrayForApiRealEstate.reduce((acc, curr) => {
                if (curr !== param) acc.push(curr);
                return acc;
            }, []);
            // //consollog({newArrayReduce})
            // setRarityArrayForApiRealEstate(newArrayReduce)
            dispatch(RealEstateRarityScore(newArrayReduce))


        }
        else  {
            let newArray = [...rarityArrayForApiRealEstate]
            newArray.push(param)
            // setRarityArrayForApiRealEstate(newArray)
            // //consollog({newArray})
            dispatch(RealEstateRarityScore(newArray))


        }
    }

    const [minEth,setMinEth]= useState('')
    const [maxEth,setMaxEth]= useState('')
    const handleSubmitPriceRealEstate = (values) => {
        setMinEth(values.minETH)
        setMaxEth(values.maxETH)
        //consollog("value submit", values.minETH)
        //consollog("value2 submit", values.maxETH)

        let minMacObject = {
            min: values.minETH,
            max:values.maxETH
        }

        dispatch(RealEstateMinMaxEthPrice(minMacObject))

    }
    return (
        <Fragment>

            <SidebarDropdown selected={selected} setSelected={setSelected} type="real-estate" />

            {selected &&
                <>

                    <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown`} onClick={handleColorPick}>Rarity Score
                        <span className="font22Txt orbFont clr-red mb-0 fontFix ml-1">{rarityArrayForApiRealEstate.length>0 ? `(${rarityArrayForApiRealEstate.length})`: ''}</span>

                        <img src={arrow} alt="arrow" className={`float-right ml-auto ${color ? 'rotateImage' : 'rotateBack'}`} />
                    </button>
                    <div className={`${color ? 'long-checkDropdown-box' : 'd-none'} `}>
                        <div className="long-checkbox-grid">

                            {rarityScoreReactEstate.map((key)=>{
                                const isImgActive = rarityArrayForApiRealEstate.indexOf(key.value) > -1

                                return (
                                    <Fragment>
                                        <CustomLongCheckbox name={key.name} value={key.value} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} color={key.color} onClick={()=>{
                                            handleChangeRarityRealEstate(key.value)
                                        }
                                        } />

                                    </Fragment>
                                )
                            })

                            }
                        </div>
                    </div>

                    <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown`} onClick={handlePricePick}>Price
                        <img src={arrow} alt="arrow" className={`float-right ml-auto ${price ? 'rotateImage' : 'rotateBack'}`} />
                    </button>
                    <div className={`${price ? 'long-checkDropdown-box' : 'd-none'} `}>
                        <div className="text-center sidebar-bg">
                            <Formik
                                initialValues={{ minETH: "", maxETH: "" }}
                                // validationSchema={validationMinMaxEth}

                                onSubmit={(values) => handleSubmitPriceRealEstate(values)}
                            >
                                <Form>
                                    <div className='sidebar-input-box'>

                                        <Field
                                            name="minETH"
                                            type="number"
                                            placeholder="minETH"
                                            className="sidebar-input br-0"
                                        />
                                        <p className="mx-2 font14Txt mb-0">to</p>
                                        <Field
                                            name="maxETH"
                                            type="number"
                                            placeholder="maxETH"
                                            className="sidebar-input br-0"

                                        />
                                    </div>
                                    <p className="text-danger text-left mt-1">
                                        <ErrorMessage name="minETH" />
                                    </p>
                                    <p className="text-danger text-left mt-1">
                                        <ErrorMessage name="maxETH" />
                                    </p>
                                    <button className="btn mt-3 br-0 orbFont applyCustomBtn text-center">
                                        <div className="pos-rel">
                                            <p className="font16Txt orbFont mb-0 clr-Primary">Apply</p>
                                        </div>
                                    </button>

                                </Form>
                            </Formik>


                        </div>
                    </div>
                </>
            }
        </Fragment>
    )
}
export default RealEstateGeneral