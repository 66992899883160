import { TEMPLATE_ARR } from "../constants/envConstant";
import { AVATAR_ADDRESS, AVATAR_URL, ITEMS_ADDRESS, ITEMS_URL, REAL_STATE_ADDRESS, REAL_STATE_URL } from "./config";
import { useRef, useEffect } from "react"

export const getContractAddressByURL = (str) => {
    // return REAL_STATE_ADDRESS
    if (!str) return ""
    if (str === REAL_STATE_URL) {
        return REAL_STATE_ADDRESS
    } else if (str === ITEMS_URL) {
        return ITEMS_ADDRESS
    } else if (str === AVATAR_URL) {
        return AVATAR_ADDRESS
    }
}


export const getUSDPrice = (itemPrice, ethPrice) => {
    return itemPrice && ethPrice ? (ethPrice * itemPrice).toFixed(2) : "--"
}


export const getContractURL = (contractAddress) => {
    let url = ""
    if (contractAddress === ("0xb262b49e2eE42A105933CBa5Df3da4a0eF897405").toLowerCase() || contractAddress === ("")) {
        url = REAL_STATE_URL
    }
    else if (contractAddress === ("0xc0ce2e866f61c4bc777d2dd7ce7c9e81078dc207").toLowerCase() || contractAddress === ("")) {
        url = AVATAR_URL
    }
    else if (contractAddress === "") {
        url = ITEMS_URL
    }
    return url
}

export const getContractBasedName = (contractAddress) => {
    let name = ""
    //real state
    if (contractAddress === ("0xb262b49e2eE42A105933CBa5Df3da4a0eF897405").toLowerCase()) {
        name = "3 BEDROOM APARTMENT"
    }
    //avatar
    else if (contractAddress === ("0xc0ce2e866f61c4bc777d2dd7ce7c9e81078dc207").toLowerCase()) {
        name = "CHARTER WIREFRAME S1A"
    }
    //items
    else if (contractAddress === "") {
        name = "Items"
    }
    return name
}


export const SORTING_DATA = [
    {
        name: "Lowest Price",
        value: '21',
    },
    {
        name: "Highest Price",
        value: '22',
    },
    {
        name: "Newest First",
        value: '11',
    },
    {
        name: "Oldest First",
        value: '12',
    }
]

// If the rarity value in the API data is not defined, the rarity of the nft is set to common (hardcoded)


export const getRarityClass = (rarity) => {
    if (rarity.toLowerCase() === 'rare') return 'dark-blue-bg blue-outline'
    if (rarity.toLowerCase() === 'epic') return 'dark-purple-bg purple-outline'
    if (rarity.toLowerCase() === 'legendry') return 'dark-orange-bg orange-outline'
    if (rarity.toLowerCase() === 'uncommon') return 'dark-green-bg green-outline'
    if (rarity.toLowerCase() === 'godly') return 'dark-cream-bg cream-outline'
    if (rarity.toLowerCase() === 'common') return 'dark-white-bg white-outline'
    if (rarity.toLowerCase() === 'floor') return 'dark-gray-bg gray-outline'
    if (rarity.toLowerCase() === 'grail') return 'dark-cream-bg cream-outline'
    if (rarity.toLowerCase() === '') return 'dark-white-bg white-outline'

}

export const getRarityText = (rarity) => {
    if (rarity.toLowerCase() === 'rare') return 'clr-blue'
    if (rarity.toLowerCase() === 'epic') return 'clr-purple'
    if (rarity.toLowerCase() === 'legendry') return 'clr-orange'
    if (rarity.toLowerCase() === 'uncommon') return 'clr-green'
    if (rarity.toLowerCase() === 'godly') return 'clr-cream'
    if (rarity.toLowerCase() === 'common') return 'clr-white'
    if (rarity.toLowerCase() === 'floor') return 'clr-gray'
    if (rarity.toLowerCase() === 'grail') return 'clr-cream'
    if (rarity.toLowerCase() === '') return 'clr-white'

}

export const getRarityBG = (rarity) => {
    if (rarity.toLowerCase() === 'epic') return 'profile-card-purple clr-purple'
    if (rarity.toLowerCase() === 'legendry') return 'profile-card-orange clr-orange'
    if (rarity.toLowerCase() === 'uncommon') return 'profile-card-green clr-green'
    if (rarity.toLowerCase() === 'godly') return 'profile-card-cream clr-cream'
    if (rarity.toLowerCase() === 'common') return 'profile-card-white clr-white'
    if (rarity.toLowerCase() === 'floor') return 'profile-card-gray clr-gray'
    if (rarity.toLowerCase() === 'grail') return 'profile-card-cream clr-cream'
    if (rarity.toLowerCase() === 'rare') return 'profile-card-blue clr-blue'
    if (rarity.toLowerCase() === '') return 'profile-card-white clr-white'

}



const HandleClickOutside = (handler) => {

    const ref = useRef()
    useEffect(() => {
        const manageClickOutside = (event) => {
            if (!ref.current.contains(event.target)) {
                handler()
            }
        }


        document.addEventListener("mousedown", manageClickOutside)
        return (() => {
            document.removeEventListener("mousedown", manageClickOutside)
        })
    }, [handler]);
    return ref
}
export default HandleClickOutside