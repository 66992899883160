import React, { Fragment } from 'react'
import { getRarityClass } from '../../utils/helper'
import {TickIcon} from "../../utils/svgConst";

const CustomLongCheckbox = (props) => {

  const dataSet=   props.onClick

    return (
        <Fragment>
            <div className="rarity-block fix">
                <input
                    className="checkbox-effect custom-long-checkbox custom-checkbox hideBeforeAfter"
                    id={props.name}
                    type="checkbox"
                    value={props.value}
                    name={props.value}
                    onClick={()=>{
                        dataSet(props.value)
                    }}
                />
                <label for={props.name} className={`font14Txt d-flex align-items-center mb-0 ${props.color}`}>

                    <div className={`profileID rarityWidth ${getRarityClass(props.value)}`}>
                        <p className='mb-0 font12Txt'>{props.name}</p>

                    </div>
                </label>
                <span className={`ml-auto mrSet ${props.stateActiveCheckBoxTick}`}><TickIcon /></span>


            </div>
        </Fragment>
    )
}

export default CustomLongCheckbox
