export const API_BASE_URL = "https://testrevog.owens.market/"
export const PRIMARY_SALE_ADDRESS = "0x67fD9478BbAA229DD044E9a9e230D4F37074194E"
export const SECONDARY_SALE_ADDRESS = "0x4b2eB8d12D3e49dd3370c7582294254a6039475B"
export const REAL_STATE_ADDRESS = "0x872Dd062FA1Fb1AfC03f46592048E6de192464Dd"
export const PRICE_TOKEN_ETH = "0x0000000000000000000000000000000000000000"

export const DEFAULT_WEB3_ADDRESS = "https://polygon-mumbai-bor.publicnode.com/"
export const ITEMS_ADDRESS = '0x6d970A04165306d92bC269e6D85a6Fe188DF84b9'
export const AVATAR_ADDRESS = '0xd44FAe8d6a4cc9f05A8C72d186f23c32EBF0b857'


export const REAL_STATE_URL = "real-estate"
export const ITEMS_URL = "items"
export const AVATAR_URL = "avatar"
export const DASHBOARD_URL = "dashboard"
// 0xc0ce2e866f61c4bc777d2dd7ce7c9e81078dc207