import React, { Fragment } from 'react';
import "../../css/dashboard.css"
import "../../css/marketCard.css"
import thumb from "../../images/cardImage.svg"
import PersonIcon from '@mui/icons-material/Person';
import { getRarityClass, getRarityText } from '../../utils/helper'
import { HeartIcon, HeartbeatIcon, PunchIcon, ShieldIcon } from '../../utils/svgConst';

const WalletListViewCard = (props) => {
    return (
        <Fragment>
            <div className="wallet-listed-block-item marketListViewBlock">
                <div className="marketListViewImgBlock">
                    <img src={thumb} alt="cardImage"
                        className="list-thumb-icon" />
                    {/* <img src={thumb} alt="thumb" className="" /> */}
                </div>

                <p className="font18Txt mt-2 mb-0 text-center clr-white">
                    charter
                </p>
                <div className='d-flex justify-content-between'>
                    <div className={`profileID ${getRarityClass('rare')}`}>
                        <div className="pos-rel">
                            <PersonIcon className={`${getRarityText('rare')}`} />
                            <p className={`font12BoldTxt ml-2 mb-0 d-ib ${getRarityText('rare')}`}>#25</p>
                        </div>
                    </div>

                </div>
                <div className="stats-block">
                    <div className="stats-icons">
                        <p className="font12Txt clr-gray ">Base HP</p>
                        <HeartIcon />
                        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>
                    </div>
                    <div className="stats-icons">
                        <p className="font12Txt clr-gray ">HEALTH REGEN</p>
                        <HeartbeatIcon />
                        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>
                    </div>
                    <div className="stats-icons">
                        <p className="font12Txt clr-gray ">ATTACK POWER</p>
                        <PunchIcon />
                        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>
                    </div>
                    <div className="stats-icons">
                        <p className="font12Txt clr-gray ">DEFENSE</p>
                        <ShieldIcon />
                        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>
                    </div>
                </div>

                {/* {key?.seller &&
                            <div>
                                <p className="font12Txt mb-0 clr-Primary">Seller</p>
                                <CustomTooltip title={key?.seller}>
                                    <p className="font14Txt mb-0 marketFontOverflowFix">
                                        {key?.seller}
                                    </p>
                                </CustomTooltip>
                            </div>
                        } */}
                {props.type !== "collected" &&
                    <div className="img--block text-center">
                        <p className="font21Txt mb-0">Ξ 25</p>
                        <p className="font18Txt clr-gray mb-0">$ 25</p>
                    </div>}
            </div>
        </Fragment>
    )
};

export default WalletListViewCard;
