import React, { Fragment, useEffect, useState } from "react";
import "../../css/dashboard.css";
import "../../css/marketCard.css";
import thumb from "../../images/real-estate-small.svg";

import {
  HeartIcon,
  HeartbeatIcon,
  PunchIcon,
  ShieldIcon,
  BuildingIcon,
  A2ZIcon,
  FrameIcon,
} from "../../utils/svgConst";
import { getRarityClass, getRarityText } from "../../utils/helper";
import { getUSDPrice } from "../../utils/helper";
import { useSelector } from "react-redux";
import heartIcon from "../../images/heart.svg";
import heartbeatIcon from "../../images/heartbeat.svg";
import punchIcon from "../../images/punch.svg";
import shieldIcon from "../../images/shield.svg";

const RealEstateListViewCard = (props) => {
  const [data, setData] = useState([]);

  const getIcon = (value) => {
    if (value === "floorLocation") {
      return <BuildingIcon />;
    } else if (value === "apartmentLetter") {
      return <A2ZIcon />;
    } else if (value === "pictureFrames") {
      return <FrameIcon />;
    }
  };
  const selector = useSelector((state) => state);
  useEffect(() => {
    setData(props?.data);
  }, [props]);

  return (
    data &&
    data.length > 0 &&
    data.map((key, index) => {
      let filterDataForTypeThree = props?.data[index].attributes.filter(
        (key) => {
          return key.type === 3;
        }
      );
      return (
        <Fragment key={key?.saleId}>
          <div className="listed-market-block-item marketListViewBlock">
            <div className="marketListViewImgBlock">
              <img
                src={key?.image ? key.image : thumb}
                alt="cardImage"
                className="list-thumb-icon"
              />
              {/* <img src={thumb} alt="thumb" className="" /> */}
            </div>

            <div>
              <div className={`profileID ${getRarityClass(key.rarity)}`}>
                <div className="pos-rel">
                  {/* <PersonIcon className={`${getRarityText("common")}`} /> */}
                  <p
                    className={`font14Txt ml-2 mb-0 d-ib ${getRarityText(
                      key.rarity
                    )}`}
                  >
                    {key.rarity}
                  </p>
                </div>
              </div>
              <p className="font21Txt mt-2 mb-0 text-center clr-white">
                {/* {getContractBasedName((key.nftContract).toLowerCase())} */}3
                BEDROOM APARTMENT
              </p>
            </div>

            <div className="stats-block">
              {filterDataForTypeThree &&
                filterDataForTypeThree.length > 0 &&
                filterDataForTypeThree.map((key, index) => {
                  return (
                    <Fragment key={index}>
                      {/*{key?.attribute === "baseHP" && key?.type === 3 &&*/}
                      <div className="stats-icons">
                        <p className="font12Txt clr-gray ">{key?.attribute}</p>
                        <img src={getIcon(key?.attribute)} alt="heart" />
                        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">
                          {key?.value}
                        </p>
                      </div>
                      {/*}*/}
                    </Fragment>
                  );
                })}
              {/*<div className="stats-icons">*/}
              {/*    <p className="font12Txt clr-gray ">Floor Location</p>*/}
              {/*    <BuildingIcon />*/}
              {/*    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>*/}
              {/*</div>*/}
              {/*<div className="stats-icons">*/}
              {/*    <p className="font12Txt clr-gray ">Apartment Letter</p>*/}
              {/*    <A2ZIcon />*/}
              {/*    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">D</p>*/}
              {/*</div>*/}
              {/*<div className="stats-icons">*/}
              {/*    <p className="font12Txt clr-gray ">Picture Frames</p>*/}
              {/*    <FrameIcon />*/}
              {/*    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">6</p>*/}
              {/*</div>*/}
            </div>

            {props.type !== "collected" && (
              <div className="price-block mr-3">
                <p className="font24BoldTxt mb-0">Ξ {key?.price}</p>
                <p className="font21Txt clr-gray mb-0">
                  $ {getUSDPrice(key?.price, selector.ethPrice.price)}
                </p>
              </div>
            )}
          </div>
        </Fragment>
      );
    })
  );
};

export default RealEstateListViewCard;
