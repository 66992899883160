import React, { Fragment } from 'react'
import noActivity from "../../images/noActivity.svg"

const NoActivity = () => {
    return (
        <fragment>
            <div className="wallet-no-case-wrapper">
                <div className="wallet-empty-block">
                    <img src={noActivity} alt="noActivity" className="noCaseImageFix" />
                </div>
            </div>
        </fragment>
    )
}

export default NoActivity
