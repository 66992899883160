import React, { Fragment } from 'react'
import {TickIcon} from "../../utils/svgConst";

const ColorCheckBoxWatch = (props) => {
    const show=()=>{
        console.log("nothing")

    }
    const dataSet=   props.onClick ?props.onClick: show


    return (
        <Fragment>
            <div className={`checkbox-style-block ${props.type === 'withBG' ? '' : 'bg-transparent'}`}>
                <input
                    className="checkbox-effect custom-long-checkbox custom-checkbox hideBeforeAfter"
                    id={props.name}
                    type="checkbox"
                    value={props.value ?props.value.replace('watch-',''): '' }
                    name={props.name ?props.name.replace('watch-',''): '' }

                    onClick={()=>{
                        dataSet(props.value ?props.value.replace('watch-',''): '')
                    }}
                />
                <label for={props.name} className={`font14Txt d-flex align-items-center mb-0`}>
                    <div className="icon-checkbox-block">
                        {props.image &&
                        <img src={props.image} alt="icon-img-color" />
                        }
                        {props.color &&
                        <div className={`circle-input ${props.color}`}/>
                        }
                        <p className='mb-0 font14Txt clr-white'>{props.name ?props.name.replace('watch-',''): '' } </p>
                        <span className={`ml-auto mrSet ${props.stateActiveCheckBoxTick}`}><TickIcon /></span>

                    </div>
                </label>
            </div>
        </Fragment>
    )
}

export default ColorCheckBoxWatch
