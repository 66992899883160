import React, { Fragment, useState } from 'react'
import arrow from "../../../images/dropdownArrow.svg"
import rectHeadDesel from "../../../images/rectangleHeadDeselect.svg"
import rectHeadSel from "../../../images/rectangleHeadSelected.svg"
import heartHeadDesel from "../../../images/heartHeadDeselect.svg"
import heartHeadSel from "../../../images/heartHeadSelect.svg"
import blockHeadDesel from "../../../images/blockHeadDeselect.svg"
import blockHeadSel from "../../../images/blockHeadSelect.svg"
import oblongHeadDesel from "../../../images/oblongHeadDeselect.svg"
import oblongHeadSel from "../../../images/oblongHeadSelect.svg"
import roundHeadDesel from "../../../images/roundHeadDeselect.svg"
import roundHeadSel from "../../../images/roundHeadSelect.svg"
import pearHeadSel from "../../../images/pearHeadSelect.svg"
import pearHeadDesel from "../../../images/pearHeadDeselect.svg"
import diamondHeadSel from "../../../images/diamondHeadSelect.svg"
import diamondHeadDesel from "../../../images/diamondHeadDeselect.svg"
import triangleHeadSel from "../../../images/triangleHeadSelect.svg"
import triangleHeadDesel from "../../../images/triangleHeadDeselect.svg"

import bodyIconDesel from "../../../images/bodyIconDeselect.svg"
import bodyIconSel from "../../../images/bodyIconSelect.svg"

import psychicHandDesel from "../../../images/psychicHandDeselect.svg"
import psychicHandSel from "../../../images/psychicHandSelect.svg"
import comicHandDesel from "../../../images/comicHandDeselect.svg"
import comicHandSel from "../../../images/comicHandSelect.svg"
import intellectHandDesel from "../../../images/intellectHandDeselect.svg"
import intellectHandSel from "../../../images/intellectHandSelect.svg"

import watchOneDesel from "../../../images/watchOneDeselect.svg"
import watchOneSel from "../../../images/watchOneSelect.svg"
import watchTwoDesel from "../../../images/watchTwoDeselect.svg"
import watchTwoSel from "../../../images/watchTwoSelect.svg"
import watchThreeDesel from "../../../images/watchThreeDeselect.svg"
import watchThreeSel from "../../../images/watchThreeSelect.svg"
import watchFourDesel from "../../../images/watchFourDeselect.svg"
import watchFourSel from "../../../images/watchFourSelect.svg"
import watchFiveDesel from "../../../images/watchFiveDeselect.svg"
import watchFiveSel from "../../../images/watchFiveSelect.svg"
import watchSixDesel from "../../../images/watchSixDeselect.svg"
import watchSixSel from "../../../images/watchSixSelect.svg"
import wristbandOneDesel from "../../../images/wristbandOneDeselect.svg"
import wristbandOneSel from "../../../images/wristbandOneSelect.svg"
import wristbandTwoDesel from "../../../images/wristbandTwoDeselect.svg"
import wristbandTwoSel from "../../../images/wristbandTwoSelect.svg"

import droidSelect from "../../../images/droidSelect.svg"
import droidDeselect from "../../../images/droidDeselect.svg"
import plexusSelect from "../../../images/plexusSelect.svg"
import plexusDeselect from "../../../images/plexusDeselect.svg"
import sketchSelect from "../../../images/sketchSelect.svg"
import sketchDeselect from "../../../images/sketchDeselect.svg"
import topoSelect from "../../../images/topoSelect.svg"
import topoDeselect from "../../../images/topoDeselect.svg"
import tracerSelect from "../../../images/tracerSelect.svg"
import tracerDeselect from "../../../images/tracerDeselect.svg"
import triadSelect from "../../../images/triadSelect.svg"
import triadDeselect from "../../../images/triadDeselect.svg"

import poison from "../../../images/poison.svg"
import cage from "../../../images/cage.svg"
import grunt from "../../../images/grunt.svg"
import strife from "../../../images/strife.svg"
import dohma from "../../../images/dohma.svg"

import clearBtnActive from "../../../images/clearActiveBtn.png"


import IconCheckbox from '../../Common/IconCheckbox'
import SidebarDropdown from '../../Dropdown/SidebarDropdown'
import { SidebarClearActiveBtn, SidebarClearInactiveBtn, SidebarClearBtn } from '../../../utils/svgConst'
import {
    AvatarHand, AvatarHandColor, AvatarHandStyle,
    AvatarRarityScore,
    AvatarTorso, AvatarTorsoColor, AvatarTorsoStyle,
    AvatarTraitsHeadColor,
    AvatarTraitsHeadStyle, AvatarWatch, AvatarWatchColor
} from "../../../redux/actions/actions";
import CustomLongCheckbox from "../../Common/CustomLongCheckbox";
import TorsoStyleCheckBox from "../../checkBoxSideBar/torsoStyleCheckBox";
import StyleHeadCheckBox from "../../checkBoxSideBar/styleHeadCheckBox";
import handStyleCheckBox from "../../checkBoxSideBar/handStyleCheckBox";
import ColorCheckBox from "../../checkBoxSideBar/colorHeadCheckBox";
import ColorCheckBoxTorso from "../../checkBoxSideBar/colorTorsoCheckBox";
import ColorCheckBoxHand from "../../checkBoxSideBar/colorHandCheckBox";
import HandStyleCheckBox from "../../checkBoxSideBar/handStyleCheckBox";
import ColorCheckBoxWatch from "../../checkBoxSideBar/colorWatchCheckBox";

import { useDispatch, useSelector } from "react-redux";
import { AvatarTraitsUserNameColor, AvatarTraitsHead } from "../../../redux/actions/actions";


import { headStyleMap } from '../../checkBoxSideBar/data'
import { userNameAndColor } from '../../checkBoxSideBar/data'
import { torsoStyleMap } from '../../checkBoxSideBar/data'
import { handStyleMap } from '../../checkBoxSideBar/data'
import { colorArrayWatch } from '../../checkBoxSideBar/data'
import { colorHeadArray } from '../../checkBoxSideBar/data'
import { colorTorsoArray } from '../../checkBoxSideBar/data'
import { colorHandArray } from '../../checkBoxSideBar/data'
import UserNameColor from "../../checkBoxSideBar/userNameColor";



const AvatarTraits = () => {
    const dispatch = useDispatch()
    const selector = useSelector(state => state);


    const [nameColor, setNameColor] = useState(false)
    const handleNameColorPick = () => {
        setNameColor(!nameColor)
        setHead(false)
        setTorso(false)
        setHand(false)
        setWatch(false)
    }
    const [head, setHead] = useState(false)
    const handleHeadPick = () => {
        setHead(!head)
        setNameColor(false)
        setTorso(false)
        setHand(false)
        setWatch(false)
    }
    const [torso, setTorso] = useState(false)
    const handleTorsoPick = () => {
        setTorso(!torso)
        setNameColor(false)
        setHead(false)
        setHand(false)
        setWatch(false)
    }
    const [style, setStyle] = useState(false)
    const handleStylePick = () => {
        setStyle(!style)
    }
    const [hand, setHand] = useState(false)
    const handleHandPick = () => {
        setHand(!hand)
        setHead(false)
        setNameColor(false)
        setTorso(false)
        setWatch(false)
    }
    const [watch, setWatch] = useState(false)
    const handleWatchPick = () => {
        setWatch(!watch)
        setHand(false)
        setHead(false)
        setNameColor(false)
        setTorso(false)

    }
    const [subColor, setSubColor] = useState(false)
    const handleSubColorPick = () => {
        setSubColor(!subColor)
    }


    const isHeadSelected = selector.filterData.avatarTraitsHead

    // const [isHeadSelected, setIsHeadSelected] = useState([])

    const handleHeadSelect = (param, index) => {
        let trueDataValue = isHeadSelected.some(item => param === item)
        if (trueDataValue) {
            let newArrayReduce = isHeadSelected.reduce((acc, curr) => {
                if (curr !== param) acc.push(curr);
                return acc;
            }, []);
            // setIsHeadSelected(newArrayReduce)
            dispatch(AvatarTraitsHead(newArrayReduce))
        }
        else {
            let newArray = [...isHeadSelected]
            newArray.push(param)
            // setIsHeadSelected(newArray)
            dispatch(AvatarTraitsHead(newArray))
        }
    }


    const isHandSelected = selector.filterData.avatarHand

    const handleHandSelect = (param, index) => {
        let trueDataValue = isHandSelected.some(item => param === item)
        if (trueDataValue) {
            let newArrayReduce = isHandSelected.reduce((acc, curr) => {
                if (curr !== param) acc.push(curr);
                return acc;
            }, []);
            dispatch(AvatarHand(newArrayReduce))
        }
        else {
            let newArray = [...isHandSelected]
            newArray.push(param)
            dispatch(AvatarHand(newArray))
        }
    }


    // const [isWatchSelected, setIsWatchSelected] = useState([])
    const isWatchSelected = selector.filterData.avatarWatch

    const handleWatchSelect = (param, index) => {
        //console.log({param},{index} ,typeof param)

        let trueDataValue = isWatchSelected.some(item => param === item)

        if (trueDataValue) {

            let newArrayReduce = isWatchSelected.reduce((acc, curr) => {
                if (curr !== param) acc.push(curr);
                return acc;
            }, []);
            //console.log({newArrayReduce})
            dispatch(AvatarWatch(newArrayReduce))


        }
        else {
            let newArray = [...isWatchSelected]
            newArray.push(param)
            //console.log({newArray})
            dispatch(AvatarWatch(newArray))

        }

    }
    //BIG-TOP
    //
    //   const [isTorsoSelected, setIsTorsoSelected] = useState(false)
    // const [torsoValue,setTorsoValue] = useState('big-top')
    const torsoValue = selector.filterData.avatarTorso

    const handleTorsoSelect = () => {
        let param = 'BIG-TOP'

        let trueDataValue = torsoValue.some(item => param === item)

        if (trueDataValue) {

            let newArrayReduce = torsoValue.reduce((acc, curr) => {
                if (curr !== param) acc.push(curr);
                return acc;
            }, []);
            //console.log({newArrayReduce})
            dispatch(AvatarTorso(newArrayReduce))


        }
        else {
            let newArray = [...torsoValue]
            newArray.push(param)
            //console.log({newArray})
            dispatch(AvatarTorso(newArray))

        }




        // if (torsoValue.length > 0) {
        //     dispatch(AvatarTorso(''))
        // }
        // else {
        //     dispatch(AvatarTorso('BIG-TOP'))
        // }

    }

    const [selected, setSelected] = useState("")


    const handleStyleHeadValues = () => {

    }





    const userNameColorArray = selector.filterData.avatarTraitsUserNameColor
    console.log("userNameColorArray", userNameColorArray)

    // const [userNameColorArray,setUserNameColorArray]= useState([])
    const handleChangeUserNameColor = (param, i) => {

        let newArrayForUserNameColor = userNameColorArray.some(item => param === item)

        if (newArrayForUserNameColor) {
            let newArrayReduceUserNameColor = userNameColorArray.reduce((acc, curr) => {
                if (curr !== param) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            // setUserNameColorArray(newArrayReduceUserNameColor)
            // props.setRarityApiData(newArrayReduce)

            dispatch(AvatarTraitsUserNameColor(newArrayReduceUserNameColor))


        }
        else {

            let newArray = [...userNameColorArray]
            newArray.push(param)
            // setUserNameColorArray(newArray)
            // props.setRarityApiData(newArray)
            dispatch(AvatarTraitsUserNameColor(newArray))


        }
    }


    // const [headStyleArray,setheadStyleArray]= useState([])
    const headStyleArray = selector.filterData.avatarTraitsHeadStyle

    const handleChangeHeadStyle = (param, i) => {

        let newArray = headStyleArray.some(item => param === item)

        if (newArray) {
            let newArrayReduce = headStyleArray.reduce((acc, curr) => {
                if (curr !== param) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            // setheadStyleArray(newArrayReduce)

            dispatch(AvatarTraitsHeadStyle(newArrayReduce))


        }
        else {

            let newArray = [...headStyleArray]
            newArray.push(param)
            // setheadStyleArray(newArray)
            dispatch(AvatarTraitsHeadStyle(newArray))


        }
    }
    const clearHeadStyle = () => {
        dispatch(AvatarTraitsHeadStyle([]))

    }



    // const [handStyleArray,setHandStyleArray]= useState([])
    const handStyleArray = selector.filterData.avatarHandStyle
    const handleChangeHandStyle = (param, i) => {
        let newArray = handStyleArray.some(item => param === item)
        if (newArray) {
            let newArrayReduce = handStyleArray.reduce((acc, curr) => {
                if (curr !== param) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            dispatch(AvatarHandStyle(newArrayReduce))
        }
        else {
            let newArray = [...handStyleArray]
            newArray.push(param)
            dispatch(AvatarHandStyle(newArray))
        }
    }
    const clearHandStyle = () => {
        dispatch(AvatarHandStyle([]))
    }




    // const [headColorArray,setheadColorArray]= useState([])
    const headColorArray = selector.filterData.avatarTraitsHeadColor

    const handleChangeHeadColor = (param, i) => {
        let newArray = headColorArray.some(item => param === item)
        if (newArray) {
            let newArrayReduce = headColorArray.reduce((acc, curr) => {
                if (curr !== param) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            // setheadColorArray(newArrayReduce)
            dispatch(AvatarTraitsHeadColor(newArrayReduce))
        }
        else {
            let newArray = [...headColorArray]
            newArray.push(param)
            // setheadColorArray(newArray)
            dispatch(AvatarTraitsHeadColor(newArray))
        }
    }
    const clearHeadColor = () => {
        // setheadColorArray([])
        dispatch(AvatarTraitsHeadColor([]))

    }


    // const [torsoColorArray,setTorsoColorArray]= useState([])
    const torsoColorArray = selector.filterData.avatarTorsoColor

    const handleChangeTorsoColor = (param, i) => {
        let newArray = torsoColorArray.some(item => param === item)
        if (newArray) {
            let newArrayReduce = torsoColorArray.reduce((acc, curr) => {
                if (curr !== param) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            dispatch(AvatarTorsoColor(newArrayReduce))

        }
        else {
            let newArray = [...torsoColorArray]
            newArray.push(param)
            dispatch(AvatarTorsoColor(newArray))
        }
    }
    const clearTorsoColor = () => {
        dispatch(AvatarTorsoColor([]))
    }

    // const [handColorArray,setHandColorArray]= useState([])
    const handColorArray = selector.filterData.avatarHandColor

    const handleChangHandColorHand = (param, i) => {
        let newArray = handColorArray.some(item => param === item)
        if (newArray) {
            let newArrayReduce = handColorArray.reduce((acc, curr) => {
                if (curr !== param) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            dispatch(AvatarHandColor(newArrayReduce))

        }
        else {
            let newArray = [...handColorArray]
            newArray.push(param)
            dispatch(AvatarHandColor(newArray))
        }
    }
    const clearHandColor = () => {
        dispatch(AvatarHandColor([]))

    }




    // const [torsoStyleArray,setTorsoStyleArray]= useState([])
    const torsoStyleArray = selector.filterData.avatarTorsoStyle

    const handleChangeTorsoStyle = (param, i) => {
        let newArray = torsoStyleArray.some(item => param === item)
        if (newArray) {
            let newArrayReduce = torsoStyleArray.reduce((acc, curr) => {
                if (curr !== param) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            dispatch(AvatarTorsoStyle(newArrayReduce))
        }
        else {
            let newArray = [...torsoStyleArray]
            newArray.push(param)
            dispatch(AvatarTorsoStyle(newArray))
        }
    }

    const clearTorsoStyle = () => {
        dispatch(AvatarTorsoStyle([]))
    }

    // const [watchColorArray,setWatchColorArray]= useState([])
    const watchColorArray = selector.filterData.avatarWatchColor

    const handleChangeColorWatch = (param, i) => {
        let newArray = watchColorArray.some(item => param === item)
        if (newArray) {
            let newArrayReduce = watchColorArray.reduce((acc, curr) => {
                if (curr !== param) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            dispatch(AvatarWatchColor(newArrayReduce))
        }
        else {
            let newArray = [...watchColorArray]
            newArray.push(param)
            dispatch(AvatarWatchColor(newArray))
        }
    }
    const clearWatchColor = () => {
        dispatch(AvatarWatchColor([]))

    }


    return (
        <Fragment>

            <SidebarDropdown selected={selected} setSelected={setSelected} type='avatar' />

            {selected &&
                <>

                    {/* --------------------------------------- USER NAME COLOR CODE STARTS --------------------------------------- */}

                    <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt mt-3 customNavDropdown `} onClick={handleNameColorPick}>User Name Color
                        <p className="font22Txt orbFont clr-red mb-0 ml-1">{userNameColorArray.length > 0 ? `(${userNameColorArray.length})` : ''}</p>

                        <img src={arrow} alt="arrow" className={`float-right ml-auto ${nameColor ? 'rotateImage' : 'rotateBack'}`} />
                    </button>
                    <div className={`${nameColor ? 'long-checkDropdown-box' : 'd-none'} `}>
                        <div className="long-checkbox-grid">
                            {userNameAndColor.map((key, i) => {
                                const isImgActive = userNameColorArray.indexOf(key.value) > -1

                                return (
                                    <Fragment>
                                        <UserNameColor name={key.name} value={key.value} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} color={key.colorCircle} type="withBG" onClick={() => {
                                            handleChangeUserNameColor(key.value, i)
                                        }
                                        } />


                                    </Fragment>
                                )
                            })

                            }

                        </div>
                    </div>

                    {/* --------------------------------------- USER NAME COLOR CODE ENDS --------------------------------------- */}

                    {/* --------------------------------------- HEAD CODE STARTS --------------------------------------- */}



                    <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown `} onClick={handleHeadPick}>Head
                        <p className="font22Txt orbFont clr-red mb-0 ml-1">{isHeadSelected.length || (headStyleArray.length) || (headColorArray.length) > 0 ? `(${isHeadSelected.length + headStyleArray.length + headColorArray.length})` : ''}</p>
                        <img src={arrow} alt="arrow" className={`float-right ml-auto ${head ? 'rotateImage' : 'rotateBack'}`} />
                    </button>
                    <div className={`${head ? 'long-checkDropdown-box' : 'd-none'} `}>
                        <p className='mb-20 clr-white font12Txt text-center'>Select Head (S)</p>
                        <div className="nft-cont-grid">
                            <button className='btn p-0' onClick={() => { handleHeadSelect('rectangle', 1) }}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHeadSelected?.some((key) => key === 'rectangle') ? 'selected' : ''}`} />
                                    <image href={rectHeadDesel} className={`svg-head-image ${isHeadSelected?.some((key) => key !== 'rectangle') ? '' : 'd-none'}`
                                    } />
                                    <image href={rectHeadDesel} className={`svg-head-image`} />
                                    <image href={rectHeadSel} className={`svg-head-image ${isHeadSelected?.some((key) => key === 'rectangle') ? 'selected' : 'd-none'}`} />
                                    <text x='25%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected?.some((key) => key === 'rectangle') ? 'selected' : ''}`}>Rectangle</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => { handleHeadSelect('heart', 2) }}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block  ${isHeadSelected?.some((key) => key === 'heart') ? 'selected' : ''}`} />
                                    <image href={heartHeadDesel} className={`svg-head-image ${isHeadSelected?.some((key) => key !== 'heart') ? '' : 'd-none'}`} />
                                    <image href={heartHeadDesel} className={`svg-head-image`} />
                                    <image href={heartHeadSel} className={`svg-head-image ${isHeadSelected?.some((key) => key === 'heart') ? 'selected' : 'd-none'}`} />
                                    <text x='35%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected?.some((key) => key === 'heart') ? 'selected' : ''}`}>Heart</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => { handleHeadSelect('block', 3) }}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHeadSelected.some((key) => key === 'block') ? 'selected' : ''}`} />
                                    <image href={blockHeadDesel} className={`svg-head-image `} />
                                    <image href={blockHeadDesel} className={`svg-head-image ${isHeadSelected.some((key) => key !== 'block') ? '' : 'd-none'}`} />
                                    <image href={blockHeadSel} className={`svg-head-image ${isHeadSelected.some((key) => key === 'block') ? 'selected' : 'd-none'}`} />
                                    <text x='35%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected.some((key) => key === 'block') ? 'selected' : ''}`}>Block</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => { handleHeadSelect('oblong', 4) }}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHeadSelected.some((key) => key === 'oblong') ? 'selected' : ''}`} />
                                    <image href={oblongHeadDesel} className={`svg-head-image ${isHeadSelected.some((key) => key !== 'oblong') ? '' : 'd-none'}`} />
                                    <image href={oblongHeadDesel} className={`svg-head-image `} />

                                    <image href={oblongHeadSel} className={`svg-head-image ${isHeadSelected.some((key) => key === 'oblong') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected.some((key) => key === 'oblong') ? 'selected' : ''}`}>Oblong</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => { handleHeadSelect('round', 5) }}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHeadSelected.some((key) => key === 'round') ? 'selected' : ''}`} />
                                    <image href={roundHeadDesel} className={`svg-head-image`} />

                                    <image href={roundHeadDesel} className={`svg-head-image ${isHeadSelected.some((key) => key !== 'round') ? '' : 'd-none'}`} />
                                    <image href={roundHeadSel} className={`svg-head-image ${isHeadSelected.some((key) => key === 'round') ? 'selected' : 'd-none'}`} />
                                    <text x='35%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected.some((key) => key === 'round') ? 'selected' : ''}`}>Round</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => { handleHeadSelect('pear', 6) }}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHeadSelected.some((key) => key === 'pear') ? 'selected' : ''}`} />
                                    <image href={pearHeadDesel} className={`svg-head-image ${isHeadSelected.some((key) => key !== 'pear') ? '' : 'd-none'}`} />
                                    <image href={pearHeadDesel} className={`svg-head-image`} />

                                    <image href={pearHeadSel} className={`svg-head-image ${isHeadSelected.some((key) => key === 'pear') ? 'selected' : 'd-none'}`} />
                                    <text x='40%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected.some((key) => key === 'pear') ? 'selected' : ''}`}>Pear</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => { handleHeadSelect('diamond', 7) }}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block  ${isHeadSelected.some((key) => key === 'diamond') ? 'selected' : ''}`} />
                                    <image href={diamondHeadDesel} className={`svg-head-image  ${isHeadSelected.some((key) => key !== 'diamond') ? '' : 'd-none'}`} />
                                    <image href={diamondHeadDesel} className={`svg-head-image  `} />

                                    <image href={diamondHeadSel} className={`svg-head-image  ${isHeadSelected.some((key) => key === 'diamond') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt  ${isHeadSelected.some((key) => key === 'diamond') ? 'selected' : ''}`}>Diamond</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => { handleHeadSelect('triangle', 8) }}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHeadSelected.some((key) => key === 'triangle') ? 'selected' : ''}`} />
                                    <image href={triangleHeadDesel} className={`svg-head-image ${isHeadSelected.some((key) => key !== 'triangle') ? '' : 'd-none'}`} />
                                    <image href={triangleHeadDesel} className={`svg-head-image `} />

                                    <image href={triangleHeadSel} className={`svg-head-image ${isHeadSelected.some((key) => key === 'triangle') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected.some((key) => key === 'triangle') ? 'selected' : ''}`}>Triangle</text>
                                </svg>
                            </button>
                        </div>

                        <p className="font12Txt clr-white text-uppercase mt-30 mb-0">Style
                            {/*<span className="font22Txt orbFont clr-red mb-0 fontFix ml-1">{headStyleArray.length > 0 ? `(${headStyleArray.length})` : ''}</span>*/}
                        </p>

                        <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt mt-3 customNavDropdown btnHover ${style ? ' traitCustomBtn blueDropdownBG' : ''}`} onClick={handleStylePick}>
                            <div className='inlineMapSizeFix'>
                                {headStyleArray.length > 0 ?

                                    headStyleArray.map((key) => {
                                        return (
                                            <span >{key},</span>
                                        )
                                    })

                                    : 'Select'}
                            </div>
                            <img src={arrow} alt="arrow" className={`float-right ml-auto ${style ? 'rotateImage' : 'rotateBack'}`} />
                        </button>
                        <div className={` p-2 pt-3 ${style ? 'sub-long-checkDropdown-box' : 'd-none'} `} onClick={handleStyleHeadValues}>
                            {headStyleMap.map((key, i) => {
                                const isImgActive = headStyleArray.indexOf(key.value) > -1 ? true : false
                                return (
                                    <Fragment>
                                        <IconCheckbox name={key.name} value={key.value} trueFalse={isImgActive} image={isImgActive ? key.imageSelected : key.image} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} onClick={() => {
                                            handleChangeHeadStyle(key.value, i)
                                        }
                                        } />

                                    </Fragment>
                                )
                            })
                            }
                            <div className="apply-cancel-block">
                                <button className='btn p-0 sidebar-clear-block'>

                                    {headStyleArray.length > 0 ?
                                        <SidebarClearActiveBtn onClick={clearHeadStyle} />

                                        :
                                        <SidebarClearInactiveBtn />
                                    }

                                </button>
                            </div>

                        </div>

                        <p className="font12Txt clr-white text-uppercase mt-30 mb-0">Color
                            {/*<span className="font22Txt orbFont clr-red mb-0 fontFix ml-1">{headColorArray.length > 0 ? `(${headColorArray.length})` : ''}</span>*/}

                        </p>
                        <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt mt-3 customNavDropdown btnHover ${subColor ? ' traitCustomBtn blueDropdownBG' : ''} fixx`} onClick={handleSubColorPick}>
                            <div className='inlineMapSizeFix'>
                                {headColorArray.length > 0 ?
                                    headColorArray.map((key) => {
                                        return (
                                            <span>{key},</span>
                                        )
                                    })
                                    : 'Select'}
                            </div>
                            <img src={arrow} alt="arrow" className={`float-right ml-auto ${subColor ? 'rotateImage' : 'rotateBack'}`} />
                        </button>
                        <div className={` p-2 pt-3 ${subColor ? 'sub-long-checkDropdown-box' : 'd-none'} `}>

                            {colorHeadArray.map((key, i) => {
                                const valueHeadColor = key.value.replace('head-', '')
                                const isImgActive = headColorArray.indexOf(valueHeadColor) > -1 ? true : false
                                return (
                                    <Fragment>
                                        <ColorCheckBox name={key.name} value={key.value} image={isImgActive ? key.imageSelected : key.image} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} onClick={() => {
                                            handleChangeHeadColor(valueHeadColor, i)
                                        }
                                        } />

                                    </Fragment>
                                )
                            })

                            }

                            <div className="apply-cancel-block">
                                <button className='btn p-0 sidebar-clear-block'>
                                    {headColorArray.length > 0 ?
                                        <SidebarClearActiveBtn onClick={clearHeadColor} />
                                        :
                                        <SidebarClearBtn />
                                    }

                                </button>
                            </div>
                        </div>
                    </div>

                    {/* ---------------------------------------HEAD CODE ENDS --------------------------------------- */}

                    {/* --------------------------------------- TORSO CODE STARTS --------------------------------------- */}

                    <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown`} onClick={handleTorsoPick}>Torso
                        <p className="font22Txt orbFont clr-red mb-0 ml-1">{torsoValue.length || (torsoStyleArray.length) || (torsoColorArray.length) > 0 ? `(${torsoValue.length + torsoColorArray.length + torsoStyleArray.length})` : ''}</p>
                        <img src={arrow} alt="arrow" className={`float-right ml-auto ${torso ? 'rotateImage' : 'rotateBack'}`} />
                    </button>
                    <div className={`${torso ? 'long-checkDropdown-box' : 'd-none'} `}>
                        <p className='mb-20 clr-white font12Txt text-center'>Select Torso (S)</p>

                        <div className="nft-cont-grid">
                            <button className='btn p-0' onClick={() => {
                                handleTorsoSelect('bog-top')
                            }
                            }>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${torsoValue.length > 0 ? 'selected' : ''}`} />
                                    <image href={bodyIconDesel} className={`svg-head-image ${!torsoValue.length ? '' : 'd-none'}`} />
                                    <image href={bodyIconSel} className={`svg-head-image ${torsoValue.length ? 'selected' : 'd-none'}`} />
                                    <text x='33%' y='90%' font-size='10' className={`svg-head-txt ${torsoValue.length ? 'selected' : ''}`}>Big Top</text>
                                </svg>
                            </button>
                        </div>
                        <p className="font12Txt clr-white text-uppercase mt-30 mb-0">Style
                            {/*<span className="font22Txt orbFont clr-red mb-0 fontFix ml-1">{torsoStyleArray.length > 0 ? `(${torsoStyleArray.length})` : ''}</span>*/}
                        </p>
                        <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt mt-3 customNavDropdown btnHover ${style ? ' traitCustomBtn blueDropdownBG' : ''}`} onClick={handleStylePick}>
                            <div className='inlineMapSizeFix'>
                                {torsoStyleArray.length > 0 ?
                                    torsoStyleArray.map((key) => {
                                        return (
                                            <span >{key},</span>
                                        )
                                    })

                                    : 'Select'}
                            </div>
                            <img src={arrow} alt="arrow" className={`float-right ml-auto ${style ? 'rotateImage' : 'rotateBack'}`} />
                        </button>
                        <div className={` p-2 pt-3 ${style ? 'sub-long-checkDropdown-box' : 'd-none'} `}>

                            {torsoStyleMap.map((key, i) => {
                                let valueMain = key.value.replace('Torso-', '')
                                const isImgActiveTorso = torsoStyleArray.indexOf(valueMain) > -1 ? true : false
                                return (
                                    <Fragment>
                                        <TorsoStyleCheckBox name={key.name} value={key.value} trueFalse={isImgActiveTorso} image={isImgActiveTorso ? key.imageSelected : key.image} stateActiveCheckBoxTick={isImgActiveTorso ? "activeTickIcon" : "inactive"} onClick={() => {
                                            handleChangeTorsoStyle(valueMain, i)
                                        }
                                        }
                                        />

                                    </Fragment>
                                )
                            })

                            }
                            <div className="apply-cancel-block">
                                <button className='btn p-0 sidebar-clear-block'>
                                    {torsoStyleArray.length > 0 ?
                                        <SidebarClearActiveBtn onClick={clearTorsoStyle} />
                                        :
                                        <SidebarClearBtn />
                                    }
                                </button>
                            </div>
                        </div>

                        <p className="font12Txt clr-white text-uppercase mt-30 mb-0">Color
                            {/*<span className="font22Txt orbFont clr-red mb-0 fontFix ml-1">{torsoColorArray.length > 0 ? `(${torsoColorArray.length})` : ''}</span>*/}
                        </p>
                        <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt mt-3 customNavDropdown btnHover ${subColor ? ' traitCustomBtn blueDropdownBG' : ''}`} onClick={handleSubColorPick}>
                            <div className='inlineMapSizeFix'>
                                {torsoColorArray.length > 0 ?
                                    torsoColorArray.map((key) => {
                                        return (
                                            <span >{key},</span>
                                        )
                                    })

                                    : 'Select'}
                            </div>
                            <img src={arrow} alt="arrow" className={`float-right ml-auto ${subColor ? 'rotateImage' : 'rotateBack'}`} />
                        </button>
                        <div className={` p-2 pt-3 ${subColor ? 'sub-long-checkDropdown-box' : 'd-none'} `}>
                            {colorTorsoArray.map((key, i) => {
                                let valueMain = key.value.replace('torso-', '')
                                const isImgActive = torsoColorArray.indexOf(valueMain) > -1 ? true : false
                                return (
                                    <Fragment>
                                        <ColorCheckBoxTorso name={key.name} value={key.value} image={isImgActive ? key.imageSelected : key.image} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} onClick={() => {
                                            handleChangeTorsoColor(valueMain, i)
                                        }
                                        } />

                                    </Fragment>
                                )
                            })

                            }

                            <div className="apply-cancel-block">
                                <button className='btn p-0 sidebar-clear-block'>
                                    {torsoColorArray.length > 0 ?
                                        <SidebarClearActiveBtn onClick={clearTorsoColor} />
                                        :
                                        <SidebarClearBtn />
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* --------------------------------------- TORSO CODE ENDS --------------------------------------- */}

                    {/* --------------------------------------- HAND CODE STARTS --------------------------------------- */}

                    <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown `} onClick={handleHandPick}>Hand
                        <p className="font22Txt orbFont clr-red mb-0 ml-1">{isHandSelected.length || handStyleArray.length || handColorArray.length > 0 ? `(${isHandSelected.length + handStyleArray.length + handColorArray.length})` : ''}</p>
                        <img src={arrow} alt="arrow" className={`float-right ml-auto ${hand ? 'rotateImage' : 'rotateBack'}`} />
                    </button>
                    <div className={`${hand ? 'long-checkDropdown-box' : 'd-none'} `}>
                        <p className='mb-20 clr-white font12Txt text-center'>Select Hand (S)</p>

                        <div className="nft-cont-grid">
                            <button className='btn p-0' onClick={() => handleHandSelect('Psychic', 1)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHandSelected.some((key) => key === 'Psychic') ? 'selected' : ''}`} />
                                    <image href={psychicHandDesel} className={`svg-head-image ${isHandSelected.some((key) => key !== 'Psychic') ? '' : 'd-none'}`} />
                                    <image href={psychicHandDesel} className={`svg-head-image`} />

                                    <image href={psychicHandSel} className={`svg-head-image ${isHandSelected.some((key) => key === 'Psychic') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isHandSelected.some((key) => key === 'Psychic') ? 'selected' : ''}`}>Psychic</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => handleHandSelect('Comic', 2)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHandSelected.some((key) => key === 'Comic') ? 'selected' : ''}`} />
                                    <image href={comicHandDesel} className={`svg-head-image ${isHandSelected.some((key) => key !== 'Comic') ? '' : 'd-none'}`} />
                                    <image href={comicHandDesel} className={`svg-head-image `} />

                                    <image href={comicHandSel} className={`svg-head-image ${isHandSelected.some((key) => key === 'Comic') ? 'selected' : 'd-none'}`} />
                                    <text x='35%' y='90%' font-size='10' className={`svg-head-txt ${isHandSelected.some((key) => key === 'Comic') ? 'selected' : ''}`}>Comic</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => handleHandSelect('Intellect', 3)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHandSelected.some((key) => key === 'Intellect') ? 'selected' : ''}`} />

                                    <image href={intellectHandDesel} className={`svg-head-image ${isHandSelected.some((key) => key !== 'Intellect') ? '' : 'd-none'}`} />
                                    <image href={intellectHandDesel} className={`svg-head-image`} />

                                    <image href={intellectHandSel} className={`svg-head-image ${isHandSelected.some((key) => key === 'Intellect') ? 'selected' : 'd-none'}`} />
                                    <text x='25%' y='90%' font-size='10' className={`svg-head-txt ${isHandSelected.some((key) => key === 'Intellect') ? 'selected' : ''}`}>Intellect</text>
                                </svg>
                            </button>
                        </div>
                        <p className="font12Txt clr-white text-uppercase mt-30 mb-0">Style
                            {/*<span className="font22Txt orbFont clr-red mb-0 fontFix ml-1">{handStyleArray.length > 0 ? `(${handStyleArray.length})` : ''}</span>*/}
                        </p>
                        <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt mt-3 customNavDropdown btnHover ${style ? ' traitCustomBtn blueDropdownBG' : ''}`} onClick={handleStylePick}>
                            <div className='inlineMapSizeFix'>
                                {handStyleArray.length > 0 ?
                                    handStyleArray.map((key) => {
                                        return (
                                            <span>{key},</span>
                                        )
                                    })
                                    : 'Select'}
                            </div>
                            <img src={arrow} alt="arrow" className={`float-right ml-auto ${style ? 'rotateImage' : 'rotateBack'}`} />
                        </button>
                        <div className={` p-2 pt-3 ${style ? 'sub-long-checkDropdown-box' : 'd-none'} `}>
                            {handStyleMap.map((key, i) => {
                                let valueMainHand = key.value.replace('Hand-', '')
                                const isImgActive = handStyleArray.indexOf(valueMainHand) > -1 ? true : false
                                return (
                                    <Fragment>
                                        <HandStyleCheckBox name={key.name} value={key.value} image={isImgActive ? key.imageSelected : key.image} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} onClick={() => {
                                            handleChangeHandStyle(valueMainHand, i)
                                        }} />
                                    </Fragment>
                                )
                            })}
                            <div className="apply-cancel-block">
                                <button className='btn p-0 sidebar-clear-block'>
                                    {handStyleArray.length > 0 ?
                                        <SidebarClearActiveBtn onClick={clearHandStyle} />
                                        :
                                        <SidebarClearBtn />
                                    }
                                </button>
                            </div>
                        </div>


                        <p className="font12Txt clr-white text-uppercase mt-30 mb-0">Color
                            {/*<span className="font22Txt orbFont clr-red mb-0 fontFix ml-1">{handColorArray.length > 0 ? `(${handColorArray.length})` : ''}</span>*/}
                        </p>
                        <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt mt-3 customNavDropdown btnHover ${subColor ? ' traitCustomBtn blueDropdownBG' : ''}`} onClick={handleSubColorPick}>
                            <div className='inlineMapSizeFix'>
                                {handColorArray.length > 0 ?
                                    handColorArray.map((key) => {
                                        return (
                                            <span>{key},</span>
                                        )
                                    })
                                    : 'Select'}
                            </div>
                            <img src={arrow} alt="arrow" className={`float-right ml-auto ${subColor ? 'rotateImage' : 'rotateBack'}`} />
                        </button>
                        <div className={` p-2 pt-3 ${subColor ? 'sub-long-checkDropdown-box' : 'd-none'} `}>
                            {colorHandArray.map((key, i) => {
                                let valueMainHandColor = key.value.replace('hand-', '')
                                const isImgActive = handColorArray.indexOf(valueMainHandColor) > -1
                                return (
                                    <Fragment>
                                        <ColorCheckBoxHand name={key.name} value={key.value} image={isImgActive ? key.imageSelected : key.image} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} onClick={() => {
                                            handleChangHandColorHand(valueMainHandColor, i)
                                        }
                                        } />

                                    </Fragment>
                                )
                            })

                            }
                            <div className="apply-cancel-block">
                                <button className='btn p-0 sidebar-clear-block'>
                                    {handColorArray.length > 0 ?
                                        <SidebarClearActiveBtn onClick={clearHandColor} />
                                        :
                                        <SidebarClearBtn />
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* --------------------------------------- HAND CODE ENDS --------------------------------------- */}

                    {/* --------------------------------------- WRISTWATCH CODE STARTS --------------------------------------- */}

                    <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown `} onClick={handleWatchPick}>
                        Watch/Writstband
                        <p className="font22Txt orbFont clr-red mb-0 ml-1">{isWatchSelected.length || watchColorArray.length > 0 ? `(${isWatchSelected.length + watchColorArray.length})` : ''}</p>
                        <img src={arrow} alt="arrow" className={`float-right ml-auto ${watch ? 'rotateImage' : 'rotateBack'}`} />
                    </button>
                    <div className={`${watch ? 'long-checkDropdown-box' : 'd-none'} `}>
                        <p className='mb-20 clr-white font12Txt text-center'>Select Watch (S)</p>

                        <div className="nft-cont-grid">
                            <button className='btn p-0' onClick={() => handleWatchSelect('Watch 1', 1)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isWatchSelected.some((key) => key === 'Watch 1') ? 'selected' : ''}`} />
                                    <image href={watchOneDesel} className={`svg-head-image ${isWatchSelected.some((key) => key !== 'Watch 1') ? '' : 'd-none'}`} />
                                    <image href={watchOneDesel} className={`svg-head-image`} />

                                    <image href={watchOneSel} className={`svg-head-image ${isWatchSelected.some((key) => key === 'Watch 1') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isWatchSelected ? 'selected' : ''}`}>Watch 1</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => handleWatchSelect('Watch 2', 2)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isWatchSelected.some((key) => key === 'Watch 2') ? 'selected' : ''}`} />
                                    <image href={watchTwoDesel} className={`svg-head-image ${isWatchSelected.some((key) => key !== 'Watch 2') ? '' : 'd-none'}`} />
                                    <image href={watchTwoDesel} className={`svg-head-image `} />
                                    <image href={watchTwoSel} className={`svg-head-image ${isWatchSelected.some((key) => key === 'Watch 2') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isWatchSelected ? 'selected' : ''}`}>Watch 2</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => handleWatchSelect('Watch 3', 3)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isWatchSelected.some((key) => key === 'Watch 3') ? 'selected' : ''}`} />
                                    <image href={watchThreeDesel} className={`svg-head-image ${isWatchSelected.some((key) => key !== 'Watch 3') ? '' : 'd-none'}`} />
                                    <image href={watchThreeDesel} className={`svg-head-image `} />
                                    <image href={watchThreeSel} className={`svg-head-image ${isWatchSelected.some((key) => key === 'Watch 3') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isWatchSelected ? 'selected' : ''}`}>Watch 3</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => handleWatchSelect('Watch 4', 4)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isWatchSelected.some((key) => key === 'Watch 4') ? 'selected' : ''}`} />
                                    <image href={watchFourDesel} className={`svg-head-image ${isWatchSelected.some((key) => key === 'Watch 4') ? '' : 'd-none'}`} />
                                    <image href={watchFourDesel} className={`svg-head-image `} />

                                    <image href={watchFourSel} className={`svg-head-image ${isWatchSelected.some((key) => key === 'Watch 4') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isWatchSelected.some((key) => key === 'Watch 4') ? 'selected' : ''}`}>Watch 4</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => handleWatchSelect('Watch 5', 5)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isWatchSelected.some((key) => key === 'Watch 5') ? 'selected' : ''}`} />
                                    <image href={watchFiveDesel} className={`svg-head-image ${isWatchSelected.some((key) => key !== 'Watch 5') ? '' : 'd-none'}`} />
                                    <image href={watchFiveDesel} className={`svg-head-image `} />

                                    <image href={watchFiveSel} className={`svg-head-image ${isWatchSelected.some((key) => key === 'Watch 5') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isWatchSelected ? 'selected' : ''}`}>Watch 5</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={() => handleWatchSelect('Watch 6', 6)}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isWatchSelected.some((key) => key === 'Watch 6') ? 'selected' : ''}`} />
                                    <image href={watchSixDesel} className={`svg-head-image ${isWatchSelected.some((key) => key !== 'Watch 6') ? '' : 'd-none'}`} />
                                    <image href={watchSixDesel} className={`svg-head-image `} />

                                    <image href={watchSixSel} className={`svg-head-image ${isWatchSelected.some((key) => key === 'Watch 6') ? 'selected' : 'd-none'}`} />
                                    <text x='30%' y='90%' font-size='10' className={`svg-head-txt ${isWatchSelected.some((key) => key === 'Watch 6') ? 'selected' : ''}`}>Watch 6</text>
                                </svg>
                            </button>

                            {/* -------------------Wristband code has been commented for further instruction------------------- */}


                            {/* <button className='btn p-0' onClick={handleHeadSelect}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHeadSelected ? 'selected' : ''}`} />
                                    <image href={wristbandOneDesel} className={`svg-head-image ${!isHeadSelected ? '' : 'd-none'}`} />
                                    <image href={wristbandOneSel} className={`svg-head-image ${isHeadSelected ? 'selected' : 'd-none'}`} />
                                    <text x='20%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected ? 'selected' : ''}`}>Wristband 1</text>
                                </svg>
                            </button>
                            <button className='btn p-0' onClick={handleHeadSelect}>
                                <svg className="svgBtn" width="107" height="110" viewBox="0 0 107 110" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H85.6L107 23.65V110H0V0Z" className={`svg-image-block ${isHeadSelected ? 'selected' : ''}`} />
                                    <image href={wristbandTwoDesel} className={`svg-head-image ${!isHeadSelected ? '' : 'd-none'}`} />
                                    <image href={wristbandTwoSel} className={`svg-head-image ${isHeadSelected ? 'selected' : 'd-none'}`} />
                                    <text x='20%' y='90%' font-size='10' className={`svg-head-txt ${isHeadSelected ? 'selected' : ''}`}>Wristband 2</text>
                                </svg>
                            </button> */}

                        </div>

                        <p className="font12Txt clr-white text-uppercase mt-30 mb-0">Color
                            {/*<span className="font22Txt orbFont clr-red mb-0 ml-1">{watchColorArray.length > 0 ? `(${watchColorArray.length})` : ''}</span>*/}
                        </p>
                        <button className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt mt-3 customNavDropdown btnHover ${subColor ? ' traitCustomBtn blueDropdownBG' : ''}`} onClick={handleSubColorPick}>
                            <div className='inlineMapSizeFix'>
                                {watchColorArray.length > 0 ?
                                    watchColorArray.map((key) => {
                                        return (
                                            <span>{key},</span>
                                        )
                                    })
                                    : 'Select'}
                            </div>
                            <img src={arrow} alt="arrow" className={`float-right ml-auto ${subColor ? 'rotateImage' : 'rotateBack'}`} />
                        </button>
                        <div className={` p-2 pt-3 ${subColor ? 'sub-long-checkDropdown-box' : 'd-none'} `}>
                            {colorArrayWatch.map((key, i) => {
                                let valueMainWatchMain = key.value.replace('watch-', '')
                                const isImgActive = watchColorArray.indexOf(valueMainWatchMain) > -1
                                return (
                                    <Fragment>
                                        <ColorCheckBoxWatch name={key.name} value={key.value} image={isImgActive ? key.imageSelected : key.image} stateActiveCheckBoxTick={isImgActive ? "activeTickIcon" : "inactive"} onClick={() => {
                                            handleChangeColorWatch(valueMainWatchMain, i)
                                        }
                                        } />

                                    </Fragment>
                                )
                            })

                            }
                            <div className="apply-cancel-block">
                                <button className='btn p-0 sidebar-clear-block'>
                                    {watchColorArray.length > 0 ?
                                        <SidebarClearActiveBtn onClick={clearWatchColor} />
                                        :
                                        <SidebarClearBtn />
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* --------------------------------------- WRISTWATCH CODE ENDS --------------------------------------- */}

                </>
            }

        </Fragment>
    )
}

export default AvatarTraits
