import {combineReducers, createStore} from 'redux'
import ethReducer from './reducers/eth.reducer'
import modalReducer from "./reducers/modal.reducer";
import ethPriceReducer from './reducers/ethPrice.reducer'
import filterReducer from "./reducers/filtersChanges";
import userReducer from "./reducers/user.reducer";

const rootReducer = combineReducers({
    user: ethReducer,
    userNormal: userReducer,
    modals: modalReducer,
    ethPrice: ethPriceReducer,
    filterData:filterReducer
})

const store = createStore(rootReducer)

export default store

