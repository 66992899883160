import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router";
import AvatarMintScreen from '../component/MintScreen/AvatarMintScreen'
import ItemsMintScreen from '../component/MintScreen/ItemsMintScreen'
import RealEstateMintScreen from '../component/MintScreen/RealEstateMintScreen'
import axios from "axios";
import {
    API_BASE_URL,
    AVATAR_URL,
    DEFAULT_WEB3_ADDRESS,
    ITEMS_URL,
    PRIMARY_SALE_ADDRESS,
    REAL_STATE_ADDRESS,
    REAL_STATE_URL,
    SECONDARY_SALE_ADDRESS
} from "../utils/config";

import { Link } from "react-router-dom";
import { PRIMARY_SALE_ABI, SECONDARY_SALE_ABI } from "../utils/ABI";
import * as Web3 from "web3";
import { getContractAddressByURL } from "../utils/helper";
import SuccessModal from "../component/Detail/SuccessModal";

const defaultWeb3 = new Web3(DEFAULT_WEB3_ADDRESS)
const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(SECONDARY_SALE_ABI, SECONDARY_SALE_ADDRESS);


const MintScreen = () => {
    const params = useParams();
    const nftContract = params.nftContract

    const [showRealEstateMintScreen, setRealEstateMintScreen] = useState(false)
    const [showAvatarMintScreen, setAvatarMintScreen] = useState(false)
    const [showItemsMintScreen, setItemsMintScreen] = useState(false)
    const [mintingDetail, setMintingDetail] = useState({})
    const [active, setActive] = useState(1)
    const [saleStartTime, setSaleStartTime] = useState("")

    const history = useHistory()

    const url = history.location.pathname

    // param check and show hide of minting screen
    const checkMintScreen = () => {
        if (nftContract === REAL_STATE_URL) {
            setRealEstateMintScreen(true)
            setAvatarMintScreen(false)
            setItemsMintScreen(false)
        } else if (nftContract === AVATAR_URL) {
            setAvatarMintScreen(true)
            setItemsMintScreen(false)
            setRealEstateMintScreen(false)
        } else if (nftContract === ITEMS_URL) {
            setItemsMintScreen(true)
            setAvatarMintScreen(false)
            setRealEstateMintScreen(false)
        }
    }


    // get api for solOut data
    const getNFTMintingDetail = () => {

        const contract = getContractAddressByURL(nftContract)
        // const contract = nftContract === REAL_STATE_URL ? REAL_STATE_ADDRESS : nftContract === ITEMS_URL ? ITEMS_URL : AVATAR_URL
        axios.get(API_BASE_URL + 'smart-contract/mint-details/' + contract)
            .then((res) => {
                setMintingDetail(res.data)
            }).catch((err) => {
                //consollog("failed to fetch mint details", err)
            })
    }
    const getMintPriceAndToken = async () => {
        const contract = getContractAddressByURL(nftContract)

        try {
            const contractCall = new defaultWeb3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS)
            const data = await contractCall.methods.whitelistedContracts(contract).call();
            //consollog("data.saleStartTime", data.saleStartTime)
            setSaleStartTime(data.saleStartTime)
        } catch (e) {
            //consollog('balance error', e)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getNFTMintingDetail()
        }, 5000);
        return () => clearInterval(interval);
    }, [nftContract]);


    useEffect(() => {
        checkMintScreen()
        getNFTMintingDetail()
    }, [nftContract])

    useEffect(() => {
        getMintPriceAndToken()
    }, [nftContract])

    useEffect(() => {
        if (url === "/mint/avatar") {
            setActive(1)
        }
        if (url === "/mint/real-estate") {
            setActive(2)
        }
        if (url === "/mint/items") {
            setActive(3)
        }
    }, [url])


    return (
        <Fragment>
            <Fragment>
                <div className="tabHeaderBG">
                    <Link to='/mint/avatar' className="mx-3">
                        <button
                            className={`btn font18Txt ${active === 1 ? "tabHeaderBtn" : "clr-white"
                                }`}
                        >
                            Avatars
                        </button>
                    </Link>
                    <Link to="/mint/real-estate" className="mx-3">
                        <button
                            className={`btn font18Txt ${active === 2 ? "tabHeaderBtn" : "clr-white"
                                }`}
                        >
                            Real Estate
                        </button>
                    </Link>
                    <Link to="/mint/items" className="mx-3">
                        <button
                            className={`btn font18Txt ${active === 3 ? "tabHeaderBtn" : "clr-white"
                                }`}
                        >
                            Items
                        </button>
                    </Link>
                </div>
            </Fragment>

            {showAvatarMintScreen && saleStartTime &&
                <AvatarMintScreen nftContract={nftContract}
                    mintingDetail={mintingDetail}
                    saleStartTime={saleStartTime}
                />
            }
            {showRealEstateMintScreen && saleStartTime &&
                <RealEstateMintScreen nftContract={nftContract}
                    mintingDetail={mintingDetail}
                    saleStartTime={saleStartTime}
                />
            }
            {showItemsMintScreen && saleStartTime &&
                <ItemsMintScreen nftContract={nftContract}
                    mintingDetail={mintingDetail}
                    saleStartTime={saleStartTime}
                />

            }


        </Fragment>
    )
}

export default MintScreen
