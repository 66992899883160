import React, { Fragment } from 'react'
import "../../css/marketCard.css"
import "../../css/CustomCard.css"

const SkeletonCard = (props) => {
    const N = props?.loop;
    const arr = Array.from({ length: N }, (_, index) => index + 1);
    return (
        arr.length > 0 && arr.map((key, index) => {
            return (
                <Fragment key={index}>
                    <div className="marketCard skeleton-card">
                        <div className="profile-card-skeleton">
                            <div className="skeleton-profile skeleton-animation"></div>
                        </div>
                        <div className="card-grayBG">
                            <div className="skeleton-text skeleton-animation mt-2"></div>
                            <div className="skeleton-image skeleton-animation mt-2"></div>
                            <div className="skeleton-price skeleton-animation mt-2"></div>
                            <div className="skeleton-price skeleton-animation mt-1"></div>
                        </div>
                    </div>
                </Fragment>
            )
        }
        )
    )
}

export default SkeletonCard
