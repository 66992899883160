import React, { Fragment } from "react"
import "../../css/wallet.css"
import "../../css/detail.css"
import twitterIcon from "../../images/twitter-blue.svg"
import fbIcon from "../../images/fb.svg"
import telegramIcon from "../../images/telegram.svg"
import linkIcon from "../../images/link.svg"
import grayCross from "../../images/grayCross.svg"
import blueCross from "../../images/blueCross.svg"
import { useDispatch, useSelector } from "react-redux";
import AvatarDetailImage from "../Detail/AvatarDetailImage"
import { SaleSuccessModalShowHide } from "../../redux/actions/modalActions";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { AVATAR_URL, ITEMS_URL, REAL_STATE_URL } from "../../utils/config";
import RealEstateDetailImage from "./RealEstateDetailImage";
import ItemsDetailImage from "./ItemsDetailImage";
import { getContractURL } from "../../utils/helper";
import { useParams } from "react-router";


const ItemForSaleModal = (props) => {
    const params = useParams()

    const NFT_CONTRACT = params.nftContract
    const NFT_URL = getContractURL(params.nftContract).toLowerCase()
    const { addToast } = useToasts()
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const showModal = selector.modals.saleSuccessModal
    const pageUrl = window.location.href

    const hideModal = () => {
        dispatch(SaleSuccessModalShowHide(false))
        setTimeout(() => {
            window.location.reload()
        }, 2000)
    }

    return (
        <Fragment>
            <div className={`modal fade vertical-scroll ${showModal ? "show" : ""}`} role="dialog">
                <div className="success-box-modal">
                    <div className="modal-content modal-content-box pos-rel text-center">
                        <div className="modal-cancel-btn">
                            <img src={grayCross} alt="cross" className="float-right" />
                            <img src={blueCross} alt="cross" className="float-right btn-hover"
                                onClick={hideModal} />
                        </div>
                        <h5 className="font36BoldTxt clr-red orbFont w-60">YOUR ITEM IS NOW FOR SALE</h5>
                        <div className="modal-image-block">
                            {NFT_URL === REAL_STATE_URL.toLowerCase() &&
                                <RealEstateDetailImage />
                            }

                            {NFT_URL === AVATAR_URL.toLowerCase() &&
                                <AvatarDetailImage />
                            }

                            {NFT_URL === ITEMS_URL.toLowerCase() &&
                                <ItemsDetailImage />
                            }
                        </div>
                        <div className="email-notification-area text-left">
                            <p className="font16Txt clr-gray">ENABLE EMAIL NOTIFICATIONS</p>
                            <p className="font18Txt clr-white text-initial">Enter your email address in your account
                                settings so we can let you know when your listing sells or receives offers</p>
                            <button className="btn px-0">
                                <svg width="270" height="42" viewBox="0 0 270 42" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="M30.2634 41H1V1H269V31L258.763 41H51.2634" stroke="#00FFFF"
                                            stroke-linecap="round" />
                                        <text x="12" y="25" font-size="14" font-family="orbitron" fill="#00ffff">MANAGE
                                            ACCOUNT SETTINGS
                                        </text>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className="bdr-btm pb-30" />
                        <p className="font16Txt mb-0 clr-gray mt-25 text-left">SHARE YOUR LISTING</p>
                        <div className="modal-icon-flex">

                            <button className="btn br-0 modal-icon-block">
                                <TwitterShareButton
                                    url={pageUrl}
                                    quote="link"
                                    className="Demo__some-network__share-button">
                                    <img src={twitterIcon} alt="twitter"

                                    />
                                </TwitterShareButton>
                            </button>

                            <button className="btn br-0 modal-icon-block">
                                <CopyToClipboard text={`https://mumbai.polygonscan.com/tx` + '/' + props?.hash?.message}
                                    onCopy={() => (
                                        { copied: true },
                                        addToast('Copy successfully', {
                                            appearance: 'success',
                                            autoDismiss: true
                                        })
                                    )}>
                                    <img src={linkIcon} alt="copyIcon" />

                                </CopyToClipboard>
                            </button>
                            <button className="btn br-0 modal-icon-block">
                                <TelegramShareButton
                                    url={pageUrl}
                                    quote="link"
                                    className="Demo__some-network__share-button">
                                    <img src={telegramIcon} alt="twitter"
                                    />
                                </TelegramShareButton>
                            </button>

                            <button className="btn br-0 modal-icon-block">
                                <FacebookShareButton
                                    url={pageUrl}
                                    quote="link"
                                    className="Demo__some-network__share-button">
                                    <img src={fbIcon} alt="twitter"
                                    />
                                </FacebookShareButton>
                            </button>
                        </div>
                        <div className="bdr-btm pb-40" />
                        <div className="mt-40">
                            <button className="btn">
                                <svg className="svg-btn" width="237" height="40" viewBox="0 0 237 40" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path className="default-Btn" d="M0.5 0.5H236.5V29.7929L226.793 39.5H0.5V0.5Z"
                                        fill="#072D2D" stroke="#00FFFF" />
                                    <text x="65" y="27" font-size="18" font-family="orbitron" font-weight="400"
                                        fill="#00ffff">View Item
                                    </text>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default ItemForSaleModal
