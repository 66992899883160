import React, { Fragment, useCallback, useEffect, useState } from 'react'
import NoItemSale from './noItemSale';
import axios from "axios";
import { API_BASE_URL, AVATAR_ADDRESS, ITEMS_ADDRESS, PRICE_TOKEN_ETH, REAL_STATE_ADDRESS } from "../../utils/config";
import { useSelector } from "react-redux";
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from "@mui/icons-material/ViewList"
import { Field, Form, Formik } from 'formik'
import DropdownInput from '../Dropdown/DropdownInput'
import WalletGridViewItems from './WalletGridViewItems';
import WalletListViewItems from './WalletListViewItems';
import WalletEditModal from "./WalletEditModal";
import LeftArrow from "../../images/arrowLeft.svg";
import RightArrow from "../../images/arrowRight.svg";
import searchIcon from "../../images/searchIcon.svg";
import { SORTING_DATA } from "../../utils/helper";
import NavbarDropdown from "../Dropdown/NavbarDropdown"


const WalletForSale = () => {

    const selector = useSelector(state => state)
    const userAddress = selector.user.address
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [noData, setNoData] = useState(false)
    const [forSale, setForSale] = useState(true)
    const [pageLimit] = useState(10)
    const [pageNo, setPageNo] = useState(1)
    const [maxResult, setMaxResult] = useState(0)
    const [sortValue, setSortValue] = useState("0")
    const [sortValueSort, setSortValueSort] = useState("21")

    const [sortValueNft, setSortValueNft] = useState("")

    const sortValuesNft = [
        {
            name: 'Avatar',
            value: '0'
        },
        {
            name: 'Real Estate',
            value: '1'
        },
        {
            name: 'Items',
            value: '2'
        },
    ]
    const getContractAddress = () => {
        if (sortValueNft === "0") {
            return AVATAR_ADDRESS
        } else if (sortValueNft === '1') {
            return REAL_STATE_ADDRESS
        } else if (sortValueNft === "2") {
            return ITEMS_ADDRESS
        }
    }
    const maxPage = Math.ceil(maxResult / pageLimit)
    const contractBasedOnDropDownFilter = getContractAddress(sortValueNft)

    const getSale = useCallback(() => {
        setLoading(true)
        setNoData(false)

        const limit = "limit=" + pageLimit;
        const offset = "&skip=" + (pageNo === 1 ? 0 : ((pageNo - 1) * pageLimit))

        //sort by case with orderby and order
        const sortSplitValues = sortValueSort ? sortValueSort.toString().split("") : ""
        const orderBy = sortSplitValues.length > 0 ? "&orderBy=" + sortSplitValues[0] : "&orderBy=2";
        const order = sortSplitValues.length > 0 ? "&order=" + sortSplitValues[1] : "&order=1";

        const nftContract = "nftContracts=" + PRICE_TOKEN_ETH + "&nftContracts=" + contractBasedOnDropDownFilter;

        //if nft contract exist then pass in in params
        const nft = sortValueNft ? ("?" + nftContract + '&') : "?"


        const params = nft + limit + offset + orderBy + order

        // to test cards use this address 0xB6e3974F93B9e5790Ae0a3f4Aea00c83bdD26bfc
        axios.get(API_BASE_URL + 'marketplace/user/secondary/listed/' + userAddress + params)
            .then(function (res) {
                setData(res.data.listedSales)
                setMaxResult(res.data.totalListedSales)
                //consollog("saleData", res.data.listedSales)
                if (res.data.listedSales.length === 0) {
                    setNoData(true)
                }
            }).catch(() => {
                setData([])
                setNoData(true)

            }).finally(() => {
                setLoading(false)
            })

    }, [pageNo, sortValue, sortValueNft, contractBasedOnDropDownFilter])


    useEffect(() => {
        getSale()
    }, [getSale])


    const [viewType, setViewType] = useState(1) // 1 used for grid view and 2 used for list view


    const initialValues = {
        name: '',
    }

    const handleSubmit = () => {
        //consollog('values printed')
    }


    const handlePageChange = (type) => {
        // //consollog('hit', type, maxPage, pageNo)
        if (type === "INC") {
            //when max page is matched with current page no then dont call any api
            if (pageNo >= maxPage) return
            setPageNo(prevState => prevState + 1)
        } else if (type === "DEC") {
            if (pageNo === 1) return
            setPageNo(prevState => prevState - 1)
        } else {
            //consollog("type not matched")
        }
    }

    const handleSortChange = (selectedSort) => {
        setSortValueSort(selectedSort)
    }
    const handleSortChangeNft = (selectedSort) => {
        setSortValueNft(selectedSort)
    }
    return (
        <Fragment>

            {/* <WalletSearchbar /> */}


            <div className="searchbar-grid">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    <Form className="search-area pos-rel">
                        <span>
                            <img src={searchIcon} alt="search" aria-hidden="true" className="search-icon" />
                        </span>
                        <Field type="text" name="search" placeholder="Search" className="w-100 h-100 wallet-input" />
                    </Form>
                </Formik>

                {/*<DropdownInput data={SORTING_DATA} selected={sortValue}*/}
                {/*    defaultOption={"Sort By"}*/}
                {/*    handleChangeSelection={handleSortChange} />*/}

                <NavbarDropdown data={sortValuesNft} selected={sortValue}
                    defaultOption={"Sort By"}
                    handleChangeSelection={handleSortChangeNft}
                    className="h-100 chakraFont" />



                <NavbarDropdown data={SORTING_DATA} selected={sortValueSort}
                    defaultOption={"Sort By"}
                    handleChangeSelection={handleSortChange}
                    className="h-100 chakraFont" />


                <div className="viewBtn-block">
                    <button
                        className={`btn br-0 gridBtn ${viewType === 1 ? "blueBtn lightBlueBtn" : "clr-gray"}`}
                        onClick={() => setViewType(1)}
                    >
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 6.6528V0.5H6.6528V6.6528H0.5Z" className="svgHoverBlue" />
                            <path d="M9.84406 6.6528V0.5H15.9969V6.6528H9.84406Z" className="svgHoverBlue" />
                            <path d="M0.5 15.5V9.3472H6.6528V15.5H0.5Z" className="svgHoverBlue" />
                            <path d="M9.84406 15.5V9.3472H15.9969V15.5H9.84406Z" className="svgHoverBlue" />
                        </svg>
                    </button>
                    <button
                        className={`btn br-0 gridBtn ${viewType === 2 ? "blueBtn lightBlueBtn" : "clr-gray"}`}
                        onClick={() => setViewType(2)}
                    >
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 4.45V0.5H4.45V4.45H0.5Z" className="svgHoverBlue" />
                            <path d="M7.44995 4.45V0.5H19.29V4.45H7.44995Z" className="svgHoverBlue" />
                            <path d="M0.5 10.62V6.67004H4.45V10.62H0.5Z" className="svgHoverBlue" />
                            <path d="M7.44995 10.62V6.67004H19.29V10.62H7.44995Z" className="svgHoverBlue" />
                            <path d="M0.5 16.78V12.83H4.45V16.78H0.5Z" className="svgHoverBlue" />
                            <path d="M7.44995 16.78V12.83H19.29V16.78H7.44995Z" className="svgHoverBlue" />
                        </svg>

                    </button>
                </div>
            </div>

            <WalletGridViewItems
                viewType={viewType}
                data={data}
                loading={loading}
                forSale={forSale}
            />

            <WalletListViewItems
                viewType={viewType}
                data={data}
                loading={loading}
            />


            {!loading && noData && data.length === 0 && (
                <NoItemSale />
            )}


            <WalletEditModal />

            {data.length > 0 &&
                <div className="pageNavigator">
                    <button className="btn" onClick={() => handlePageChange("DEC")}>
                        <img src={LeftArrow} alt="leftArrow" />
                    </button>
                    <p className="page--txt">Page</p>
                    <p className="page--count">{pageNo}</p>
                    <p className="page--txt">of &nbsp; {maxPage}</p>
                    <button className="btn" onClick={() => handlePageChange("INC")}>
                        <img src={RightArrow} alt="rightArrow" />
                    </button>
                </div>
            }

        </Fragment>
    )
}

export default WalletForSale








