// change price modal from wallet page
export const EditPriceModal = (open, nftInfo = {}) => {
    return {
        type: 'EDIT_PRICE_NFT',
        open,
        nftInfo
    }
}

//sell nft modal from detail page
export const SellNFTModalAction = (open, nftInfo = {}) => {
    return {
        type: 'SELL_NFT_MODAL',
        open,
        nftInfo
    }
}
export const BuySuccessModalShowHide = (open) => {
    //consollog("Action triggered")
    return {
        type: 'SUCCESS_MODEL_BUY',
        open
    }
}


export const SaleSuccessModalShowHide = (open) => {
    return {
        type: "SALE_SUCCESS_MODAL",
        open
    }
}
export const NFTRemoveConfirmModalShowHide = (open, nftObj) => {
    return {
        type: "NFT_REMOVE_CONFIRM_MODAL",
        open,
        nftObj
    }
}
export const EnterEmailModalAction = (open)=>{
    return {
        type: "ENTER_EMAIL_MODAL",
        open,
    }
}
export const EnterOTPModalAction = (open)=>{
   return {
    type: "ENTER_OTP_MODAL",
    open,
}
}
export const CreateUsernameModalAction = (open)=>{
    return {
        type: "CREATE_USERNAME_MODAL",
        open,
    }
}