import React, { Fragment } from "react"
import "../../css/wallet.css"
import "../../css/detail.css"
import { useDispatch, useSelector } from "react-redux";
import { BuySuccessModalShowHide } from "../../redux/actions/modalActions";
import AvatarDetailImage from "../Detail/AvatarDetailImage"
import tickIcon from "../../images/tick.svg"
import CustomTooltip from "../Common/CutomTooltip"
import twitterIcon from "../../images/twitter-blue.svg"
import fbIcon from "../../images/fb.svg"
import telegramIcon from "../../images/telegram.svg"
import linkIcon from "../../images/link.svg"
import grayCross from "../../images/grayCross.svg"
import blueCross from "../../images/blueCross.svg"
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToasts } from "react-toast-notifications";
import { AVATAR_URL, ITEMS_URL, REAL_STATE_URL } from "../../utils/config";
import RealEstateDetailImage from "./RealEstateDetailImage";
import ItemsDetailImage from "./ItemsDetailImage";
import { getContractURL } from "../../utils/helper";
import { useParams } from "react-router";

const BuySuccessModal = (props) => {
    const { addToast } = useToasts()
    const params = useParams();

    const pageUrl = window.location.href
    const NFT_CONTRACT = params.nftContract
    const NFT_URL = getContractURL(params.nftContract).toLowerCase()
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const showModal = selector.modals.buySuccessModal

    console.log("Selector buy modal", showModal)

    const hideModal = () => {
        dispatch(BuySuccessModalShowHide(false))
        setTimeout(() => {
            window.location.reload()
        }, 1000)

    }


    return (
        <Fragment>
            <div className={`modal fade vertical-scroll ${showModal ? "show" : ""}`} role="dialog">
                <div className="success-box-modal">
                    <div className="modal-content modal-content-box pos-rel text-center">
                        <div className="modal-cancel-btn">
                            <img src={grayCross} alt="cross" className="float-right" />
                            <img src={blueCross} alt="cross" className={`float-right btn-hover `} onClick={hideModal} />
                        </div>
                        <h5 className="font36BoldTxt clr-red orbFont w-75">
                            {props?.messageText ? props?.messageText : "Your purchase has processed!"}
                        </h5>
                        {props?.cardName &&
                            <p className="font24Txt mb-0 mt-10">Woot! You just purchased {props?.cardName}</p>
                        }
                        <div className="modal-image-block">

                            {NFT_URL === REAL_STATE_URL.toLowerCase() &&
                                <RealEstateDetailImage />
                            }

                            {NFT_URL === AVATAR_URL.toLowerCase() &&
                                <AvatarDetailImage />
                            }

                            {NFT_URL === ITEMS_URL.toLowerCase() &&
                                <ItemsDetailImage />
                            }
                            {/*<AvatarDetailImage/>*/}
                            {/* <ItemsDetailImage />
                            <RealEstateDetailImage /> */}
                        </div>
                        <div className="success-status-block">
                            <div>
                                <p className="font16Txt clr-gray mb-10">Status</p>
                                <div className="d-flex">
                                    <img src={tickIcon} alt="tick" className="mr-3" />
                                    <p className="font18Txt mb-0 clr-white">COMPLETE</p>
                                </div>
                            </div>
                            <div>
                                <p className="font16Txt clr-gray mb-10">TRANSACTION HASH</p>
                                <CustomTooltip title={props.hash?.message}>
                                    <p className="font14Txt mb-0 fontOverflowFix  clr-Primary">
                                        <a href={`https://mumbai.polygonscan.com/tx` + '/' + props.hash?.message}
                                            target="_blank">
                                            {props.hash?.message}
                                        </a>
                                    </p>
                                </CustomTooltip>
                            </div>
                        </div>
                        <div className="bdr-btm pb-40" />
                        <p className="font18Txt mb-0 clr-white mt-25">SHARE</p>
                        <div className="modal-icon-grid">

                            <button className="btn br-0 modal-icon-block">
                                <TwitterShareButton
                                    url={pageUrl}
                                    quote="link"
                                    className="Demo__some-network__share-button">
                                    <img src={twitterIcon} alt="twitter"

                                    />
                                </TwitterShareButton>
                            </button>

                            <button className="btn br-0 modal-icon-block">
                                <CopyToClipboard text={`https://mumbai.polygonscan.com/tx` + '/' + props?.hash?.message}
                                    onCopy={() => (
                                        { copied: true },
                                        addToast('Copy successfully', {
                                            appearance: 'success',
                                            autoDismiss: true
                                        })
                                    )}>
                                    <img src={linkIcon} alt="copyIcon" />

                                </CopyToClipboard>
                            </button>
                            <button className="btn br-0 modal-icon-block">
                                <TelegramShareButton
                                    url={pageUrl}
                                    quote="link"
                                    className="Demo__some-network__share-button">
                                    <img src={telegramIcon} alt="twitter"
                                    />
                                </TelegramShareButton>
                            </button>

                            <button className="btn br-0 modal-icon-block">
                                <FacebookShareButton
                                    url={pageUrl}
                                    quote="link"
                                    className="Demo__some-network__share-button">
                                    <img src={fbIcon} alt="twitter"
                                    />
                                </FacebookShareButton>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default BuySuccessModal
