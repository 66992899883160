import React, { Fragment, useState } from 'react'
import CustomSlider from "../../Common/CustomSlider"
import blueBuilding from "../../../images/buildingBlue.svg"
import blueA2Z from "../../../images/a2zBlue.svg"
import SidebarDropdown from '../../Dropdown/SidebarDropdown'
import {useDispatch} from "react-redux";
import {
    AvatarBaseHp,
    AvatarHeathReaganRate,
    RealEstateStatsApartmentNumber,
    RealEstateStatsFloorNumber
} from "../../../redux/actions/actions";
import {Field} from "formik";





const RealEstateStats = () => {

    const dispatch=useDispatch()


    const marks = [
        {
            value: 0,
        },
        {
            value: 1,
        },
        {
            value: 2,
        },
        {
            value: 3,
        },
        {
            value: 4,
        },
        {
            value: 5,
        },
        {
            value: 6,
        },

    ]

    const [nameColor, setNameColor] = useState(false)
    const handleNameColorPick = () => {
        setNameColor(!nameColor)
    }
    const [selected, setSelected] = useState("")

    // const [floorValueStats,setFloorValueStats] = useState('')

    // const statsFloor=(e,value)=>{
    //     setFloorValueStats(value)
    //     let convertNumberValue= Number(value)
    //     let array = [convertNumberValue]
    //     dispatch(RealEstateStatsFloorNumber(array))
    // }


    const [floorValueStats,setFloorValueStats]= useState(5)
    const statsFloor =(e,value)=>{
        console.log('handleSelectBaseHp',value)
        setFloorValueStats(value)
        let convertNumberValue= Number(value)
        let array = [convertNumberValue]
        dispatch(RealEstateStatsFloorNumber(array))

    }





    const resetStatsFloor =()=>{
        dispatch(RealEstateStatsFloorNumber([]))
        setFloorValueStats(0)
    }

    const [apartmentValue,setApartmentValue] = useState('')

    const statsApartment=(e,value)=>{
        setApartmentValue(value)
        let convertNumberValue= Number(value)
        let array = [convertNumberValue]
        dispatch(RealEstateStatsApartmentNumber(array))
    }
    const resetStatsApartment =()=>{
        dispatch(RealEstateStatsApartmentNumber([]))
        setApartmentValue(0)
    }

    return (
        <Fragment>

            <SidebarDropdown selected={selected} setSelected={setSelected} type="real-estate" />
            {selected &&
            <>
                <div className="sidebar-heading mt-40">
                    <div className="d-flex align-items-center">
                        <img src={blueBuilding} alt="heart" />
                        <p className="font12Txt clr-white text-uppercase mb-0 w-100 ml-2">FOOR NUMBER</p>
                    </div>

                    <button className="btn font14Txt clr-Primary" onClick={resetStatsFloor}>Reset</button>
                </div>
                <div className="slider-block mt-2">
                    <CustomSlider
                        aria-label="ios slider"
                        defaultValue={5}
                        min={0}
                        max={6}
                        value={floorValueStats}
                        marks={marks}

                        onChange ={(event, value)=>{
                            statsFloor(event, value)
                        }}
                    />
                </div>
                <div className="sidebar-heading mt-30">
                    <div className="d-flex align-items-center">
                        <img src={blueA2Z} alt="heart" />
                        <p className="font12Txt clr-white text-uppercase mb-0 w-100 ml-2">APARTMENT NUMBER</p>
                    </div>

                    <button className="btn font14Txt clr-Primary" onClick={resetStatsApartment}>Reset</button>
                </div>
                <div className="slider-block mt-2">
                    <CustomSlider
                        aria-label="ios slider"
                        defaultValue={5}
                        min={0}
                        max={6}
                        value={apartmentValue}

                        marks={marks}
                        onChange={(event,value)=>{
                            statsApartment(event,value)
                        }}
                    />
                </div>
            </>
            }
        </Fragment>
    )
}

export default RealEstateStats
