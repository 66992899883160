import React, { Fragment, useCallback, useEffect, useState } from "react"
import "../css/avatar.css"
import "../css/pageNavigator.css"
import "../css/bottomNavbar.css"
import TabHeader from "../component/TabHeader"
import Sidebar from "../component/Sidebar"
import { API_BASE_URL, PRICE_TOKEN_ETH } from "../utils/config";
import axios from "axios";
import DropdownInput from "../component/Dropdown/DropdownInput"
import { useParams } from "react-router";
import { getContractAddressByURL, SORTING_DATA } from "../utils/helper";
import MarketNoDataFound from "../component/MarketPlace/MarketNoDataFound";
import MarketPlaceGridViewItems from "../component/MarketPlace/MarketPlaceGridViewItems";
import MarketPlaceListViewItems from "../component/MarketPlace/MarketPlaceListViewItems"
import NavbarDropdown from "../component/Dropdown/NavbarDropdown"
import { GridViewIcon, LeftArrow, ListViewIcon, RightArrow, TagClearIcon } from "../utils/svgConst"
import { useDispatch, useSelector } from "react-redux";
import {
    AvatarRarityScore,
    AvatarTraitsCount,
    ClearAllFilter,
    AvatarMinMaxEthPrice,
    AvatarBaseHp,
    AvatarHeathReaganRate,
    AvatarBonusToDmg,
    AvatarBonusToDefence,
    RealEstateMinMaxEthPrice,
    AvatarTraitsUserNameColor,
    AvatarTraitsHead,
    AvatarTraitsHeadStyle,
    AvatarTraitsHeadColor,
    AvatarTorsoStyle,
    AvatarTorsoColor,
    AvatarHand,
    AvatarHandStyle,
    AvatarHandColor,
    AvatarWatch,
    AvatarWatchColor,
    RealEstateRarityScore,
    AvatarTorso,
    RealEstateFloorNumber, RealEstateApartmentNumber,
    RealEstateStatsFloorNumber,
    RealEstateStatsApartmentNumber
} from "../redux/actions/actions";
import { elGR } from "@mui/material/locale";


const MarketPlace = () => {
    const dispatch = useDispatch()

    const selector = useSelector(state => state);
    console.log('redux', selector.filterData)

    // value is used for active tabs
    const [value, setValue] = React.useState(0);
    const params = useParams()

    const NFT_URL = params.nftContract
    //console.log('params', NFT_URL)

    const NFT_CONTRACT_ADDRESS = getContractAddressByURL(NFT_URL)
    //console.log("NFT_CONTRACT_ADDRESS", NFT_CONTRACT_ADDRESS)

    const [sortValue, setSortValue] = useState("21")
    const [viewType, setViewType] = useState(1) // 1 used for grid view and 2 used for list view
    const [loading, setLoading] = useState(false)
    const [pageLimit] = useState(10)
    const [pageNo, setPageNo] = useState(1)
    const [maxResult, setMaxResult] = useState(0)
    const [data, setData] = useState([])
    const [noData, setNoData] = useState(false)


    // gettingFilters value from fredux
    const avatarRarity = selector.filterData.avatarRariryScore
    console.log({ avatarRarity })
    const avatarTraitsCount = selector.filterData.avatarTraitsCount
    const ethMinPrice = selector.filterData.avatarMinEthPrice
    const ethMaxPrice = selector.filterData.avatarMaxEthPrice
    const avatarTraitsUserNameColor = selector.filterData.avatarTraitsUserNameColor
    console.log({ avatarTraitsUserNameColor })

    const avatarTraitsHead = selector.filterData.avatarTraitsHead
    const avatarHeadStyle = selector.filterData.avatarTraitsHeadStyle
    const avatarHeadColor = selector.filterData.avatarTraitsHeadColor
    const avatarTorso = selector.filterData.avatarTorso
    const avatarTorsoStyle = selector.filterData.avatarTorsoStyle
    const avatarTorsoColor = selector.filterData.avatarTorsoColor

    const avatarHand = selector.filterData.avatarHand
    const avatarHandStyle = selector.filterData.avatarHandStyle
    const avatarHandColor = selector.filterData.avatarHandColor

    const avatarWatch = selector.filterData.avatarWatch
    const avatarWatchColor = selector.filterData.avatarWatchColor

    const avatarBaseHpValue = selector.filterData.avatarBaseHp
    const avatarHeathReaganRate = selector.filterData.avatarHeathReaganRate
    const avatarBonusToDmg = selector.filterData.avatarBonusToDmg
    const avatarBonusToDefence = selector.filterData.avatarBonusToDefence

    const realEstateRarityScore = selector.filterData.realEstateRarityScore
    const realEstateEthMinPrice = selector.filterData.realEstateMinEthPrice
    const realEstateEthMaxPrice = selector.filterData.realEstateMaxEthPrice

    const realEstateFloor = selector.filterData.realEstateFloorNumber
    const realEstateApartment = selector.filterData.realEstateApartmentNumber

    const realEstateStatsFloor = selector.filterData.realEstateStatsFloorNumber
    const realEstateStatsApartment = selector.filterData.realEstateStatsApartmentNumber


    const firstFilter = selector.filterData.firstFilter

    const maxPage = Math.ceil(maxResult / pageLimit)



    const getTagHtml = (array, type) => array.map(function (key) {
        console.log({ type })

        if (type === 'rarity') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarRarityScore(newArrayReduce))
                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'userNameColor') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarTraitsUserNameColor(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'head') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarTraitsHead(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'headStyle') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarTraitsHeadStyle(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'headColor') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarTraitsHeadColor(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'torsoStyle') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarTorsoStyle(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'torsoColor') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarTorsoColor(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'hand') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarHand(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'handStyle') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarHandStyle(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'handColor') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarHandColor(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'watch') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarWatch(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'watchColor') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(AvatarWatchColor(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'realEsateRarity') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(RealEstateRarityScore(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'floor') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">Floor {key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(RealEstateFloorNumber(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'apartmentNumber') {

            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">Apartment {key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(RealEstateApartmentNumber(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }
        else if (type === 'statsFloor') {
            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">Floor {key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(RealEstateStatsFloorNumber(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }

        else if (type === 'statsApartmentNumber') {

            return <>
                <div className="tag-block">
                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                        <p className="font16txt mb-0 tagFontOverflowFix">Apartment {key}</p>
                        <button className="btn ml-auto"
                            onClick={() => {
                                let newArrayReduce = array.reduce((acc, curr) => {
                                    if (curr !== key) acc.push(curr);
                                    return acc;
                                }, []);
                                dispatch(RealEstateStatsApartmentNumber(newArrayReduce))

                            }}>
                            <TagClearIcon />
                        </button>
                    </div>
                </div>
            </>
        }

    })


    const deleteEthPrice = (ethMinPrice, ethMaxPrice) => {
        if (ethMinPrice) {
            dispatch(AvatarMinMaxEthPrice(''))
        }
        else {
            dispatch(AvatarMinMaxEthPrice(''))
        }

    }


    const mapFilterFormating = (array) => array.map(function (key) {
        if (typeof key == "number") {
            return key
        }
        else {
            return `"${key}"`
        }
        // return `"${key}"`

    }).join(',').toUpperCase();

    const createQuery = (a, b, c) => {
        let queryValue = `&attributes[]={"attribute": "${a}","value": [${b}]}`
        let returnValue = c.length > 0 ? queryValue : ''
        return returnValue
    }

    const clearAll = () => {
        console.log("call")
        dispatch(ClearAllFilter())
        // window.location.reload()
    }


    const filterType = selector.filterData.typeAvatarRariryScore
    const filterTypeuserNameColor = selector.filterData.typeAvatarTraitsUserNameColor
    const filterTypeHead = selector.filterData.typeAvatarTraitsHead
    const filterTypeHeadStyle = selector.filterData.typeAvatarTraitsHeadStyle
    const filterTypeHeadColor = selector.filterData.typeAvatarTraitsHeadColor

    const filterTypeTorso = selector.filterData.typeAvatarTorso
    const filterTypeTorsoStyle = selector.filterData.typeTorsoStyle
    const filterTypeTorsoColor = selector.filterData.typeTorsoColor
    const filterTypeHand = selector.filterData.typeAvatarHand
    const filterTypeHandStyle = selector.filterData.typeAvatarHandStyle
    const filterTypeHandColor = selector.filterData.typeAvatarHandColor
    const filterTypeWatch = selector.filterData.typeAvatarWatch
    const filterTypeWatchColor = selector.filterData.typeAvatarWatchColor
    const filterTypeRealEstateRarityScore = selector.filterData.typeRealEstateRarityScore
    const filterTypeBaseHp = selector.filterData.typeAvatarBaseHp
    const filterTypeHeathReaganRate = selector.filterData.typeHeathReaganRate
    const filterTypeBonusToDmg = selector.filterData.typeBonusToDmg
    const filterTypeBonusToDefence = selector.filterData.typeBonusToDefence
    const filterTypeRealEstateFloor = selector.filterData.typeRealEstateFloorNumber
    const filterTypeRealEstateApartment = selector.filterData.typeRealEstateApartmentNumber

    const filterTypeRealEstateStatsFloor = selector.filterData.typeRealEstateStatsFloorNumber
    const filterTypeRealEstateStatsApartment = selector.filterData.typeRealEstateStatsApartmentNumber





    const [totalFilterCount,setTotalFilterCount] = useState([])



    const getSecondaryListedSale = useCallback(() => {


        // this line is for checking total count of selected filter for showing clear all button
       let totalC= avatarRarity+avatarTraitsCount+ ethMinPrice+ethMaxPrice+avatarTraitsUserNameColor+
            avatarTraitsHead+ avatarHeadStyle+avatarHeadColor+ avatarTorso+
            avatarTorsoStyle+ avatarTorsoColor+ avatarHand+ avatarHandStyle+
            avatarHandColor+ avatarWatch+ avatarWatchColor+ realEstateRarityScore+
            realEstateEthMinPrice+ realEstateEthMaxPrice+ realEstateFloor+
            realEstateApartment+ avatarBaseHpValue+avatarHeathReaganRate+ avatarBonusToDmg+ avatarBonusToDefence+
            +realEstateStatsFloor + realEstateStatsApartment

        if(totalC === '00'){
            setTotalFilterCount(0)
        }
        else if(totalC === '0undefinedundefined0'){
            setTotalFilterCount(0)
        }
        else {
            setTotalFilterCount(totalC)
        }
        console.log('totalC',typeof totalC)
        console.log('totalCttt',totalC)
        console.log('totalCttt length',totalC.length)


        setLoading(true)

        //console.log("Api call with sort values", sortValue)
        //setting all required params
        const nftContract = "?nftContracts[]=" + PRICE_TOKEN_ETH + "&nftContracts[]=" + NFT_CONTRACT_ADDRESS;
        // const nftContract = "?nftContracts[]=" + PRICE_TOKEN_ETH + ',' + NFT_CONTRACT_ADDRESS;


        // const nftContract = "?nftContract=" + NFT_CONTRACT_ADDRESS;
        const limit = "&limit=" + pageLimit;
        const offset = "&skip=" + (pageNo === 1 ? 0 : ((pageNo - 1) * pageLimit))

        //sort by case with orderby and order
        const sortSplitValues = sortValue ? sortValue.toString().split("") : ""
        //console.log({sortSplitValues})
        const orderBy = sortSplitValues.length > 0 ? "&orderBy=" + sortSplitValues[0] : "&orderBy=2";
        const order = sortSplitValues.length > 0 ? "&order=" + sortSplitValues[1] : "&order=1";

        //side bar filters getting type (we can past it outside this callback function)


        const avatarRarityFilter = selector.filterData.avatarRariryScore
        const avatarTraitsCountApiData = avatarTraitsCount ? `&traitCount=${avatarTraitsCount}` : ''
        const ethMin = ethMinPrice ? `&minPrice=${ethMinPrice}` : ''
        const ethMax = ethMaxPrice ? `&maxPrice=${ethMaxPrice}` : ''
        const minMaxEthAPiData = ethMin + ethMax

        const realEstateEthMinPriceData = realEstateEthMinPrice ? `&minPrice=${realEstateEthMinPrice}` : ''
        const realEstateEthMaxPriceData = realEstateEthMaxPrice ? `&maxPrice=${realEstateEthMaxPrice}` : ''
        const realEstateMinMaxEthAPiData = realEstateEthMinPriceData + realEstateEthMaxPriceData




        // avatar traits username color api data
        let formateDataTraitsUserNameColor = mapFilterFormating(avatarTraitsUserNameColor)
        const userNameColor = createQuery(filterTypeuserNameColor, formateDataTraitsUserNameColor, avatarTraitsUserNameColor)



        // avatar rarity score api data
        let formateData = mapFilterFormating(avatarRarityFilter)
        let rarity = createQuery(filterType, formateData, avatarRarityFilter)
        const rarityDataAPiData = avatarRarityFilter.length > 0 ? rarity : ''


        //avatarTraitsHead
        let formateDataHead = mapFilterFormating(avatarTraitsHead)
        const avatarTraitsHeadData = createQuery(filterTypeHead, formateDataHead, avatarTraitsHead)


        //avatarHeadStyle
        let formateDataHeadStyle = mapFilterFormating(avatarHeadStyle)
        const avatarTraitsHeadStyleData = createQuery(filterTypeHeadStyle, formateDataHeadStyle, avatarHeadStyle)


        //avatarHeadColor
        let formateDataHeadColor = mapFilterFormating(avatarHeadColor)
        const avatarHeadColorData = createQuery(filterTypeHeadColor, formateDataHeadColor, avatarHeadColor)


        // //torso avatarTorso
        let avatarTorsoStr = `"${avatarTorso}"`
        const avatarTorsoData = createQuery(filterTypeTorso, avatarTorsoStr, avatarTorso)


        let formateDatatorsoStyle = mapFilterFormating(avatarTorsoStyle)
        const avatarTorsoStyleData = createQuery(filterTypeTorsoStyle, formateDatatorsoStyle, avatarTorsoStyle)


        let formateDatatorsoColor = mapFilterFormating(avatarTorsoColor)
        const avatarTorsoColorData = createQuery(filterTypeTorsoColor, formateDatatorsoColor, avatarTorsoColor)



        //avatarHand

        let formateDataHand = mapFilterFormating(avatarHand)
        const avatarTraitsHandData = createQuery(filterTypeHand, formateDataHand, avatarHand)




        //avatarHandStyle
        let formateDataHandStyle = mapFilterFormating(avatarHandStyle)
        const avatarTraitsHandStyleData = createQuery(filterTypeHandStyle, formateDataHandStyle, avatarHandStyle)


        //avatarHandColor
        let formateDataHandColor = mapFilterFormating(avatarHandColor)
        const avatarTraitsHandColorData = createQuery(filterTypeHandColor, formateDataHandColor, avatarHandColor)


        //avatarWatch
        let formateDataWatch = mapFilterFormating(avatarWatch)
        const avatarWatchData = createQuery(filterTypeWatch, formateDataWatch, avatarWatch)

        //avatarWatchColor
        let formateDataWatchColor = mapFilterFormating(avatarWatchColor)
        const avatarWatchColorData = createQuery(filterTypeWatchColor, formateDataWatchColor, avatarWatchColor)

        //realEstateRarityScore
        let formateDataRealEstateScore = mapFilterFormating(realEstateRarityScore)
        const realEstateScoreData = createQuery(filterTypeRealEstateRarityScore, formateDataRealEstateScore, realEstateRarityScore)


        //avatar stats:
        //avatarBaseHpValue,avatarHeathReaganRate,avatarBonusToDmg,avatarBonusToDefence
        const formateBaseHpDataBaseHp = mapFilterFormating(avatarBaseHpValue)
        const avatarBaseHpApiData = createQuery(filterTypeBaseHp, formateBaseHpDataBaseHp, avatarBaseHpValue)

        const formateHeathReaganRateData = mapFilterFormating(avatarHeathReaganRate)
        const avatarHeathReaganRateApiData = createQuery(filterTypeHeathReaganRate, formateHeathReaganRateData, avatarHeathReaganRate)

        const formateBonusToDmg = mapFilterFormating(avatarBonusToDmg)
        const avatarBonusToDmgApiData = createQuery(filterTypeBonusToDmg, formateBonusToDmg, avatarBonusToDmg)

        const formateBonusToDefence = mapFilterFormating(avatarBonusToDefence)
        const avatarBonusToDefenceApiData = createQuery(filterTypeBonusToDefence, formateBonusToDefence, avatarBonusToDefence)

        //real estate floor
        const formateRealEstateFloorData = mapFilterFormating(realEstateFloor)
        const realEstateFloorDataApiData = createQuery(filterTypeRealEstateFloor, formateRealEstateFloorData, realEstateFloor)


        //real estate apartment
        const formateRealEstateApartmentNumberData = mapFilterFormating(realEstateApartment)
        const realEstateApartmentNumberDataApiData = createQuery(filterTypeRealEstateApartment, formateRealEstateApartmentNumberData, realEstateApartment)


        //real estate stats floor realEstateStatsFloor +realEstateStatsApartment
        const formateRealEstateStatsFloorData = mapFilterFormating(realEstateStatsFloor)
        const realEstateStatsFloorApiData = createQuery(filterTypeRealEstateStatsFloor, formateRealEstateStatsFloorData, realEstateStatsFloor)

        const formateRealEstateStatsApartmentData = mapFilterFormating(realEstateStatsApartment)
        const realEstateStatsApartmentApiData = createQuery(filterTypeRealEstateStatsApartment, formateRealEstateStatsApartmentData, realEstateStatsApartment)

        //real estate stats apartment


        // this both line is for testing
        // let test = '&attributes[]={"attribute": "rarity","value": ["GRAIL", "EPIC"]}'
        // const testingUrl = '/marketplace/secondary/listed?nftContracts[]=0xc0ce2e866f61c4bc777d2dd7ce7c9e81078dc207&limit=10&skip=0&orderBy=2&order=1&attributes[]={"attribute": "rarity","value": ["GRAIL", "EPIC"]}&attributes[]={"attribute": "mask","value": ["MASK 13"]}'


        //adding all required params in one variable
        const params = nftContract + limit + offset + orderBy + order + rarity + avatarTraitsCountApiData
            + minMaxEthAPiData +
            userNameColor + avatarTraitsHeadData + avatarTraitsHeadStyleData +
            avatarHeadColorData + avatarTorsoData + avatarTorsoStyleData + avatarTorsoColorData +
            avatarTraitsHandData + avatarTraitsHandStyleData + avatarTraitsHandColorData +
            avatarWatchData + avatarWatchColorData + realEstateScoreData + realEstateMinMaxEthAPiData
            + avatarBaseHpApiData + avatarHeathReaganRateApiData + avatarBonusToDmgApiData + avatarBonusToDefenceApiData
            + realEstateFloorDataApiData + realEstateApartmentNumberDataApiData
            + realEstateStatsFloorApiData + realEstateStatsApartmentApiData

        //api call to get results
        // this line is for testing
        // axios.get(API_BASE_URL + testingUrl)

        axios.get(API_BASE_URL + "marketplace/secondary/listed" + params)
            .then((res) => {
                setMaxResult(res.data.totalListedSales)
                setData(res.data.listedSales)
                if (res.data.totalListedSales === "0") {
                    setNoData(true)
                } else {
                    setNoData(false)
                }
            }).catch((err) => {
                setData([])
                setNoData(true)
                setMaxResult(0)
            }).finally(() => setLoading(false))
    }, [pageNo, value, sortValue, NFT_CONTRACT_ADDRESS, avatarRarity, avatarTraitsCount, ethMinPrice, ethMaxPrice, avatarTraitsUserNameColor,
        avatarTraitsHead, avatarHeadStyle, avatarHeadColor, avatarTorso,
        avatarTorsoStyle, avatarTorsoColor, avatarHand, avatarHandStyle,
        avatarHandColor, avatarWatch, avatarWatchColor, realEstateRarityScore,
        realEstateEthMinPrice, realEstateEthMaxPrice, realEstateFloor,
        realEstateApartment, avatarBaseHpValue, avatarHeathReaganRate, avatarBonusToDmg, avatarBonusToDefence,
        +realEstateStatsFloor + realEstateStatsApartment])


    useEffect(() => {
        getSecondaryListedSale()
    }, [getSecondaryListedSale])

    const handlePageChange = (type) => {
        if (type === "INC") {
            //when max page is matched with current page no then dont call any api
            if (pageNo >= maxPage) return
            setPageNo(prevState => prevState + 1)
        } else if (type === "DEC") {
            if (pageNo === 1) return
            setPageNo(prevState => prevState - 1)
        } else {
            //console.log("type not matched")
        }
    }


    const handleSortChange = (selectedSort) => {
        debugger
        setSortValue(selectedSort)
    }




    return (
        <Fragment>
            <TabHeader />
            <div className="darkModeBg">
                <div className="avatar-grid">
                    <Sidebar />
                    <div className="avatar-detail">
                        <div className="bottom-navbar">
                            <div className="left-menu">
                                <p className="mb-0 mr-3 d-flex">
                                    <span className="font36BoldTxt clr-red orbFont mr-2">{maxResult}</span>
                                    <span
                                        className="font36Txt clr-red orbFont"> {NFT_URL === "real-estate" ? 'Real estate' : NFT_URL === 'avatar' ? 'Avatar' : NFT_URL === 'items' ? " Item" : ""}</span>
                                </p>
                                <div className="dropdown-width-fix">
                                    {/*<NavbarDropdown*/}
                                    {/*    data={SORTING_DATA}*/}
                                    {/*    selected={sortValue}*/}
                                    {/*    defaultOption={"Sort By"}*/}
                                    {/*    handleChangeSelection={handleSortChange}*/}

                                    {/*/>*/}
                                    {/* <DropdownInput
                                        data={SORTING_DATA}
                                        selected={sortValue}
                                        defaultOption={"Sort By"}
                                        handleChangeSelection={handleSortChange}/>*/}
                                </div>
                            </div>

                            <div className="right-menu">
                                <div className="dropdown-width-fix">
                                    <NavbarDropdown
                                        data={SORTING_DATA}
                                        selected={sortValue}
                                        defaultOption={"Sort By"}
                                        handleChangeSelection={handleSortChange}

                                    />
                                </div>
                                <div className="viewBtn-block">
                                    <button
                                        className={`btn br-0 gridBtn ${viewType === 1 ? "blueBtn lightBlueBtn" : "clr-gray"}`}
                                        onClick={() => setViewType(1)}
                                    >
                                        <GridViewIcon />
                                    </button>
                                    <button
                                        className={`btn br-0 gridBtn ${viewType === 2 ? "blueBtn lightBlueBtn" : "clr-gray"}`}
                                        onClick={() => setViewType(2)}
                                    >
                                        <ListViewIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="tag-section">


                            {/*rarity*/}

                            {/*{avatarRarity && avatarRarity.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarRarity,key,filterType)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}

                            {/*{avatarTraitsUserNameColor && avatarTraitsUserNameColor.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarTraitsUserNameColor,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}

                            {/*}*/}

                            {/*{avatarTraitsHead && avatarTraitsHead.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarTraitsHead,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}
                            {/*{avatarHeadStyle && avatarHeadStyle.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarHeadStyle,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}
                            {/*{avatarHeadColor && avatarHeadColor.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarHeadColor,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}
                            {/*{avatarTorsoStyle && avatarTorsoStyle.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarTorsoStyle,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}
                            {/*{avatarTorsoColor && avatarTorsoColor.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarTorsoColor,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}
                            {/*{avatarHand && avatarHand.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarHand,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}

                            {/*{avatarHandStyle && avatarHandStyle.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarHandStyle,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}

                            {/*{avatarHandColor && avatarTorsoColor.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarHandColor,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}

                            {/*{avatarWatch && avatarWatch.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarWatch,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}

                            {/*{avatarWatchColor && avatarWatchColor.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(avatarWatchColor,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}

                            {/*{realEstateRarityScore && realEstateRarityScore.map((key)=>{*/}
                            {/*    return <>*/}
                            {/*        <div className="tag-block">*/}
                            {/*            <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                <button className="btn ml-auto"onClick={()=>{*/}
                            {/*                    deleteTag(realEstateRarityScore,key)*/}
                            {/*                }}>*/}
                            {/*                    <TagClearIcon />*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </>*/}
                            {/*})*/}
                            {/*}*/}






                            {getTagHtml(avatarRarity, filterType)}
                            {getTagHtml(avatarTraitsUserNameColor, filterTypeuserNameColor)}
                            {getTagHtml(avatarTraitsHead, filterTypeHead)}
                            {getTagHtml(avatarHeadStyle, filterTypeHeadStyle)}
                            {getTagHtml(avatarHeadColor, filterTypeHeadColor)}
                            {getTagHtml(avatarTorsoStyle, filterTypeTorsoStyle)}
                            {getTagHtml(avatarTorsoColor, filterTypeTorsoColor)}
                            {getTagHtml(avatarHand, filterTypeHand)}
                            {getTagHtml(avatarHandStyle, filterTypeHandStyle)}
                            {getTagHtml(avatarHandColor, filterTypeHandColor)}
                            {getTagHtml(avatarWatch, filterTypeWatch)}
                            {getTagHtml(avatarWatchColor, filterTypeWatchColor)}
                            {getTagHtml(realEstateRarityScore, filterTypeRealEstateRarityScore)}
                            {getTagHtml(realEstateFloor, filterTypeRealEstateFloor)}
                            {getTagHtml(realEstateApartment, filterTypeRealEstateApartment)}
                            {getTagHtml(realEstateStatsFloor, filterTypeRealEstateStatsFloor)}
                            {getTagHtml(realEstateStatsApartment, filterTypeRealEstateStatsApartment)}


                            {/*RealEstateStatsFloorNumber*/}


                            {/*{getSimpleHtml(firstFilter)}*/}



                            {avatarTorso == 0 ? '' :
                                <div className="tag-block">
                                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                                        <p className="font16txt mb-0 tagFontOverflowFix">{avatarTorso}</p>
                                        <button className="btn ml-auto" onClick={() => {
                                            dispatch(AvatarTorso(''))
                                        }}>
                                            <TagClearIcon />
                                        </button>
                                    </div>
                                </div>
                            }

                            {avatarTraitsCount == 0 ? '' :
                                <div className="tag-block">
                                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                                        <p className="font16txt mb-0 tagFontOverflowFix">Trait count {avatarTraitsCount}</p>
                                        <button className="btn ml-auto" onClick={() => {
                                            dispatch(AvatarTraitsCount(''))
                                        }}>
                                            <TagClearIcon />
                                        </button>
                                    </div>
                                </div>
                            }

                            {ethMinPrice == 0 & ethMaxPrice == 0 ? '' : ethMinPrice || ethMaxPrice ?
                                <div className="tag-block">
                                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                                        <p className="font16txt mb-0 tagFontOverflowFix">Price&nbsp; {ethMinPrice ? ethMinPrice : '0'} {ethMaxPrice ? 'to' : ''} {ethMaxPrice}</p>
                                        <button className="btn ml-auto" onClick={() => {
                                            deleteEthPrice(ethMinPrice, ethMaxPrice)
                                        }}>
                                            <TagClearIcon />
                                        </button>
                                    </div>
                                </div>
                                : ''
                            }



                            {avatarBaseHpValue == 0 ? '' :
                                <div className="tag-block">
                                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                                        <p className="font16txt mb-0 tagFontOverflowFix">baseHp&nbsp; {avatarBaseHpValue}</p>
                                        <button className="btn ml-auto" onClick={() => {
                                            dispatch(AvatarBaseHp([]))
                                        }}>
                                            <TagClearIcon />
                                        </button>
                                    </div>
                                </div>
                            }
                            {avatarHeathReaganRate == 0 ? '' :
                                <div className="tag-block">
                                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                                        <p className="font16txt mb-0 tagFontOverflowFix" onClick={AvatarHeathReaganRate('')}>ReaganRate&nbsp; {avatarHeathReaganRate}</p>
                                        <button className="btn ml-auto" onClick={() => {
                                            dispatch(AvatarHeathReaganRate([]))
                                        }}>
                                            <TagClearIcon />
                                        </button>
                                    </div>
                                </div>
                            }
                            {avatarBonusToDmg == 0 ? '' :
                                <div className="tag-block">
                                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                                        <p className="font16txt mb-0 tagFontOverflowFix">Dmg&nbsp; {avatarBonusToDmg}</p>
                                        <button className="btn ml-auto" onClick={() => {
                                            dispatch(AvatarBonusToDmg([]))
                                        }}>
                                            <TagClearIcon />
                                        </button>
                                    </div>
                                </div>
                            }
                            {avatarBonusToDefence == 0 ? '' :
                                <div className="tag-block">
                                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                                        <p className="font16txt mb-0 tagFontOverflowFix">Defence&nbsp; {avatarBonusToDefence}</p>
                                        <button className="btn ml-auto" onClick={() => {
                                            dispatch(AvatarBonusToDefence([]))
                                        }}>
                                            <TagClearIcon />
                                        </button>
                                    </div>
                                </div>
                            }


                            {realEstateEthMinPrice == 0 & realEstateEthMaxPrice == 0 ? '' : realEstateEthMinPrice || realEstateEthMaxPrice ?
                                <div className="tag-block">
                                    <div className="pos-rel d-flex align-items-center px-2 pl-3">
                                        <p className="font16txt mb-0 tagFontOverflowFix">Price&nbsp; {realEstateEthMinPrice ? realEstateEthMinPrice : '0'} {realEstateEthMaxPrice ? 'to' : ''} {realEstateEthMaxPrice}</p>
                                        <button className="btn ml-auto" onClick={() => {
                                            dispatch(RealEstateMinMaxEthPrice(''))
                                        }}>
                                            <TagClearIcon />
                                        </button>
                                    </div>
                                </div>
                                : ''
                            }





                            {/*{avatarRarity && avatarRarity.length>0 && avatarRarity.map((key)=>{*/}
                            {/*    return (*/}
                            {/*        <>*/}
                            {/*            <div className="tag-block">*/}
                            {/*                <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                    <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                    <button className="btn ml-auto">*/}
                            {/*                        <TagClearIcon />*/}
                            {/*                    </button>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </>*/}
                            {/*    )*/}
                            {/*})}*/}
                            {/*{avatarTraitsUserNameColor && avatarTraitsUserNameColor.length>0 && avatarTraitsUserNameColor.map((key)=>{*/}
                            {/*    return (*/}
                            {/*        <>*/}
                            {/*            <div className="tag-block">*/}
                            {/*                <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*                    <p className="font16txt mb-0 tagFontOverflowFix">{key}</p>*/}
                            {/*                    <button className="btn ml-auto">*/}
                            {/*                        <TagClearIcon />*/}
                            {/*                    </button>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </>*/}
                            {/*    )*/}
                            {/*})}*/}

                            {/*{avatarTorso &&*/}
                            {/*<div className="tag-block">*/}
                            {/*    <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*        <p className="font16txt mb-0 tagFontOverflowFix">{avatarTorso}</p>*/}
                            {/*        <button className="btn ml-auto">*/}
                            {/*            <TagClearIcon />*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*}*/}
                            {/*{firstFilter &&*/}
                            {/*<div className="tag-block">*/}
                            {/*    <div className="pos-rel d-flex align-items-center px-2 pl-3">*/}
                            {/*        <p className="font16txt mb-0 tagFontOverflowFix">{firstFilter}</p>*/}
                            {/*        <button className="btn ml-auto">*/}
                            {/*            <TagClearIcon />*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*}*/}

                            {totalFilterCount.length>1 ? <button className="btn clr-primary font12Txt blueHoverBtn" onClick={clearAll}>Clear All</button>
                                :''}

                        </div>



                        <MarketPlaceGridViewItems
                            viewType={viewType}
                            data={data}
                            loading={loading}
                        />

                        <MarketPlaceListViewItems
                            viewType={viewType}
                            data={data}
                            loading={loading}
                        />


                        {!loading && data.length > 0 &&
                            <div className="pageNavigator">
                                <button className="btn" onClick={() => handlePageChange("DEC")}>
                                    <LeftArrow className={`${pageNo > 1 ? "arrow-blue" : "arrow-gray"}`} />
                                </button>
                                <p className="page--txt">Page</p>
                                <p className="page--count">{pageNo}</p>
                                <p className="page--txt">of &nbsp; {maxPage}</p>
                                <button className="btn" onClick={() => handlePageChange("INC")}>
                                    <RightArrow className={`${pageNo < maxPage ? "arrow-blue" : "arrow-gray"}`} />
                                </button>
                            </div>
                        }


                        <MarketNoDataFound
                            loading={loading}
                            data={data}
                            noData={noData}
                        />


                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MarketPlace
