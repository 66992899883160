import React, {Fragment, useEffect, useState} from 'react'
import PersonIcon from '@mui/icons-material/Person';
import CardImage from "../../images/cardImage.svg"
import {useDispatch, useSelector} from "react-redux";
import {EditPriceModal, NFTRemoveConfirmModalShowHide} from "../../redux/actions/modalActions";
import {getUSDPrice,getContractBasedName} from '../../utils/helper'
import {Link} from "react-router-dom";
import NFTRemoveConfirmModal from "../MyWallet/WalletRemoveListModal";
import {AVATAR_ADDRESS, REAL_STATE_ADDRESS} from "../../utils/config";

const AvatarMarketplaceImage = (props) => {

    const dispatch = useDispatch()
    const [data, setData] = useState([])

    const selector = useSelector(state => state);

    useEffect(() => {
        if (props.collectorData) {
            setData(props.collectorData)
        }
    }, [props.collectorData])

    const handleOpenEditModal = (nftInfo) => {
        dispatch(EditPriceModal(true, nftInfo))
    }

    const handleOpenNFTRemoveConfirmModal = (nftObj) => {
        dispatch(NFTRemoveConfirmModalShowHide(true, nftObj))
    }


    return (

        <Fragment>
            {data && data.length > 0 && data.map((key, index) => {

                return (
                    <Fragment key={key?.saleId}>
                        <div>
                            <Link to={"/detail/" + (key?.nftContract).toLowerCase() + "/" + key?.nftId}>
                                <div className="marketCard">

                                    <div className="profile-card profile-card-orange">
                                        <div className="pos-rel ml-2">
                                            <PersonIcon className="clr-orange"/>
                                            <p className="clr-orange font12BoldTxt ml-2 mb-0 d-ib">{key.nftId}</p>
                                        </div>
                                    </div>
                                    <div className="card-grayBG">
                                        <p className="font18Txt mt-2 text-center clr-Primary">
                                            {getContractBasedName((key.nftContract).toLowerCase())}

                                        </p>
                                        <div
                                            className={`text-center ${props.type !== "collected" ? 'Avatar_image_block' : 'CardImageFix'}`}>
                                            <img src={key?.image ? key.image : CardImage} alt="cardImage"
                                                 className="avatarThumb"/>
                                        </div>
                                        {props.type !== "collected" &&
                                        <Fragment>
                                            <p className="font21BoldTxt text-center mb-0 mt-4 clr-white">Ξ {key?.price ? key?.price : "0"}</p>
                                            <p className="font18Txt text-center mb-0 clr-gray">$ {getUSDPrice(key?.price, selector.ethPrice.price)}</p>
                                        </Fragment>
                                        }
                                    </div>
                                </div>
                            </Link>
                            {props.forSale &&
                            <Fragment>
                                <div className="wallet-sale-btn-group">
                                    <button className="btn br-0 removeCustomBtn"
                                            onClick={() => handleOpenNFTRemoveConfirmModal(key)}>
                                        <div className="pos-rel">
                                            <p className="mb-0 font12Txt orbFont clr-Primary">remove</p>
                                        </div>
                                    </button>
                                    <button className="btn br-0 editCustomBtn" onClick={() => handleOpenEditModal(key)}>
                                        <div className="pos-rel">
                                            <p className="mb-0 font12Txt orbFont clr-Primary">edit</p>
                                        </div>
                                    </button>
                                </div>
                            </Fragment>


                            }
                        </div>


                    </Fragment>
                )
            })
            }

            <NFTRemoveConfirmModal/>
        </Fragment>


    )
}


export default AvatarMarketplaceImage