import React, { Fragment, useState } from 'react';
import "../../css/sidebar.css"
import arrow from "../../images/dropdownArrow.svg"
import HandleClickOutside from '../../utils/helper';
import Select from "@mui/material/Select";


const NavbarDropdown = (props) => {

    const [data, setData] = useState(props?.data)
    const [selected, setSelected] = useState(props.selected ? props.selected : '')
    const [isOpen, setIsOpen] = useState(false)

    const ref = HandleClickOutside(() => {
        setIsOpen(false)
    })

    const [value, setValue] = useState(props?.selected ? props?.selected : "");

    const handleToggleBox = (e) => {
        setIsOpen(!isOpen)

    };
    const changeInput = (key) => {

        setValue(key?.value);
        setSelected(key?.value);
        setIsOpen(false)
        if (props?.handleChangeSelection) {
            props?.handleChangeSelection(key?.value)
        }
    }

    const getNameBySelected = (selectedValue) => {

        const filterdData = data.find((key) => {
            return key.value === selectedValue
        })

        return filterdData?.name


    }


    return (
        <div ref={ref} className='pos-rel'>
            <button
                className={`btn clr-Primary customTraitPadding dropdown-css br-0 Clr-Btn font16Txt customNavDropdown btnHover ${props.className}
                ${isOpen ? ' traitCustomBtn blueDropdownBG' : ''}`}
                onClick={handleToggleBox}>{selected ? getNameBySelected(selected) : 'Select'}
                <img src={arrow} alt="arrow"
                    className={`float-right ml-auto ${isOpen ? 'rotateImage' : 'rotateBack'}`} />
            </button>


            <div className={`${isOpen ? 'navbar-dropdown-wrapper absolute-box' : 'd-none'} `}>
                {data.map((key) => {
                    return (
                        <Fragment>
                            <button className="btn navbar--dropdown"
                                onClick={() => {
                                    changeInput(key)
                                }}>
                                <p className="mb-0 font14Txt clr-white">{key.name}</p>
                            </button>
                        </Fragment>
                    )
                })}

            </div>
        </div>
    )
};

export default NavbarDropdown;
