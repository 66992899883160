import React, { useState } from 'react'
import noItemListed from "../../images/noItemsListed.svg"
import noItemSold from '../../images/noItemsSold.svg'

const DashboardNoItemsFound = (props) => {

    const [type, setType] = useState(props?.type)

    return (
        <>
            <div className="no-item-found mt-3">
                <img src={type === "listed" ? noItemListed : noItemSold}
                    alt="no Item Listed" />
            </div>
        </>
    )
}

export default DashboardNoItemsFound
