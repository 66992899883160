import React, { Fragment, useEffect, useState } from 'react'
import "../../css/dashboard.css"
import "../../css/marketCard.css"
import thumb from "../../images/cardImage.svg"
import PersonIcon from '@mui/icons-material/Person';
import CustomTooltip from "../Common/CutomTooltip";
import { useSelector } from "react-redux";
import { getContractBasedName, getUSDPrice } from '../../utils/helper'
import { HeartIcon, HeartbeatIcon, PunchIcon, ShieldIcon } from '../../utils/svgConst';
import { getRarityClass, getRarityText } from '../../utils/helper'
import { AVATAR_ADDRESS, REAL_STATE_ADDRESS } from "../../utils/config";
import heartIcon from "../../images/heart.svg";
import heartbeatIcon from "../../images/heartbeat.svg";
import punchIcon from "../../images/punch.svg";
import shieldIcon from "../../images/shield.svg";


const MarketListViewCard = (props) => {

    const [data, setData] = useState([])

    const selector = useSelector(state => state);

    useEffect(() => {
        setData(props?.data)
    }, [props])

    const getIcon = (value)=>{
        if(value==='baseHP'){
            return heartIcon
        }
         else if(value==='healthReganRate'){
            return heartbeatIcon
        }
        else if(value==='bonusToDMG'){
            return punchIcon
        }
        else if(value==='bonusToDefence'){
            return shieldIcon
        }

    }

    return (
        data && data.length > 0 && data.map((key, index) => {

            let filterDataForTypeThree = props?.data[index].attributes.filter((key)=>{
                return key.type===3
            })

            return (
                <Fragment key={key?.saleId}>
                    <div className="listed-market-block-item marketListViewBlock">
                        <div className="marketListViewImgBlock">
                            <img src={key?.image ? key.image : thumb} alt="cardImage"
                                className="list-thumb-icon" />
                            {/* <img src={thumb} alt="thumb" className="" /> */}
                        </div>

                        <div>

                            <div className={`profileID ${getRarityClass(key.rarity)}`}>
                                <div className="pos-rel">
                                    {/* <PersonIcon className={`${getRarityText(key.rarity)}`} /> */}
                                    <p className={`font14Txt ml-2 mb-0 d-ib ${getRarityText(key.rarity)}`}>{key?.rarity}</p>
                                </div>
                            </div>
                            <p className="font18Txt mt-2 mb-0 text-center clr-white">
                                {getContractBasedName((key.nftContract).toLowerCase())}
                            </p>

                        </div>

                        <div className="stats-block">

                            {filterDataForTypeThree&& filterDataForTypeThree.length > 0 && filterDataForTypeThree.map((key, index) => {
                                return (
                                    <Fragment key={index}>
                                        {/*{key?.attribute === "baseHP" && key?.type === 3 &&*/}
                                        <div className="stats-icons">
                                            <p className="font12Txt clr-gray ">{key?.attribute}</p>
                                            <img src={getIcon(key?.attribute)} alt="heart" />
                                            <p className="mb-0 font14Txt mt-1 ml-2 d-ib">{key?.value}</p>
                                        </div>
                                        {/*}*/}
                                    </Fragment>
                                )
                            })
                            }


                            {/*{key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {*/}
                            {/*    return (*/}
                            {/*        <Fragment key={index}>*/}
                            {/*            {key?.attribute === "baseHP" && key?.type === 3 &&*/}
                            {/*                <div className="stats-icons">*/}
                            {/*                    <p className="font12Txt clr-gray ">Base HP</p>*/}
                            {/*                    <img src={heartIcon} alt="heart" />*/}
                            {/*                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">{key?.value}</p>*/}
                            {/*                </div>*/}
                            {/*            }*/}
                            {/*        </Fragment>*/}
                            {/*    )*/}
                            {/*})*/}
                            {/*}*/}
                            {/*{key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {*/}
                            {/*    return (*/}
                            {/*        <Fragment key={index}>*/}
                            {/*            {key?.attribute === "healthReganRate" && key?.type === 3 &&*/}
                            {/*                <div className="stats-icons">*/}
                            {/*                    <p className="font12Txt clr-gray ">HEALTH REGEN</p>*/}
                            {/*                    <img src={heartbeatIcon} alt="heart" />*/}
                            {/*                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">{key?.value}</p>*/}
                            {/*                </div>*/}
                            {/*            }*/}
                            {/*        </Fragment>*/}
                            {/*    )*/}
                            {/*})*/}
                            {/*}*/}
                            {/*{key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {*/}
                            {/*    return (*/}
                            {/*        <Fragment key={index}>*/}
                            {/*            {key?.attribute === "bonusToDMG" && key?.type === 3 &&*/}
                            {/*                <div className="stats-icons">*/}
                            {/*                    <p className="font12Txt clr-gray ">ATTACK POWER</p>*/}
                            {/*                    <img src={punchIcon} alt="heart" />*/}
                            {/*                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">{key?.value}</p>*/}
                            {/*                </div>*/}

                            {/*            }*/}
                            {/*        </Fragment>*/}
                            {/*    )*/}
                            {/*})*/}
                            {/*}*/}

                            {/*{key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {*/}
                            {/*    return (*/}
                            {/*        <Fragment key={index}>*/}
                            {/*            {key?.attribute === "bonusToDefence" && key?.type === 3 &&*/}
                            {/*                <div className="stats-icons">*/}
                            {/*                    <p className="font12Txt clr-gray ">DEFENSE</p>*/}
                            {/*                    <img src={shieldIcon} alt="heart" />*/}
                            {/*                    <p className="mb-0 font14Txt mt-1 ml-2 d-ib">{key?.value}</p>*/}
                            {/*                </div>*/}
                            {/*            }*/}
                            {/*        </Fragment>*/}
                            {/*    )*/}
                            {/*})*/}
                            {/*}*/}
                        </div>




                        {/*<div className="stats-block">*/}
                        {/*    <div className="stats-icons">*/}
                        {/*        <p className="font12Txt clr-gray ">Base HP</p>*/}
                        {/*        <HeartIcon />*/}
                        {/*        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="stats-icons">*/}
                        {/*        <p className="font12Txt clr-gray ">HEALTH REGEN</p>*/}
                        {/*        <HeartbeatIcon />*/}
                        {/*        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="stats-icons">*/}
                        {/*        <p className="font12Txt clr-gray ">ATTACK POWER</p>*/}
                        {/*        <PunchIcon />*/}
                        {/*        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="stats-icons">*/}
                        {/*        <p className="font12Txt clr-gray ">DEFENSE</p>*/}
                        {/*        <ShieldIcon />*/}
                        {/*        <p className="mb-0 font14Txt mt-1 ml-2 d-ib">45</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/* {key?.seller &&
                            <div>
                                <p className="font12Txt mb-0 clr-Primary">Seller</p>
                                <CustomTooltip title={key?.seller}>
                                    <p className="font14Txt mb-0 marketFontOverflowFix">
                                        {key?.seller}
                                    </p>
                                </CustomTooltip>
                            </div>
                        } */}
                        {props.type !== "collected" &&
                            <div className="img--block">
                                <p className="font21Txt mb-0">Ξ {key?.price}</p>
                                <p className="font18Txt clr-gray mb-0">$ {getUSDPrice(key?.price, selector.ethPrice.price)}</p>
                            </div>}
                    </div>
                </Fragment >
            )
        })
    )
}
export default MarketListViewCard
