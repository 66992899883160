import React, { Fragment } from 'react'
import {TickIcon} from "../../utils/svgConst";

const IconCheckbox = (props) => {

    const show=()=>{
        console.log("nothing")

    }
    const dataSet=   props.onClick ?props.onClick: show

    return (
        <Fragment>
            <div className={`checkbox-style-block ${props.type === 'withBG' ? '' : 'bg-transparent'}`}>
                <input
                    className="checkbox-effect custom-long-checkbox custom-checkbox hideBeforeAfter"


                    id={props.name}
                    type="checkbox"
                    value={props.value}
                    name={props.value}

                    onClick={()=>{
                        dataSet(props.value)
                    }}
                />
                <label for={props.name} className={`font14Txt d-flex align-items-center mb-0`}>
                    <div className="icon-checkbox-block">
                        {props.image &&
                            <img src={props.image} alt="icon" />
                        }
                        {props.color &&
                            <div className={`circle-input ${props.color}`}/>
                        }
                        <p className='mb-0 font14Txt clr-white'>{props.name}</p>
                        {props.stateActiveCheckBoxTick &&
                        <span className={`ml-auto mrSet ${props.stateActiveCheckBoxTick}`}><TickIcon /></span>

                        }

                    </div>
                </label>
            </div>
        </Fragment>
    )
}

export default IconCheckbox
