import mainLogo from "../images/logo.svg"

export const TEMPLATE_ARR = [
    {
        name: 'REVOG',
        home: {
            homepageMainImage: mainLogo,
        },
        navbar: {
            navLogo: mainLogo
        }
    }
]