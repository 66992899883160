import React, { Fragment, useEffect, useState } from "react"
import "../css/tabHeader.css"
import { Link, useHistory, useParams } from "react-router-dom"
import { AVATAR_URL, ITEMS_URL, REAL_STATE_URL, DASHBOARD_URL } from "../utils/config";

const TabHeader = () => {
    const [active, setActive] = useState(0)

    const params = useParams()
    const history = useHistory()

    const NFT_URL = params.nftContract


    useEffect(() => {
        if (NFT_URL === DASHBOARD_URL) {
            setActive(0)
        }
        if (NFT_URL === AVATAR_URL) {
            setActive(1)
        }
        if (NFT_URL === REAL_STATE_URL) {
            setActive(2)
        }
        if (NFT_URL === ITEMS_URL) {
            setActive(3)
        }
    }, [NFT_URL])
    return (
        <Fragment>
            <div className="tabHeaderBG">
                <Link to="/dashboard" className="mx-3">
                    <button
                        className={`btn font18Txt ${active === 0 ? "tabHeaderBtn" : "clr-gray"
                            }`}
                    >
                        Dashboard
                    </button>
                </Link>
                <Link to={`/market/${AVATAR_URL}`} className="mx-3">
                    <button
                        className={`btn font18Txt ${active === 1 ? "tabHeaderBtn" : "clr-gray"
                            }`}
                    >
                        Avatars
                    </button>
                </Link>
                <Link to={`/market/${REAL_STATE_URL}`} className="mx-3">
                    <button
                        className={`btn font18Txt ${active === 2 ? "tabHeaderBtn" : "clr-gray"
                            }`}
                    >
                        Real Estate
                    </button>
                </Link>
                <Link to={`/market/${ITEMS_URL}`} className="mx-3">
                    <button
                        className={`btn font18Txt ${active === 3 ? "tabHeaderBtn" : "clr-gray"
                            }`}
                    >
                        Items
                    </button>
                </Link>
            </div>
        </Fragment>
    )
}

export default TabHeader
