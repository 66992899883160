import React, { Fragment } from 'react'
import transferIcon from "../../images/transfer.svg"
import avatarThumbnail from "../../images/walletAvatarThumb.svg"
import OpenInNewIcon from '../../images/openInNew.svg';
import CustomTooltip from '../Common/CutomTooltip';

const ActivityTable = (props) => {
    const { image, name, to, from, time } = props;
    return (
        <Fragment>
            <div className="wallet-activity-table-row-grid cp">
                <div className="wallet-action-block">
                    <img src={transferIcon} alt="transfer" />
                    <p className="mb-0 font18Txt clr-white ml-2">Transfer</p>
                </div>
                <div className="wallet-item-block">
                    <img src={image || avatarThumbnail} alt="wallet avatar" height={50} width={50} />
                    <p className="mb-0 font14Txt clr-white ml-2">{name || "The  Veggies (open sea)The Following"}</p>
                </div>
                <div className="wallet-price-block">
                    <p className="mb-0 font18Txt clr-white">----</p>
                </div>
                <div className="wallet-quantity-block">
                    <p className="mb-0 font18Txt clr-white">1</p>
                </div>
                <CustomTooltip title={from}>
                    <div className="wallet-from-block">
                        <p className="mb-0 font18Txt clr-Primary">{from.substring(1, 8) + "..."}</p>
                    </div>
                </CustomTooltip>
                <CustomTooltip title={to}>
                    <div className="wallet-to-block">
                        <p className="mb-0 font18Txt clr-Primary">{to.substring(1, 8) + "..."}</p>
                    </div>
                </CustomTooltip>
                <div className="wallet-time-block">
                    <p className="mb-0 font18Txt clr-Primary mr-2">{time} days ago</p>
                    <img src={OpenInNewIcon} alt="open" />
                </div>
            </div>
        </Fragment>
    )
}

export default ActivityTable
