import { TEMPLATE_ARR } from "../constants/envConstant"

const USER_HELPER = () => {

    const getTemplateInfo = () => {
        const templateInfo = TEMPLATE_ARR.find((value) => value?.name === process.env.REACT_APP_APP_NAME)
        return templateInfo
    }

    return { getTemplateInfo }
}

export default USER_HELPER
