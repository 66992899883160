import React, { Fragment, memo } from "react";
import gridThumb from "../../images/grid.svg";
import itemsThumb from "../../images/itemThumb.svg";

const ItemsDetailImage = ({ image }) => {
  return (
    <Fragment>
      <div className="mintThumbBlock image-stack">
        <img
          src={gridThumb}
          alt="mintThumb"
          className="mintThumbImg stack-top"
        />
        <img
          //   src={image ? image : itemsThumb}
          src={itemsThumb}
          alt="mintThumb"
          className="mintThumbImg stack-bottom w-85"
        />
      </div>
    </Fragment>
  );
};

export default memo(ItemsDetailImage);
