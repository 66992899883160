import ethereum from "../../images/ethereum.svg";
import React, {Fragment} from "react";
import { useHistory} from "react-router";


const MintTimer =(props)=>{
    const history = useHistory()

    const goToMarketPage =()=>{
        history.push('/market')
    }

    return(
        <>
            <div className="main-dark-card p-25 mt-50 mb-40 pos-rel">
                <div className=" pb-10 mb-30 text-center">
                    <img src={ethereum} alt="ethereum" />
                    <p className="font24Txt mb-0 d-inline-block ml-3">
                        Mint on Ethereum
                    </p>
                </div>
                <div className="mint-count-bdr-top"/>
                <div className="mint-timer-wrapper mb-10">
                    <div className="mint-timer-sub-wrapper">
                        <p className="font12Txt clr-Primary text-center text-uppercase">Days</p>
                        <div className="mint-timer-block quantity-tab">
                            <p className="font50BoldTxt orbFont clr-red p-1 mb-0">{props.days}</p>
                        </div>
                    </div>
                    <div className="mint-timer-sub-wrapper">
                        <p className="font12Txt clr-Primary text-center text-uppercase">Hours</p>
                        <div className="mint-timer-block quantity-tab">
                            <p className="font50BoldTxt orbFont clr-red p-1 mb-0">{props.hours}</p>
                        </div>
                    </div>
                    <div className="mint-timer-sub-wrapper">
                        <p className="font12Txt clr-Primary text-center text-uppercase">Minutes</p>
                        <div className="mint-timer-block quantity-tab">
                            <p className="font50BoldTxt orbFont clr-red p-1 mb-0">{props.minutes}</p>
                        </div>
                    </div>
                    <div className="mint-timer-sub-wrapper">
                        <p className="font12Txt clr-Primary text-center text-uppercase">Seconds</p>
                        <div className="mint-timer-block quantity-tab">
                            <p className="font50BoldTxt orbFont clr-red p-1 mb-0">{props.seconds}</p>
                        </div>
                    </div>
                </div>
                <div className="mint-count-bdr-btm"></div>
                <div className="text-center mt-50">
                    <button className="btn btn-primary-clr font14Txt btnPadding br-0" onClick={goToMarketPage}>
                        View in Marketplace
                    </button>
                </div>
            </div>

        </>
    )
}
export default MintTimer

