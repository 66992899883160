import React, { Fragment, useEffect, useState } from "react"
import "../../css/dashboard.css"
import "../../css/marketCard.css"
import blockIcon from "../../images/block.svg"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import buildingIcon from "../../images/building.svg"
import a2zIcon from "../../images/a2z.svg"
import frameIcon from "../../images/frame.svg"
import { Link } from "react-router-dom";
import { REAL_STATE_URL } from "../../utils/config";
import { useSelector } from "react-redux";
import { getUSDPrice, getRarityClass, getRarityText } from '../../utils/helper'

import DashboardNoItemsFound from './noItemListed'
import heartIcon from "../../images/heart.svg";

const ListedRealEstate = (props) => {

    const [data, setData] = useState([])

    const selector = useSelector(state => state);

    useEffect(() => {
        setData(props?.data)
    }, [props])

    return (
        data && data.length > 0 ? data.map((key, index) => {
            return (
                <Fragment key={index}>
                    <Link to={"/detail/" + key?.nftContract.toLowerCase() + "/" + key?.nftId}>
                        <div className="listed-block-item custom-dashboard-card main-dark-card">
                            <img src={key?.image ? key?.image : blockIcon} alt="thumb" className="thumb-icon" />
                            <div className={`profileID ${getRarityClass(key.rarity)}`}>
                                <LocationOnIcon className={`${getRarityText(key.rarity)}`} />
                                <p className={`font12Txt ml-2 mb-0 ${getRarityText(key.rarity)}`}>#{key?.nftId}</p>
                            </div>



                            {/*{key.attributes && key.attributes.length > 0 && key.attributes.map((key, index) => {*/}
                            {/*    return (*/}
                            {/*        <Fragment key={index}>*/}
                            {/*            /!*{key?.attribute === "baseHP" && key?.type === 1 &&*!/*/}
                            {/*            <div className="img--block">*/}
                            {/*                <img src={heartIcon} alt="heart" />*/}
                            {/*                <p className="mb-0 font14Txt mt-1 clr-white">{key?.value}</p>*/}
                            {/*            </div>*/}
                            {/*            /!*}*!/*/}
                            {/*        </Fragment>*/}
                            {/*    )*/}
                            {/*})*/}
                            {/*}*/}

                            <div className="img--block">
                                  <img src={buildingIcon} alt="heart" />
                                    <p className="mb-0 font14Txt mt-1 clr-white">45</p>
                            </div>
                            <div className="img--block">
                                <img src={a2zIcon} alt="heart" />
                                <p className="mb-0 font14Txt mt-1 clr-white">45</p>
                            </div>
                            <div className="img--block">
                                <img src={frameIcon} alt="heart" />
                                <p className="mb-0 font14Txt mt-1 clr-white">45</p>
                            </div>
                            <div className="img--block">
                                <p className="font21Txt mb-0 clr-white">Ξ {key?.price}</p>
                                <p className="font18Txt clr-gray mb-0">$ {getUSDPrice(key?.price, selector.ethPrice.price)}</p>
                            </div>
                        </div>
                    </Link>
                </Fragment>
            )
        }) :
            <DashboardNoItemsFound type="listed" />
    )
}

export default ListedRealEstate
