import React, { Fragment, useState } from 'react';
import "../../css/wallet.css"
import "../../css/detail.css"
import { useDispatch, useSelector } from "react-redux";
import * as Web3 from "web3";
import { PRIMARY_SALE_ABI, SECONDARY_SALE_ABI } from "../../utils/ABI";
import { PRIMARY_SALE_ADDRESS, SECONDARY_SALE_ADDRESS } from "../../utils/config";
import { useToasts } from "react-toast-notifications";
import BlockChainMsg from "../blockChsainTransactionMsg";
import noticeIcon from "../../images/notice.svg"
import { BuySuccessModalShowHide, NFTRemoveConfirmModalShowHide } from "../../redux/actions/modalActions";
import BuySuccessModal from "../Detail/SuccessModal";


const web3 = new Web3(Web3.givenProvider);
const PRIMARY_SALE_CONTRACT = new web3.eth.Contract(PRIMARY_SALE_ABI, PRIMARY_SALE_ADDRESS);
const SECONDARY_SALE_CONTRACT = new web3.eth.Contract(SECONDARY_SALE_ABI, SECONDARY_SALE_ADDRESS);


const NFTRemoveConfirmModal = (props) => {

    const { addToast } = useToasts()
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    const userAddress = selector.user.address
    const showModal = selector.modals.nftRemoveConfirmModal

    const [message, setMessage] = useState({ "message": "", res: false })


    const hideModal = () => {
        dispatch(NFTRemoveConfirmModalShowHide(false))
    }

    const handleRemoveNFT = () => {

        const nftContract = selector.modals.nftRemoveInfo.nftContract
        const nftId = selector.modals.nftRemoveInfo.nftId

        //consollog({nftContract}, {nftId}, selector?.modals?.nftRemoveInfo)

        if (!userAddress) {
            addToast("Please Login to continue", { appearance: "error", autoDismiss: true })
        } else {
            try {
                SECONDARY_SALE_CONTRACT.methods
                    .removeFromMarketplace(nftContract, nftId)
                    .send({
                        from: userAddress,
                    })
                    .on("transactionHash", (hash) => {
                        //consollog("transactionHash  sendToken", hash);
                        setMessage({ "message": hash, "res": true })
                        addToast(`Click Here to see Transaction:  https://mumbai.polygonscan.com/tx/${hash}
                        `, {
                            appearance: "success",
                            autoDismiss: true,
                        })

                    })
                    .on("receipt", (receipt) => {
                        //consollog("receipt sendToken", receipt);
                    })
                    .on("confirmation", (confirmationNumber, receipt) => {
                        //consollog("confirmationNumber sendToken", confirmationNumber);
                        //consollog("receipt sendToken", receipt);
                        if (confirmationNumber === 0) {
                            dispatch(BuySuccessModalShowHide(true))
                        }
                    })
                    .on("error", (error) => {
                        //consollog("error sendToken", error);
                        addToast("Failed" + error?.message, { appearance: "error", autoDismiss: true })
                    });

            } catch (e) {
                addToast("Failed to buy NFT " + e, { appearance: "error", autoDismiss: true })
            }
        }
    }


    return (
        <Fragment>
            <div className={`modal fade ${showModal ? "show" : ""}`} role="dialog">
                <div className="modal-dialog wallet-modal-box">
                    <div className="modal-content modal-content-box pos-rel">
                        <div className=" text-center">
                            <img src={noticeIcon} alt="notice" className="mb-30" />
                            <h5 className="font21Txt clr-white w-85">ARE YOU SURE YOU WANT TO REMOVE YOUR LISTING?</h5>
                        </div>
                        <div className="modal-btn-group">
                            <button className="btn br-0 p-2 modalNoBtn orbFont" onClick={hideModal}>
                                <div className="pos-rel font16Txt clr-Primary">
                                    No
                                </div>
                            </button>
                            <button className="btn br-0 modalYesBtn p-2 orbFont"
                                onClick={handleRemoveNFT}>
                                <div className="pos-rel font16Txt clr-Primary">
                                    yes
                                </div>
                            </button>
                        </div>
                        <div className="modal-btn-group">
                            <BlockChainMsg message={message} />
                        </div>
                    </div>
                </div>
            </div>


            {message.message && message.res && showModal &&
                <BuySuccessModal hash={message} messageText={"NFT Successfully removed"} />
            }
        </Fragment>
    )
}
export default NFTRemoveConfirmModal
