import React, { Fragment, useEffect, useState } from 'react'
import ethereum from "../../images/ethereum.svg";



const SoldOutMint = () => {
    return (

        <>
            <div className="main-dark-card p-25 mt-50 mb-40 pos-rel">
                <div className=" pb-10 mb-30 text-center">
                    <img src={ethereum} alt="ethereum" />
                    <p className="font24Txt mb-0 d-inline-block ml-3">
                        Mint on Ethereum
                    </p>
                </div>
                <div className="mint-bdr-top" />
                <div className="coming-soon-text-wrapper mb-40">
                    <p className="font80Txt clr-red text-uppercase mt-20 mb-20 orbFont text-center">Sold Out</p>
                </div>
                <div className="mint-btn-group mt-20">
                    <button className="btn btn-primary-clr font14Txt p-2 br-0">
                        Trade in Marketplace
                    </button>
                    <button className="btn btn-primary-clr font14Txt p-2 br-0">
                        View in your Wallet
                    </button>
                </div>
                <div className="mint-bdr-btm" />
            </div>

        </>
    )
}
export default SoldOutMint