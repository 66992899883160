import React, { Fragment, useState } from 'react'
import tracerSelect from "../../images/tracerSelect.svg";
import tracerDeselect from "../../images/tracerDeselect.svg";
import triadSelect from "../../images/triadSelect.svg";
import triadDeselect from "../../images/triadDeselect.svg";
import sketchSelect from "../../images/sketchSelect.svg";
import sketchDeselect from "../../images/sketchDeselect.svg";
import topoSelect from "../../images/topoSelect.svg";
import topoDeselect from "../../images/topoDeselect.svg";
import plexusSelect from "../../images/plexusSelect.svg";
import plexusDeselect from "../../images/plexusDeselect.svg";
import poison from "../../images/poison.svg";
import cage from "../../images/cage.svg";
import grunt from "../../images/grunt.svg";
import strife from "../../images/strife.svg";
import dohma from "../../images/dohma.svg";
import morrow from "../../images/morrow.svg";
import sorrento from "../../images/sorrento.svg";
import raiden from "../../images/raiden.svg";
import rodan from "../../images/rodan.svg";
import crash from "../../images/crash.svg";
import frost from "../../images/frost.svg";
import vaper from "../../images/vaper.svg";
import torch from "../../images/torch.svg";
import aran from "../../images/aran.svg";
import goro from "../../images/goro.svg";
import maul from "../../images/maul.svg";
import adkins from "../../images/adkins.svg";
import bumble from "../../images/bumble.svg";
import zander from "../../images/zander.svg";
import flash from "../../images/flash.svg";
import gorgan from "../../images/gorgan.svg";
import manta from "../../images/manta.svg";
import fate from "../../images/fate.svg";
import shiro from "../../images/shiro.svg";
import marco from "../../images/marco.svg";

export const headStyleMap = [
  {
    value: 'Tracer',
    name: 'Tracer',
    imageSelected: tracerSelect,
    image: tracerDeselect

  },

  {
    value: 'Triad',
    name: 'Triad',
    imageSelected: triadSelect,
    image: triadDeselect
  },
  {
    value: 'Sketch',
    name: 'Sketch',
    imageSelected: sketchSelect,
    image: sketchDeselect
  },
  {
    value: 'topo',
    name: 'topo',
    imageSelected: topoSelect,
    image: topoDeselect
  },
  {
    value: 'plexus',
    name: 'plexus',
    imageSelected: plexusSelect,
    image: plexusDeselect
  }


]

export const userNameAndColor = [
  {
    value: 'STRIDER',
    name: 'STRIDER',
    colorCircle: 'bg-white',
    imageSelected: tracerSelect,
    image: tracerDeselect

  },

  {
    value: 'Flash',
    name: 'Flash',
    colorCircle: 'bg-primary',

    imageSelected: triadSelect,
    image: triadDeselect
  },
  {
    value: 'Clave',
    name: 'Clave',
    colorCircle: 'bg-yellow',

    imageSelected: sketchSelect,
    image: sketchDeselect
  },
  {
    value: 'Maul',
    name: 'Maul',
    colorCircle: 'bg-red',

    imageSelected: topoSelect,
    image: topoDeselect
  },
  {
    value: 'Rifl',
    name: 'Rifl',
    colorCircle: 'bg-purple',

    imageSelected: plexusSelect,
    image: plexusDeselect
  },
  {
    value: 'Clover',
    name: 'Clover',
    colorCircle: 'bg-green',

    imageSelected: plexusSelect,
    image: plexusDeselect
  },
  {
    value: 'Pinky',
    name: 'Pinky',
    colorCircle: 'bg-pink',

    imageSelected: plexusSelect,
    image: plexusDeselect
  }

]

export const torsoStyleMap = [
  {
    value: 'Torso-Tracer',
    name: 'Torso-Tracer',
    imageSelected: tracerSelect,
    image: tracerDeselect

  },

  {
    value: 'Torso-Triad',
    name: 'Torso-Triad',
    imageSelected: triadSelect,
    image: triadDeselect
  },
  {
    value: 'Torso-Sketch',
    name: 'Torso-Sketch',
    imageSelected: sketchSelect,
    image: sketchDeselect
  },
  {
    value: 'Torso-Topo',
    name: 'Torso-Topo',
    imageSelected: topoSelect,
    image: topoDeselect
  },
  {
    value: 'Torso-Plexus',
    name: 'Torso-Plexus',
    imageSelected: plexusSelect,
    image: plexusDeselect
  }


]

export const handStyleMap = [
  {
    value: 'Hand-Tracer',
    name: 'Hand-Tracer',
    imageSelected: tracerSelect,
    image: tracerDeselect

  },

  {
    value: 'Hand-Triad',
    name: 'Hand-Triad',
    imageSelected: triadSelect,
    image: triadDeselect
  },
  {
    value: 'Hand-Sketch',
    name: 'Hand-Sketch',
    imageSelected: sketchSelect,
    image: sketchDeselect
  },
  {
    value: 'Hand-Topo',
    name: 'Hand-Topo',
    imageSelected: topoSelect,
    image: topoDeselect
  },
  {
    value: 'Hand-plexus',
    name: 'Hand-Plexus',
    imageSelected: plexusSelect,
    image: plexusDeselect
  }



]


export const colorArrayWatch = [
  {
    value: 'watch-poison',
    name: 'watch-poison',
    imageSelected: poison,
    image: poison
  },

  {
    value: 'watch-cage',
    name: 'watch-cage',
    imageSelected: cage,
    image: cage
  },
  {
    value: 'watch-grunt',
    name: 'watch-grunt',
    imageSelected: grunt,
    image: grunt
  },
  {
    value: 'watch-strife',
    name: 'watch-strife',
    imageSelected: strife,
    image: strife
  },
  {
    value: 'watch-dohma',
    name: 'watch-dohma',
    imageSelected: dohma,
    image: dohma
  },
  {
    value: 'watch-morrow',
    name: 'watch-morrow',
    imageSelected: morrow,
    image: morrow
  },
  {
    value: 'watch-sorrento',
    name: 'watch-sorrento',
    imageSelected: sorrento,
    image: sorrento
  },
  {
    value: 'watch-raiden',
    name: 'watch-raiden',
    imageSelected: raiden,
    image: raiden
  },
  {
    value: 'watch-rodan',
    name: 'watch-rodan',
    imageSelected: rodan,
    image: rodan
  },
  {
    value: 'watch-crash',
    name: 'watch-crash',
    imageSelected: crash,
    image: crash
  },
  {
    value: 'watch-frost',
    name: 'watch-frost',
    imageSelected: frost,
    image: frost
  },
  {
    value: 'watch-vaper',
    name: 'watch-vaper',
    imageSelected: vaper,
    image: vaper
  },
  {
    value: 'watch-torch',
    name: 'watch-torch',
    imageSelected: torch,
    image: torch
  },
  {
    value: 'watch-aran',
    name: 'watch-aran',
    imageSelected: aran,
    image: aran
  },
  {
    value: 'watch-goro',
    name: 'watch-goro',
    imageSelected: goro,
    image: goro
  },
  {
    value: 'watch-maul',
    name: 'watch-maul',
    imageSelected: maul,
    image: maul
  },
  {
    value: 'watch-adkins',
    name: 'watch-adkins',
    imageSelected: adkins,
    image: adkins
  },
  {
    value: 'watch-bumble',
    name: 'watch-bumble',
    imageSelected: bumble,
    image: bumble
  },
  {
    value: 'watch-zander',
    name: 'watch-zander',
    imageSelected: zander,
    image: zander
  },
  {
    value: 'watch-flash',
    name: 'watch-flash',
    imageSelected: flash,
    image: flash
  },
  {
    value: 'watch-gorgan',
    name: 'watch-gorgan',
    imageSelected: gorgan,
    image: gorgan
  },
  {
    value: 'watch-manta',
    name: 'watch-manta',
    imageSelected: manta,
    image: manta
  },
  {
    value: 'watch-fate',
    name: 'watch-fate',
    imageSelected: fate,
    image: fate
  },
  {
    value: 'watch-shiro',
    name: 'watch-shiro',
    imageSelected: shiro,
    image: shiro
  },
  {
    value: 'watch-marco',
    name: 'watch-marco',
    imageSelected: marco,
    image: marco
  },

]

export const colorHeadArray = [
  {
    value: 'head-poison',
    name: 'head-poison',
    imageSelected: poison,
    image: poison
  },
  {
    value: 'head-cage',
    name: 'head-cage',
    imageSelected: cage,
    image: cage
  },
  {
    value: 'head-grunt',
    name: 'head-grunt',
    imageSelected: grunt,
    image: grunt
  },
  {
    value: 'head-strife',
    name: 'head-strife',
    imageSelected: strife,
    image: strife
  },
  {
    value: 'head-dohma',
    name: 'head-dohma',
    imageSelected: dohma,
    image: dohma
  },
  {
    value: 'head-morrow',
    name: 'head-morrow',
    imageSelected: morrow,
    image: morrow
  },
  {
    value: 'head-sorrento',
    name: 'head-sorrento',
    imageSelected: sorrento,
    image: sorrento
  },
  {
    value: 'head-raiden',
    name: 'head-raiden',
    imageSelected: raiden,
    image: raiden
  },
  {
    value: 'head-rodan',
    name: 'head-rodan',
    imageSelected: rodan,
    image: rodan
  },
  {
    value: 'head-crash',
    name: 'head-crash',
    imageSelected: crash,
    image: crash
  },
  {
    value: 'head-frost',
    name: 'head-frost',
    imageSelected: frost,
    image: frost
  },
  {
    value: 'head-vaper',
    name: 'head-vaper',
    imageSelected: vaper,
    image: vaper
  },
  {
    value: 'head-torch',
    name: 'head-torch',
    imageSelected: torch,
    image: torch
  },
  {
    value: 'head-aran',
    name: 'head-aran',
    imageSelected: aran,
    image: aran
  },
  {
    value: 'head-goro',
    name: 'head-goro',
    imageSelected: goro,
    image: goro
  },
  {
    value: 'head-maul',
    name: 'head-maul',
    imageSelected: maul,
    image: maul
  },
  {
    value: 'head-adkins',
    name: 'head-adkins',
    imageSelected: adkins,
    image: adkins
  },
  {
    value: 'head-bumble',
    name: 'head-bumble',
    imageSelected: bumble,
    image: bumble
  },
  {
    value: 'head-zander',
    name: 'head-zander',
    imageSelected: zander,
    image: zander
  },
  {
    value: 'head-flash',
    name: 'head-flash',
    imageSelected: flash,
    image: flash
  },
  {
    value: 'head-gorgan',
    name: 'head-gorgan',
    imageSelected: gorgan,
    image: gorgan
  },
  {
    value: 'head-manta',
    name: 'head-manta',
    imageSelected: manta,
    image: manta
  },
  {
    value: 'head-fate',
    name: 'head-fate',
    imageSelected: fate,
    image: fate
  },
  {
    value: 'head-shiro',
    name: 'head-shiro',
    imageSelected: shiro,
    image: shiro
  },
  {
    value: 'head-marco',
    name: 'head-marco',
    imageSelected: marco,
    image: marco
  },

]
export const colorTorsoArray = [
  {
    value: 'torso-poison',
    name: 'torso-poison',
    imageSelected: poison,
    image: poison
  },
  {
    value: 'torso-cage',
    name: 'torso-cage',
    imageSelected: cage,
    image: cage
  },
  {
    value: 'torso-grunt',
    name: 'torso-grunt',
    imageSelected: grunt,
    image: grunt
  },
  {
    value: 'torso-strife',
    name: 'torso-strife',
    imageSelected: strife,
    image: strife
  },
  {
    value: 'torso-dohma',
    name: 'torso-dohma',
    imageSelected: dohma,
    image: dohma
  },
  {
    value: 'torso-morrow',
    name: 'torso-morrow',
    imageSelected: morrow,
    image: morrow
  },
  {
    value: 'torso-sorrento',
    name: 'torso-sorrento',
    imageSelected: sorrento,
    image: sorrento
  },
  {
    value: 'torso-raiden',
    name: 'torso-raiden',
    imageSelected: raiden,
    image: raiden
  },
  {
    value: 'torso-rodan',
    name: 'torso-rodan',
    imageSelected: rodan,
    image: rodan
  },
  {
    value: 'torso-crash',
    name: 'torso-crash',
    imageSelected: crash,
    image: crash
  },
  {
    value: 'torso-frost',
    name: 'torso-frost',
    imageSelected: frost,
    image: frost
  },
  {
    value: 'torso-vaper',
    name: 'torso-vaper',
    imageSelected: vaper,
    image: vaper
  },
  {
    value: 'torso-torch',
    name: 'torso-torch',
    imageSelected: torch,
    image: torch
  },
  {
    value: 'torso-aran',
    name: 'torso-aran',
    imageSelected: aran,
    image: aran
  },
  {
    value: 'torso-goro',
    name: 'torso-goro',
    imageSelected: goro,
    image: goro
  },
  {
    value: 'torso-maul',
    name: 'torso-maul',
    imageSelected: maul,
    image: maul
  },
  {
    value: 'torso-adkins',
    name: 'torso-adkins',
    imageSelected: adkins,
    image: adkins
  },
  {
    value: 'torso-bumble',
    name: 'torso-bumble',
    imageSelected: bumble,
    image: bumble
  },
  {
    value: 'torso-zander',
    name: 'torso-zander',
    imageSelected: zander,
    image: zander
  },
  {
    value: 'torso-flash',
    name: 'torso-flash',
    imageSelected: flash,
    image: flash
  },
  {
    value: 'torso-gorgan',
    name: 'torso-gorgan',
    imageSelected: gorgan,
    image: gorgan
  },
  {
    value: 'torso-manta',
    name: 'torso-manta',
    imageSelected: manta,
    image: manta
  },
  {
    value: 'torso-fate',
    name: 'torso-fate',
    imageSelected: fate,
    image: fate
  },
  {
    value: 'torso-shiro',
    name: 'torso-shiro',
    imageSelected: shiro,
    image: shiro
  },
  {
    value: 'torso-marco',
    name: 'torso-marco',
    imageSelected: marco,
    image: marco
  },

]
export const colorHandArray = [
  {
    value: 'hand-poison',
    name: 'hand-poison',
    imageSelected: poison,
    image: poison
  },
  {
    value: 'hand-cage',
    name: 'hand-cage',
    imageSelected: cage,
    image: cage
  },
  {
    value: 'hand-grunt',
    name: 'hand-grunt',
    imageSelected: grunt,
    image: grunt
  },
  {
    value: 'hand-strife',
    name: 'hand-strife',
    imageSelected: strife,
    image: strife
  },
  {
    value: 'hand-dohma',
    name: 'hand-dohma',
    imageSelected: dohma,
    image: dohma
  },
  {
    value: 'hand-morrow',
    name: 'hand-morrow',
    imageSelected: morrow,
    image: morrow
  },
  {
    value: 'hand-sorrento',
    name: 'hand-sorrento',
    imageSelected: sorrento,
    image: sorrento
  },
  {
    value: 'hand-raiden',
    name: 'hand-raiden',
    imageSelected: raiden,
    image: raiden
  },
  {
    value: 'hand-rodan',
    name: 'hand-rodan',
    imageSelected: rodan,
    image: rodan
  },
  {
    value: 'hand-crash',
    name: 'hand-crash',
    imageSelected: crash,
    image: crash
  },
  {
    value: 'hand-frost',
    name: 'hand-frost',
    imageSelected: frost,
    image: frost
  },
  {
    value: 'hand-vaper',
    name: 'hand-vaper',
    imageSelected: vaper,
    image: vaper
  },
  {
    value: 'hand-torch',
    name: 'hand-torch',
    imageSelected: torch,
    image: torch
  },
  {
    value: 'hand-aran',
    name: 'hand-aran',
    imageSelected: aran,
    image: aran
  },
  {
    value: 'hand-goro',
    name: 'hand-goro',
    imageSelected: goro,
    image: goro
  },
  {
    value: 'hand-maul',
    name: 'hand-maul',
    imageSelected: maul,
    image: maul
  },
  {
    value: 'hand-adkins',
    name: 'hand-adkins',
    imageSelected: adkins,
    image: adkins
  },
  {
    value: 'hand-bumble',
    name: 'hand-bumble',
    imageSelected: bumble,
    image: bumble
  },
  {
    value: 'hand-zander',
    name: 'hand-zander',
    imageSelected: zander,
    image: zander
  },
  {
    value: 'hand-flash',
    name: 'hand-flash',
    imageSelected: flash,
    image: flash
  },
  {
    value: 'hand-gorgan',
    name: 'hand-gorgan',
    imageSelected: gorgan,
    image: gorgan
  },
  {
    value: 'hand-manta',
    name: 'hand-manta',
    imageSelected: manta,
    image: manta
  },
  {
    value: 'hand-fate',
    name: 'hand-fate',
    imageSelected: fate,
    image: fate
  },
  {
    value: 'hand-shiro',
    name: 'hand-shiro',
    imageSelected: shiro,
    image: shiro
  },
  {
    value: 'hand-marco',
    name: 'hand-marco',
    imageSelected: marco,
    image: marco
  },

]